import API from '@aws-amplify/api';
import { Grow, Paper, MenuList, MenuItem, Grid, Tab, Typography, Tabs, Popper, useMediaQuery, Box, IconButton, Button, TextField, ButtonGroup, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
    useGridApiContext,
    gridRowIdsSelector,
} from '@mui/x-data-grid';


export default function DataGridToolBar({
    hideButtons,
    searchValue,
    onSearchChange,
    clearSearch,
    open,
    anchorRef,
    selectedIndex,
    setSelectedIndex,
    handleClick,
    options, optionDisabled,
    handleMenuItemClick,
    buttonDisabled,
    handleClose,
    handleToggle,
    selectedRows,
    fileName
}) {
    const apiRef = useGridApiContext();

    const handleExport = (options) => apiRef.current.exportDataAsCsv({ ...options, fileName: fileName, allColumns: true });

    return (
        <Grid container rowSpacing={2} columnSpacing={0}
            sx={{
                p: 0.5,
                pb: 0,
                // display: "flex",
                // justifyContent: "space-between",
                // alignItems: "center",
                width: "100%"
            }}
        >
            <Grid item xs={12} md={6}>
                <TextField
                    variant="standard"
                    value={searchValue}
                    onChange={onSearchChange}
                    placeholder="Search..."
                    fullWidth
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: searchValue ? 'visible' : 'hidden' }}
                                onClick={clearSearch}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                    sx={{
                        width: "100%",
                        m: (theme) => theme.spacing(1, 0.5, 1.5),
                        '& .MuiSvgIcon-root': {
                            mr: 0.5,
                        },
                        '& .MuiInput-underline:before': {
                            borderBottom: 1,
                            borderColor: 'divider',
                        },
                    }}
                /></Grid>
            <Grid item xs={0} md={2} />

          
            <Grid item xs={12} md={2}>
                {/* start of set state button group */}
                {!hideButtons && <div>
                    <ButtonGroup sx={{ float: "right" }} variant="contained" ref={anchorRef} aria-label="split button">
                        <Button onClick={handleToggle} disabled={buttonDisabled()}>{"Set Status"}</Button>
                        <Button
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            disabled={buttonDisabled()}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        style={{
                            zIndex: 999,
                        }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" >
                                            {options.map((option, index) => (
                                                <MenuItem
                                                    key={option}
                                                    disabled={optionDisabled(option)}
                                                    selected={index === selectedIndex}
                                                    onClick={(e) => handleMenuItemClick(option)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
                }

                {/* end of set state button group */}
            </Grid>

            <Grid item xs={12} md={2}> {
                <Button sx={{ float: "right" }} variant="contained"
                    onClick={() => handleExport({
                        getRowsToExport: ({ apiRef }) => gridRowIdsSelector(apiRef)
                    })}
                >
                    {selectedRows.length > 0 ? `Download (${selectedRows.length})` : `Download All`}
                </Button>

            }</Grid>

        </Grid >

    );
}
DataGridToolBar.propTypes = {
    clearSearch: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
};