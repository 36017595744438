import React, { useState, useEffect } from "react";
// import gf_asset from './GameFoundationCatalog.asset';

import DateFnsUtils from '@date-io/date-fns';

import MobileDatePicker from '@mui/lab/MobileDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TimePicker from '@mui/lab/TimePicker';

import {
    Backdrop,
    CardMedia,
    CircularProgress,
    Button,
    Tab,
    Tabs,
    Typography,
    Grid,
    Container,
    Switch,
    FormControlLabel,
    Autocomplete,
    Box,
    TextField,
    InputLabel,
    useMediaQuery,
    useTheme,
    Alert,
} from "@mui/material";
import { useParams, useHistory } from "react-router";

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import PollOption from "./PollOption";

import { stringify, v4 as uuidv4 } from "uuid";

import { API } from "aws-amplify";

import Compress from "react-image-file-resizer";
import { addDays, intervalToDuration } from "date-fns";
import { deletePoll, isValidDate, updatePoll, dataURLtoFile, fetchGFAsset, MAX_DISRUPTION_POLL, MAX_NORMAL_POLL } from "./utils";
import App2 from "./App2";
import CommonHeader from "./CommonHeader";
import MyModal, { modalPreset } from "./MyModal";
import { formatDuration, set, setDate } from "date-fns/esm";
import RewardAttachment from './RewardAttachment';
import defaultImage from "./assets/polls_obj_default.png";
import Storage from '@aws-amplify/storage';
import TextWithTooltipIcon from "./TextWithTooltipIcon";

const POLL_NAME_MAX_CHAR = 35
const POLL_BODY_MAX_CHAR = 250

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function PollEditView2({ logout, openStatus, setStatus, disruption = false }) {
    const history = useHistory();
    let { id } = useParams();

    const [poll, setPoll] = useState({
        name: "",
        description: "",
        start_at: new Date(),
        end_at: addDays(new Date(), 7),
    });
    const [toPublish, setToPublish] = useState(true);
    const [isSuccessCreation, setIsSuccessCreation] = useState(false)
    const [isSuccessEdition, setIsSuccessEdition] = useState(false)
    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false)
    const [isErrorCreatingTooMany, setIsErrorCreatingTooMany] = useState(false)
    const [optionArr, setOptionArr] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [allRewardOptions, setAllRewardOptions] = useState([]);
    const [tab, setTab] = useState(0)
    const [DateErrorMsg, setDateErrorMsg] = useState(null)
    const [Image, setImage] = useState("")
    const [EditedPicture, setEditedPicture] = useState(false) //used in edit mode only
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [IsCreatingWithoutReward, setIsCreatingWithoutReward] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasDeleted, setHasDeleted] = useState(false)

    useEffect(() => {
        if (toPublish) {
            setPoll(prev => ({
                ...prev,
                start_at: new Date()
            }))
        }
    }, [toPublish]);

    const PollMapToList = (PollMap) => {
        let resultList = []
        Object.entries(PollMap).forEach(([optionId, optionBody]) => {
            if ("index" in optionBody) {
                resultList[optionBody.index] = { ...optionBody, id: optionId }
            } else {
                //is legacy poll. no index attribute
                resultList.push({ ...optionBody, id: optionId, index: resultList.length })
            }
        })
        return resultList;
    }

    //when parsing to map (for writing to db), ignore the index attr. add new index based on current list index
    const PollListToMap = (PollList) => {
        let pollMap = {}
        PollList.forEach((poll, pollIndex) => {
            pollMap[poll.id] = { ...poll, id: null, index: pollIndex }
        })
        return pollMap;
    }

    const handlePollChange = (e) => {
        if (e.target.name === "name" && e.target.value.length > POLL_NAME_MAX_CHAR) {
            e.target.value = e.target.value.substring(0, POLL_NAME_MAX_CHAR)
        } else if (e.target.name === "description") {
            e.target.value = e.target.value.substring(0, POLL_BODY_MAX_CHAR)
        }
        setPoll((prev) => ({
            ...prev,
            [e.target.name]: e.target.checked || e.target.value,
        }));
    };

    const checkPollLimit = (successCB, errorCB) => {
        API.get("PollingApi2", disruption ? "/disruption-polls/id" : "/polls/id", {})
            .then((polls) => {
                let _ongoingUpcoming = polls.filter((poll) => poll["end_at"] * 1000 > new Date())
                if (disruption && _ongoingUpcoming.length >= MAX_DISRUPTION_POLL) {
                    errorCB()
                } else if (!disruption && _ongoingUpcoming.length >= MAX_NORMAL_POLL) {
                    errorCB()
                } else {
                    successCB()
                }
            })
            .catch((err) => {
                console.logError(err)
            })
    }

    const addPoll = () => {
        // adds the poll to db
        const id = uuidv4();
        const _postPoll = (signedUrl) => {
            let body = {
                id: id,
                ...poll,
                created_at: Math.floor(Date.now() / 1000),
                start_at: Math.floor(poll.start_at.getTime() / 1000),
                end_at: Math.floor(poll.end_at.getTime() / 1000),
                options: PollListToMap(optionArr),
                rewards: rewards.map(rew => ({ key: rew.content.m_Key, quantity: rew.quantity })),
                is_disruption: disruption,
                img_s3: signedUrl,
            }
            try { delete body.base64Img } catch (e) { console.log(e) }
            API.post("PollingApi2", (disruption ? "/disruption-polls" : "/polls"), {
                header: {
                    "Access-Control-Allow-Origin": "*",
                },
                body: body,
            })
                .then((resArr) => {
                    console.log(resArr);
                    setLoading(false);
                    setIsSuccessCreation(true)
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        }

        if (Image) {
            let file = dataURLtoFile(Image, id + ".png") //filename will be the same as the quest id
            Storage.put(disruption ? `disruption-poll/${file.name}` : `poll/${file.name}`, file, {
                level: "public",
            }).then(res => {
                _postPoll(res.key)
            }).catch(err => {
                console.error(err)
            })
        } else {
            _postPoll("")
        }
    };

    const editPoll = () => {
        let file
        try { file = dataURLtoFile(Image, poll.id + ".png") } catch (err) { }//filename will be the same as the quest id
        if (EditedPicture) {
            Storage.put(disruption ? `disruption-poll/${file.name}` : `poll/${file.name}`, file, {
                level: "public",
            }).then(res => {
                _editPoll(res.key)
            }).catch(err => {
                console.error(err)
            })
        } else {
            _editPoll()
        }
    };

    const _editPoll = (imageKey) => {
        let body = {
            ...poll,
            start_at: Math.floor(poll.start_at.getTime() / 1000),
            end_at: Math.floor(poll.end_at.getTime() / 1000),
            options: PollListToMap(optionArr),
            rewards: rewards.map(rew => ({ key: rew.content.m_Key, quantity: rew.quantity })),
        }
        if (imageKey) {
            body['img_s3'] = imageKey
        }
        updatePoll(body, null, (res) => {
            setLoading(false);
            setIsSuccessEdition(true)
        })
    }

    useEffect(() => { console.log(allRewardOptions); }, [allRewardOptions])

    useEffect(() => {
        if (isValidDate(poll.start_at) && isValidDate(poll.end_at)) {
            setDateErrorMsg("")
        } else {
            setDateErrorMsg("Invalid Date(s)")
        }
    }, [poll.start_at, poll.end_at])

    useEffect(() => {
        setLoading(true)
        fetchGFAsset((_allRewardOptions) => {
            setAllRewardOptions(_allRewardOptions);
            //if the poll id is present, get the item from db
            if (id) { //editing
                API.get("PollingApi2", (disruption ? `/disruption-polls/object/${id}` : `/polls/object/${id}`))
                    .then((poll) => {
                        if (poll.length <= 0) {
                            //No item was found
                            history.push("/");
                        } else {
                            setPoll({
                                ...poll,
                                start_at: new Date(poll.start_at * 1000),
                                end_at: new Date(poll.end_at * 1000),
                            }); //"decode" the start_at and end_at 
                            setToPublish(false); // if editing a poll from previously, the ToPublish WAS most likely set to false
                            console.log(poll);
                            setOptionArr(PollMapToList(poll.options));
                            let _rewards = []
                            //unpack rewards
                            poll.rewards.forEach(re => {
                                _rewards.push(
                                    {
                                        content: _allRewardOptions.find(rew => rew.m_Key === re.key),
                                        quantity: re.quantity
                                    }
                                )
                            });
                            setRewards(_rewards);
                        }
                        //fetch the s3 picture
                        if (poll.img_s3) {
                            Storage.get(`${poll.img_s3}`, { level: "public" })
                                .then(signedUrl => {
                                    console.log(signedUrl)
                                    fetch(signedUrl).then(res => res.blob()).then(blob => {
                                        var reader = new FileReader();
                                        reader.onloadend = function () {
                                            setImage(reader.result)
                                        }
                                        reader.readAsDataURL(blob);
                                    }).catch(err => {
                                        console.error(err)
                                    })
                                }).catch(err => {
                                    console.error(err)
                                })
                        }
                        setLoading(false)
                    })
                    .catch((err) => {
                        console.log(err);
                        history.push("/");
                    });
            } else { //create new
                let defaultOptions = {
                    [uuidv4()]: {
                        name: "Option A",
                        votes: [],
                        index: 0
                    },
                    [uuidv4()]: {
                        name: "Option B",
                        votes: [],
                        index: 1
                    },
                };
                setOptionArr(PollMapToList(defaultOptions));
                setLoading(false)
            }
        })
    }, [disruption, history, id]);

    // useEffect(() => { console.log(poll); }, [poll]);

    const addNewOption = () => {
        setOptionArr((prev) => ([
            ...prev,
            {
                name: "Another Option",
                // description: "Add some explanations ...",
                votes: [],
                id: uuidv4(),
                index: prev.length
            },
        ]));
    };

    const deleteOption = (oid) => {
        setOptionArr((prev) => {
            const state = []
            prev.forEach(option => {
                if (option.id != oid) {
                    state.push(option)
                }
            })
            return state;
        });
    };


    const EncodePic = (file) => {
        //resize
        Compress.imageFileResizer(
            file, // the file from input
            1024, // width
            1024, // height
            "PNG", // compress format WEBP, JPEG, PNG
            50, // quality
            0, // rotation
            (uri) => {
                console.log(uri.length);
                // You upload logic goes here
                setImage(uri)
            },
            "base64" // blob or base64 default base64
        );
        setEditedPicture(true)
    };


    const page = (
        <>
            <Backdrop open={loading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            <MyModal title={`Are you sure you want to ${isConfirmingDeletion ? "delete" : "end"} the poll '${poll.name}'?`}
                subtitle={`This action is irreversible and ${poll.name} ${isConfirmingDeletion ? "will be irretrievable forever." : "will not be voted on anymore"}`}
                mainButtonText={isConfirmingDeletion ? "Yes, Delete" : "Yes, End"}
                subButtonText="Cancel"
                cb={() => {
                    deletePoll(
                        poll,
                        null,
                        () => {
                            setIsConfirmingDeletion(false)
                            setHasDeleted(true)
                        }
                    )
                }}
                subCb={() => {
                    setIsConfirmingDeletion(false)
                }}
                disabled={!isConfirmingDeletion}
            />
            <MyModal title={`Poll deleted successfully!`}
                subtitle={`You may view the absence of the poll from the ${disruption ? "Disruption " : ""}Polls Overview page now.`}
                mainButtonText={`Back To Upcoming ${disruption ? "Disruption " : ""}Polls`}
                cb={() => {
                    history.push(disruption ? "/disruption_poll?filter=upcoming" : "/poll?filter=upcoming")
                }}
                disabled={!hasDeleted}
            />
            <MyModal title={isSuccessCreation ? modalPreset.pollCreationSuccess.title : modalPreset.pollEditionSuccess.title}
                subtitle={isSuccessCreation ? modalPreset.pollCreationSuccess.subtitle : modalPreset.pollEditionSuccess.subtitle}
                mainButtonText={`Back to ${disruption ? "Disruption " : ""}Polls`}
                cb={() => {
                    history.push(disruption ? "/disruption_poll?filter=all" : "/poll?filter=all")
                }}
                disabled={!(isSuccessCreation || isSuccessEdition)}
            />
            <MyModal title={modalPreset.CreatingPollWithoutRewardWarning.title}
                subtitle={modalPreset.CreatingPollWithoutRewardWarning.subtitle}
                mainButtonText={"Yes, proceed"}
                subButtonText={"Cancel"}
                cb={() => {
                    setIsCreatingWithoutReward(false)
                    setLoading(true)
                    checkPollLimit(addPoll, () => {
                        setIsErrorCreatingTooMany(true)
                        setLoading(false)
                    })
                }}
                subCb={() => {
                    setIsCreatingWithoutReward(false)
                }}
                disabled={!IsCreatingWithoutReward}
            />
            <MyModal title={modalPreset.TooManyPoll(disruption).title}
                subtitle={modalPreset.TooManyPoll(disruption).subtitle}
                mainButtonText={"OK"}
                cb={() => {
                    setIsErrorCreatingTooMany(false)
                }}
                disabled={!isErrorCreatingTooMany}
            />
            <CommonHeader title={(!id) ? `Create a New ${disruption ? "Disruption " : ""}Poll` : `Edit ${disruption ? "Disruption " : ""}Poll`}
                subtitle="Create, delete and view results of polls here." />
            <Container maxWidth="lg" sx={{ p: isMobile ? 0 : 2 }}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setLoading(true);
                        if (id) {
                            editPoll();
                        } else {
                            if (rewards.length > 0) {
                                checkPollLimit(addPoll, () => {
                                    setIsErrorCreatingTooMany(true)
                                    setLoading(false)
                                })
                            } else {
                                setLoading(false)
                                setIsCreatingWithoutReward(true)
                            }
                        }
                    }}
                >
                    <Grid container maxWidth="md" spacing={isMobile ? 0 : 3} >
                        <Grid item md={8}>
                            <Box sx={{
                                borderWidth: 1,
                                borderColor: 'divider',
                                borderStyle: "solid",
                                p: 2
                            }}>
                                <Box>
                                    <Tabs value={tab} onChange={(e, val) => { setTab(val) }} aria-label="tabs">
                                        <Tab label="Content" />
                                        <Tab label="Rewards" />
                                    </Tabs>
                                </Box>
                                <TabPanel value={tab} index={0}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container
                                                direction="column"
                                                spacing={1}
                                                sx={{ m: 0, height: "100%", justifyContent: "space-between", flexWrap: "noWrap" }}
                                                justifyContent="flex-start"
                                                alignItems="stretch"
                                            >
                                                <Grid item sx={{ pl: "0!important" }}>
                                                    <TextField
                                                        name="name"
                                                        onChange={handlePollChange}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="title"
                                                        label="Title"
                                                        value={poll.name}
                                                        defaultValue={poll.description}

                                                    />
                                                </Grid>
                                                <Grid item sx={{ pl: "0!important" }}>
                                                    <TextField
                                                        name="description"
                                                        variant="outlined"
                                                        multiline
                                                        rows={4}
                                                        required
                                                        fullWidth
                                                        id="description"
                                                        onChange={handlePollChange}
                                                        label="Description"
                                                        value={poll.description}
                                                        defaultValue={poll.description}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={6} spacing={1}>
                                            <Grid container
                                                direction="column"
                                                spacing={1}
                                                sx={{ m: 0, height: "100%", justifyContent: "space-between", flexWrap: "noWrap" }}
                                                justifyContent="flex-start"
                                                alignItems="stretch"
                                            >
                                                <Grid item>

                                                    <CardMedia sx={{
                                                        minHeight: "150px",
                                                        backgroundSize: "contain"
                                                    }} image={Image || defaultImage} />

                                                </Grid>
                                                <Grid item sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                                    <Button variant="outlined" color="secondary" component="label">
                                                        Upload Image
                                                        <input
                                                            type="file"
                                                            hidden
                                                            accept="image/*"
                                                            onChange={(e) => {
                                                                EncodePic(e.target.files[0]);
                                                            }}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {(optionArr).map((option) => (
                                            <PollOption
                                                key={option.id}
                                                id={option.id}
                                                option={option}
                                                setOptionArr={setOptionArr}
                                                deleteOption={deleteOption}
                                            />
                                        ))}
                                        {optionArr && optionArr.length < 4 && (
                                            <Grid item xs={12}>
                                                <AddBoxOutlinedIcon sx={{ color: "secondary.main" }}
                                                    onClick={addNewOption} />
                                            </Grid>
                                        )}{" "}
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <RewardAttachment rewards={rewards} setRewards={setRewards} title={"Rewards of Voting"} />
                                </TabPanel>
                            </Box>
                        </Grid>

                        <Grid item md={4} xs={12} sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Grid container rowSpacing={1} sx={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "divider",
                                p: 2,
                                mt: 0
                            }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: "bold" }}>
                                        End Date
                                    </Typography>
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={12}>
                                        <MobileDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Choose Date (MM/DD/YYYY)"
                                            inputFormat="MM/dd/yyyy"
                                            value={poll.end_at}
                                            onChange={(date) => {
                                                setPoll(
                                                    prev => ({ ...prev, end_at: date }))
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <TimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Choose Time"
                                            value={poll.end_at}
                                            onChange={(date) => {
                                                setPoll(prev => ({ ...prev, end_at: date }))
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change end time',
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }}  {...params} />}
                                        />

                                    </Grid>
                                </LocalizationProvider >


                                <Grid item xs={12}>
                                    <FormControlLabel
                                        sx={{
                                            ml: 0,
                                            width:"100%",
                                            display:"flex",
                                            justifyContent:"space-between"
                                        }}
                                        control={
                                            <Switch
                                                name="to_publish"
                                                checked={toPublish}
                                                color="success"
                                                onChange={(e) => {
                                                    setToPublish(e.target.checked);
                                                }}
                                            />
                                        }
                                        label={
                                            <TextWithTooltipIcon text={"publish immediately"}
                                            tooltip={"Once published, polls are no longer editable."}
                                            textStyle={{  fontSize: "0.75rem" }} />
                                        }
                                        labelPlacement="start"
                                    />
                                </Grid>
                                {
                                    (!toPublish) &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography sx={{ fontWeight: "bold" }}>
                                                Start Date
                                            </Typography>
                                        </Grid>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Grid item xs={12}>

                                                <MobileDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="Choose Date (MM/DD/YYYY)"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={poll.start_at}
                                                    onChange={(date) => {
                                                        setPoll(
                                                            prev => ({ ...prev, start_at: date }))
                                                    }}
                                                    renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}

                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    label="Choose Time"
                                                    value={poll.start_at}
                                                    onChange={(date) => {
                                                        setPoll(prev => ({ ...prev, start_at: date }))
                                                    }}
                                                    renderInput={(params) => <TextField sx={{ width: "100%" }}  {...params} />}

                                                />
                                            </Grid>
                                        </LocalizationProvider  >
                                    </>

                                }
                                {DateErrorMsg &&
                                    <Grid item xs={12}>
                                        <Alert severity="error" color="secondary">
                                            {DateErrorMsg.toString()}
                                        </Alert>
                                    </Grid>
                                }
                                {disruption && <Grid item xs={12}>
                                    <Typography sx={{ fontSize: "0.75rem" }}
                                    >
                                        {`This poll will last for ${formatDuration(intervalToDuration({
                                            start: poll.start_at,
                                            end: poll.end_at
                                        }))}, during which all online players will be notified with in-game popups until they have voted.`}
                                    </Typography>
                                </Grid>}
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        disabled={loading || (DateErrorMsg)}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Confirm
                                    </Button>
                                </Grid>

                            </Grid>
                            {id && <Button variant="outlined" sx={{ mt: 3 }} color="secondary" onClick={() => { setIsConfirmingDeletion(true) }}>
                                <DeleteOutlineIcon color="secondary" />
                                Delete Poll
                            </Button>}
                        </Grid>

                    </Grid>
                </form>
            </Container>
        </>
    )
    return <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />
}
