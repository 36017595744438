import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PollDetailView2 from "./PollDetailView2";
import PollEditView2 from "./PollEditView2";
import { Auth } from "aws-amplify";
import SignIn from "./SignIn";
import DownloadData from "./DownloadData";
import NewMail from "./NewMail";
import VoucherEditView from "./VoucherEditView";
import VoucherListView from "./VoucherListView";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './style'
import Landing from "./Landing";
import PollListView from "./PollListView";
import { useHistory, useLocation } from 'react-router';
import DownloadDataPoll from "./downloadDataPoll";
import { IsProdEnv } from "./utils";
import QRListView from "./QRListView";
import QuestEditView from "./QuestEditView";
import VoucherDetailView from "./VoucherDetailView";
import GrossOrderTable from "./GrossOrderTable";
import { SettingsPowerRounded } from "@mui/icons-material";
import PushNotif from "./PushNotif";
import DownloadUserData from "./DownloadUserData";

export default function Routes() {
  const [user, setUser] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (window.location.href.indexOf("jw-admin.lionfish-studios.com") > -1) {
      console = {};
      console.log = function () { };
      console.error = function () { };
    }


    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const logout = () => {
    Auth.signOut()
      .then(() => {
        setUser(null);
      })
      .catch((err) => console.log(err));
  };

  const [openStatus, setStatus] = useState({})

  // useEffect(() => {
  //   console.log(openStatus);
  // }, [openStatus])



  return (
    <>
      {/* <ScopedCssBaseline /> */}
      <ThemeProvider theme={theme}>
        <Router>
          {user ? (
            <Switch>
              <Route exact path="/">
                {/* <App logout={logout}/> */}
                <Landing logout={logout} openStatus={openStatus} setStatus={setStatus} />
              </Route>
              <Route path="/about"></Route>
              <Route path="/dashboard"></Route>
              <Route path="/poll/new" children={<PollEditView2 logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/poll/:id/edit" children={<PollEditView2 logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/poll/:id" children={<PollDetailView2 logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/poll" children={<PollListView logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/disruption_poll/new" children={<PollEditView2 disruption={true} logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/disruption_poll/:id/edit" children={<PollEditView2 disruption={true} logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/disruption_poll/:id" children={<PollDetailView2 disruption={true} logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/disruption_poll" children={<PollListView disruption={true} logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/download-data" children={<DownloadData logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/send-mail" children={<NewMail logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/voucher/new" children={<VoucherEditView logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/voucher/:id/edit" children={<VoucherEditView logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/voucher/:id" children={<VoucherDetailView logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/vouchers" children={<VoucherListView logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/download-data-poll" children={<DownloadDataPoll logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/download-user" children={<DownloadUserData logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/qr" exact children={<QRListView logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/qr/new" exact children={<QuestEditView logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/qr/:id/edit" children={<QuestEditView logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              <Route path="/orders" exact children={<GrossOrderTable logout={logout} openStatus={openStatus} setStatus={setStatus} />} />
              {!IsProdEnv() && <Route path="/push-notif" exact children={<PushNotif logout={logout} openStatus={openStatus} setStatus={setStatus} />} />}
            </Switch>
          ) : (
            <SignIn setCurrentUser={setUser} />
          )}
        </Router>
      </ThemeProvider>
    </>
  );
}
