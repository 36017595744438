import React from 'react'
import WebAssetOffRoundedIcon from '@mui/icons-material/WebAssetOffRounded';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

export default function EmptyList() {
    return (
        (<Box sx={{
            width: "100%", minHeight: "75vh", flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
        }}>
            <WebAssetOffRoundedIcon sx={{
                fontSize: "15rem",
                color: "text.disabled"
            }} />
            <br />
            <br />
            <br />
            <Typography sx={{
                color: "text.disabled"
            }}>This collection is empty!</Typography>
        </Box>)
    )
}
