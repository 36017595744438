import React, { useEffect, useState } from 'react'
import { Backdrop, Button, Box, CircularProgress, Container, Grid, TextField, Typography, Autocomplete, FormControlLabel, Switch, InputLabel, CardMedia } from '@mui/material';
import { IsProdEnv } from './utils';
import { useHistory } from "react-router";
import MyModal, { modalPreset } from './MyModal';
import CommonHeader from './CommonHeader';
import App2 from './App2';
import TextWithTooltipIcon from './TextWithTooltipIcon';
import Auth from '@aws-amplify/auth';
import axios from "axios"
const TITLE_MAX_LENGTH = 60
const BODY_MAX_LENGTH = 155
export default function PushNotif({ logout, openStatus, setStatus }) {
    const allDevStages = ["prebeta", "beta"]
    const [Stage, setStage] = useState(allDevStages[0])
    const [isConfirming, setIsConfirming] = useState(false)
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [Notif, setNotif] = useState({ "title": "", "body": "" })
    //onload
    useEffect(() => {
        if (IsProdEnv()) {
            setStage("prod")
        }

    }, [])

    const handleObjectChange = (e) => {
        setNotif((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };


    const submitNotification = () => {
        setLoading(true)
        let url = ""
        if (Stage === "beta") {
            url = "https://sq59t0jsd1.execute-api.ap-southeast-1.amazonaws.com/beta/notif"
        } else if (Stage === "prebeta") {
            url = "https://g6j3vu336i.execute-api.ap-southeast-1.amazonaws.com/prebeta/notif"
        }

        Auth.currentAuthenticatedUser().then(res => {
            let idToken = res.signInUserSession.idToken.jwtToken
            console.log(idToken)
            axios.post(url, Notif, {
                headers: {
                    "Authorization": idToken
                }
            }).then(res => {
                console.log(res)
                setLoading(false)
                setIsConfirming(false)
            }
            ).catch(err => {
                console.log(err)
                setLoading(false)
            })

        })

    }

    const page = (
        <>
            <Backdrop open={loading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            {
                (isConfirming)
                &&
                <MyModal title={modalPreset.ConfirmPushNotification.title}
                    subtitle={modalPreset.ConfirmPushNotification.subtitle}
                    mainButtonText="Yes"
                    subButtonText="Cancel"
                    cb={submitNotification}
                    subCb={() => setIsConfirming(false)}
                />
            }
            <CommonHeader
                title="Push a Notification"
                subtitle="push notifications to players' mobiles here."
            />

            <Container maxWidth="md">
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setIsConfirming(true)
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <TextWithTooltipIcon
                                text={"Notification Title*"}
                                tooltip={`Maximum ${TITLE_MAX_LENGTH} characters are allowed`}
                                textStyle={{ fontWeight: "bold" }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField name="title"
                                onChange={handleObjectChange}
                                variant="outlined"
                                required
                                fullWidth
                                id="title"
                                value={Notif.title.length > TITLE_MAX_LENGTH ? Notif.title.substring(0, TITLE_MAX_LENGTH) : Notif.title}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextWithTooltipIcon
                                text={"Notification Body*"}
                                tooltip={`Maximum ${BODY_MAX_LENGTH} characters are allowed`}
                                textStyle={{ fontWeight: "bold" }}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField name="body"
                                variant="outlined"
                                multiline
                                rows={7}
                                required
                                fullWidth
                                id="body"
                                onChange={handleObjectChange}
                                value={Notif.body.length > BODY_MAX_LENGTH ? Notif.body.substring(0, BODY_MAX_LENGTH) : Notif.body}
                            />
                        </Grid>

                        {!IsProdEnv() &&
                            <Grid item xs={3}   >
                                <Autocomplete
                                    options={allDevStages}
                                    selectOnFocus
                                    clearOnBlur
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} label="Stage" />}
                                    value={Stage}
                                    onChange={(event, newValue) => {
                                        if (newValue == null)
                                            return
                                        setStage(newValue)
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                    <br />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                    >
                        Confirm
                    </Button>
                </form>
            </Container>

        </>

    )

    return <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />


}
