import { Card, CardMedia, CardContent, Typography, CardActions, Button, IconButton } from '@mui/material'
import pollCover from "./assets/polls_obj_default.png";
import React, { useEffect } from 'react'

export default function PollCard({ title, subtitle, imageStr,
    href1, href2, href3, btnText1, btnText2, btnText3, icon1, icon2, icon3, cb1, cb2, cb3, disabled1, disabled2, disabled3, sx3 }) {

    return (
        <Card sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            borderRadius: 1
        }}>
            <CardMedia
                sx={{ paddingTop: `${3 / 4 * 100}%`, }}
                image={imageStr || pollCover}
                title="Image title"
            />
            <CardContent >
                <Typography
                    sx={{
                        fontWeight: "bold ",
                        fontSize: "1.25rem ",
                    }}
                >
                    {title}
                </Typography>
                <Typography sx={{
                    color: theme => theme.palette.text.light,
                    fontSize: "0.8rem  ",
                }}
                    style={{ whiteSpace: 'pre-line' }} //to recognize newline
                >
                    {`${subtitle}`}
                </Typography>
            </CardContent>
            <CardActions sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                {(btnText3 || icon3) && (
                    <IconButton
                        size="small"
                        href={href3 || "#"}
                        disabled={disabled3}
                        onClick={cb3}
                    >
                        {icon3}
                        <Typography sx={{ fontSize: "0.75rem", textTransform: "capitalize" }}>
                            {btnText3}
                        </Typography>
                    </IconButton>
                )}
                <Button
                    size="small"
                    href={href1 || "#"}
                    fullWidth={!btnText2 && !btnText3}
                    sx={{
                        bgcolor: disabled1 ? "#999" : "#444",
                        color: "#ddd",
                        "&:hover": {
                            bgcolor: "#ddd",
                            color: "#444"
                        }
                    }}
                    disabled={disabled1}
                    onClick={cb1}
                >
                    {icon1}
                    <Typography sx={{ fontSize: "0.75rem", textTransform: "capitalize" }}>
                        {btnText1}
                    </Typography>
                </Button>
                {btnText2 && (
                    <Button
                        size="small"
                        href={href2 || "#"}
                        onClick={cb2}
                        variant="outlined"
                        color="secondary"
                        disabled={disabled2}
                    >
                        {icon2}
                        <Typography sx={{ fontSize: "0.75rem", textTransform: "capitalize" }}>
                            {btnText2}
                        </Typography>
                    </Button>
                )}
            </CardActions>
        </Card>
    )
}
