import App2 from './App2';
import React, { useState, useEffect } from "react";
import {
    CardMedia, Grid, Box, Badge, Typography, Card, LinearProgress, Button, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useMediaQuery,
    Backdrop,
    CircularProgress,
} from "@mui/material"
import { useParams, useHistory } from "react-router";
import { API } from "aws-amplify";
import { theme } from "./style";
import CommonHeader from './CommonHeader';
import pollCover from "./assets/polls_obj_default.png";
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import { deletePoll, getTimespan, importAll, timeAgo, updatePoll } from './utils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MyModal from './MyModal';
import csvDownload from 'json-to-csv-export'
import Storage from '@aws-amplify/storage';

export default function PollDetailView2({ logout, openStatus, setStatus, disruption = false }) {

    let { id } = useParams();
    const [res, setPoll] = useState({});
    const [options, setOptions] = useState([]); //ordered 
    const history = useHistory();
    const [voteCount, setVoteCount] = useState(0);

    // end of region tab
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const images = importAll(require.context('./assets/gf_image', false, /\.(png|jpe?g|svg)$/));
    const [Image, setImage] = useState("")


    useEffect(() => {
        API.get("PollingApi2", (disruption ? `/disruption-polls/object/${id}` : `/polls/object/${id}`))
            .then((res) => {
                let options = {}
                Object.entries(res.options).map(([id, op]) => {
                    options[id] = { ...op, expand: false }
                })
                res.options = options
                setPoll(res);
                let _voteCount = 0;
                if (res.options) {
                    let _options = []
                    Object.entries(res.options).forEach(([oid, option]) => {
                        if (option.votes) {
                            _voteCount += option.votes.length;
                        }
                        if ("index" in option) {
                            _options[option.index] = { ...option, id: oid, expand: false }
                        } else {
                            _options.push({ ...option, id: oid, expand: false })
                        }
                    });
                    setOptions(_options)
                }
                setVoteCount(_voteCount);
                if (res.length <= 0) {
                    history.push("/");
                }
                //fetch the s3 picture
                if (res.img_s3) {
                    Storage.get(`${res.img_s3}`, { level: "public" })
                        .then(signedUrl => {
                            fetch(signedUrl).then(res => res.blob()).then(blob => {
                                var reader = new FileReader();
                                reader.onloadend = function () {
                                    setImage(reader.result)
                                }
                                reader.readAsDataURL(blob);
                            }).catch(err => {
                                console.error(err)
                            })
                        }).catch(err => {
                            console.error(err)
                        })
                }
            })
            .catch((err) => {
                // history.push("/");
            });
    }, [history, id]);

    // debug
    // useEffect(() => {
    //     console.log(poll)
    // }, [poll])

    const download = () => {
        let data = []
        Object.values(res.options).forEach(option => {
            if (option.votes) {
                option.votes.forEach(vote => {
                    data.push({
                        voter: vote.username,
                        department: vote.department || "",
                        office_location: vote.officeLocation || "",
                        option: option.name
                    })
                }
                )
            }
        })
        csvDownload(data, `poll-data-${id}.csv`)
    }

    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false)
    const [isConfirmingEnding, setIsConfirmingEnding] = useState(false)
    const [hasDeleted, setHasDeleted] = useState(false)
    const [hasEnded, setHasEnded] = useState(false)
    const [loading, setLoading] = useState(false)

    const endPoll = () => {
        updatePoll({
            ...res,
            end_at: Math.floor(new Date().getTime()) / 1000 - 1
        }, null, () => {
            setLoading(false)
            setHasEnded(true)
        })
    };

    const page = (
        <>
            <Backdrop open={loading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            {
                (isConfirmingDeletion || isConfirmingEnding)
                &&
                <MyModal title={`Are you sure you want to ${isConfirmingDeletion ? "delete" : "end"} the poll '${res.name}'?`}
                    subtitle={`This action is irreversible and ${res.name} ${isConfirmingDeletion ? "will be irretrievable forever." : "will not be voted on anymore"}`}
                    mainButtonText={isConfirmingDeletion ? "Yes, Delete" : "Yes, End"}
                    subButtonText="Cancel"
                    cb={() => {
                        if (isConfirmingDeletion) {
                            deletePoll(
                                res,
                                null,
                                () => {
                                    setLoading(false)
                                    setHasDeleted(true)
                                    setIsConfirmingDeletion(false)
                                }
                            )
                        } else {
                            endPoll()
                            setIsConfirmingEnding(false)
                        }
                    }}
                    subCb={() => {
                        setIsConfirmingDeletion(false)
                        setIsConfirmingEnding(false)
                    }}
                />
            }
            <MyModal title={`Poll deleted successfully!`}
                subtitle={`You may view the absence of the poll from the Polls Overview page now.`}
                mainButtonText="Back To Polls"
                cb={() => {
                    history.push("/poll?filter=all")
                }}
                disabled={!hasDeleted}
            />
            <MyModal title={`Poll ended successfully!`}
                subtitle={`This poll no longer accept any votes.`}
                mainButtonText="Back To Polls"
                subButtonText="Continue"
                cb={() => {
                    history.push("/poll?filter=all")
                }}
                subCb={() => {
                    window.location.reload(false)
                }}
                disabled={!hasEnded}
            />
            <Grid container rowSpacing={0} columnSpacing={isMobile ? 0 : 6} sx={{ margin: 0, width: "100%" }}>
                <CommonHeader title={`${res.is_disruption ? "Disruption " : ""} Poll`} subtitle={`View ${res.is_disruption ? "disruption " : ""}poll details`} />
                <Grid item md={8} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <CardMedia image={Image || pollCover}
                                sx={{ paddingTop: "100%", backgroundSize: "contain" }} />
                        </Grid>
                        <Grid item xs={12} sm={8} sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-evenly"
                        }}>
                            <Box>
                                <Badge color={(res.end_at > Math.floor(Date.now() / 1000)) ? "warning" : "success"} sx={{
                                    "& .MuiBadge-badge": {
                                        color: "white",
                                        fontWeight: "bold",
                                        transform: "inherit",
                                        position: "inherit"
                                    }
                                }} badgeContent={(res.end_at > Math.floor(Date.now() / 1000)) ? "Ongoing" : "Completed"} />
                            </Box>
                            <Box>

                                <Typography variant="h4" sx={{ fontWeight: "bold" }}>{res.name}</Typography>
                                <Typography sx={{ color: "text.primary" }}>{res.description}</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ color: "text.disabled" }}>Created  {res.created_at && timeAgo.format(res.created_at * 1000)}</Typography>
                                <Typography sx={{ color: "text.disabled" }}>Published on  {new Date(res.created_at * 1000).toDateString() + ", " + new Date(res.created_at * 1000).toLocaleTimeString()}</Typography>
                                <Typography sx={{ color: "text.disabled" }}>Ends at {new Date(res.end_at * 1000).toDateString() + ", " + new Date(res.end_at * 1000).toLocaleTimeString()}</Typography>
                            </Box>
                        </Grid>

                    </Grid>
                    {options && options.length > 0 &&
                        options.map((option, index) => (
                            <Card key={index} sx={{ my: 4, p: 2, boxShadow: "5px 5px 10px 0px rgb(0 0 0 / 20%), -5px 5px 10px 0px rgb(0 0 0 / 20%)" }}>
                                <Grid container>
                                    <Grid item xs={9}>
                                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                            {option.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sx={{ textAlign: "right" }}>
                                        <Typography sx={{ fontWeight: "bold" }}>
                                            {voteCount > 0 ?
                                                ((option.votes.length / voteCount) * 100).toFixed(2) +
                                                "%"
                                                : "No Votes"
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Typography variant="caption">
                                    {option.description}
                                </Typography>
                                <br />
                                <br />
                                <Box sx={{ pl: 2 }}>
                                    <LinearProgress
                                        sx={{
                                            padding: theme.spacing(1),
                                            borderRadius: "4px",
                                            p: 0.5
                                        }}
                                        variant="determinate"
                                        color="secondary"
                                        value={(option.votes.length / voteCount) * 100}
                                    />
                                    <Typography variant="caption">
                                        {option.votes.length} votes
                                    </Typography>
                                    <Box sx={{ textAlign: "right" }}>
                                        <Button onClick={() => {
                                            let copy = [...options]
                                            copy[index] = { ...option, expand: !option.expand }
                                            setOptions(copy)
                                        }}>
                                            <Typography variant="caption">
                                                View Voters
                                            </Typography>
                                            {option.expand
                                                ? <ExpandLessRounded sx={{ verticalAlign: "middle" }} />
                                                : <ExpandMoreRounded sx={{ verticalAlign: "middle" }} />}
                                        </Button>
                                    </Box>
                                </Box>
                                {/* #region player votes */}

                                {option.expand && option.votes && (
                                    <TableContainer
                                        component={Box}
                                    >
                                        <Table
                                            aria-label="vote table"
                                            size="small"
                                        >
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell sx={{ fontWeight: "bold" }}>Player</TableCell>
                                                    <TableCell align="right" sx={{ fontWeight: "bold" }}>Voted On</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {option.votes.map((vote) => (
                                                    <TableRow key={vote.playerId}>
                                                        <TableCell component="th" scope="row">
                                                            <Typography sx={{ fontSize: "0.7rem" }}>
                                                                {vote.username} | {vote.department || "?"} | {vote.officeLocation || "?"}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Typography noWrap sx={{ fontSize: "0.7rem" }}>
                                                                {timeAgo.format(vote.created_at * 1000)}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                                {/* #endregion */}

                            </Card>
                        ))}
                </Grid>


                <Grid item md={4} xs={12} sx={{
                    mt: isMobile ? 1 : 6,
                    // p: "0 !important",
                    pr: isMobile ? 1 : 8,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                }}>
                    <Box sx={{ width: "100%" }}>
                        <Button fullWidth color="secondary" variant="contained" sx={{ boxShadow: "none" }} onClick={download}>Download .csv</Button>
                        <Box sx={{ p: 2, borderColor: "divider", borderStyle: "solid", borderWidth: "1px", my: 2 }}>
                            <Box>
                                <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                                    Overview
                                </Typography>
                            </Box>
                            <Table sx={{ ".MuiTableCell-root": { px: 0, py: 1 } }}>
                                <TableRow sx={{ color: "text.disabled" }}>
                                    <TableCell component="th" scope="row">
                                        <Typography sx={{ fontSize: "0.8rem" }}>
                                            Total Votes
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontWeight: "bold" }}>
                                            {voteCount}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ color: "text.disabled" }}>
                                    <TableCell component="th" scope="row">
                                        <Typography sx={{ fontSize: "0.8rem" }}>
                                            Poll Uptime
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography noWrap sx={{ fontWeight: "bold" }}>
                                            {getTimespan(res.start_at, res.end_at)}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </Table>
                            {
                                (res.end_at > Math.floor(Date.now() / 1000)) &&
                                <Button fullWidth variant="outlined" color="secondary" onClick={() => { setIsConfirmingEnding(true) }}>End Poll</Button>
                            }
                        </Box>
                        <Box sx={{ p: 2, borderColor: "divider", borderStyle: "solid", borderWidth: "1px", my: 2 }}>
                            <Box>
                                <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                                    Poll Rewards
                                </Typography>
                                {res.rewards && images && res.rewards.map((reward, rid) => (
                                    <Card key={rid} sx={{ my: 2, p: 1, boxShadow: "5px 5px 5px 0px rgb(0 0 0 / 20%), -5px 5px 10px 0px rgb(0 0 0 / 20%)" }}>
                                        <Grid container sx={{ p: 0.5, borderRadius: "2px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <Box component="img"
                                                src={images[reward.key] ? images[reward.key].default : pollCover}
                                                alt={reward.key}
                                                sx={{
                                                    p: 0.25,
                                                    height: "45px",
                                                    width: "45px",
                                                    objectFit: "contain",
                                                    bgcolor: "divider",
                                                    borderRadius: "5px"
                                                }}
                                            />
                                            <Typography sx={{ color: "text.disabled", fontSize: "0.75rem" }}>{reward.key}</Typography>
                                            <Typography sx={{ color: "text.disabled", fontSize: "0.75rem", fontWeight: "bold" }}>{reward.quantity}</Typography>
                                        </Grid>
                                    </Card>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    <Button variant="outlined" color="secondary" onClick={() => { setIsConfirmingDeletion(true) }}>
                        <DeleteOutlineIcon color="secondary" />
                        Delete Poll
                    </Button>
                </Grid>
            </Grid>
        </>
    );
    return (
        <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />
    )
}
