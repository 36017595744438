import React, { useState, useEffect } from "react";

import {
    Button,
    Typography,
    Container,
    TextField,
    Grid,
    CircularProgress,
    useMediaQuery,
    Backdrop,
    Autocomplete,
} from "@mui/material";
import Storage from "@aws-amplify/storage";
import Amplify from "aws-amplify";
import csvDownload from "json-to-csv-export";
import App2 from "./App2";
import CommonHeader from "./CommonHeader";
import { Box, useTheme } from "@mui/system";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { IsProdEnv, MAX_DATA_BATCH } from "./utils";
import endOfMonth from "date-fns/endOfMonth";
import { dateRangePickerDayClasses } from "@mui/lab";

Amplify.configure({
    Storage: {
        AWSS3: {
            bucket: "joyworld-data-lake-prebeta", //REQUIRED -  Amazon S3 bucket name
            region: "ap-southeast-1", //OPTIONAL -  Amazon service region
        },
    },
});

export default function DownloadData({ logout, openStatus, setStatus }) {
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    let allStages = IsProdEnv() ? ["prod"] : ["prebeta", "beta"];
    const [stage, setStage] = useState(allStages[0]);
    const [allYearOptions, setAllYearOptions] = useState([]);
    const [allMonthOptions, setAllMonthOptions] = useState([]);
    const [FileUrlMap, setFileUrlMap] = useState({});
    const [IsRetrieving, setIsRetrieving] = useState(false);
    const [IsDownloading, setIsDownloading] = useState(false);
    const [batchedFileUrl, setBatchedFileUrl] = useState([]);
    // let allYearOptions = []
    // let allMonthOptions = []

    useEffect(() => {
        let _allYearOptions = [];
        let _allMonthOptions = [];
        let today = new Date();
        for (
            let i = new Date(2021, 1, 1).getFullYear();
            i <= today.getFullYear();
            i++
        ) {
            _allYearOptions.push("" + i);
        }
        for (let i = 1; i <= 12; i++) {
            if (i < 10) {
                _allMonthOptions.push("0" + i);
            } else {
                _allMonthOptions.push("" + i);
            }
        }
        console.log(_allYearOptions);
        console.log(_allMonthOptions);
        setAllYearOptions(_allYearOptions);
        setAllMonthOptions(_allMonthOptions);
        if (_allYearOptions.length > 0) {
            setYear(_allYearOptions[_allYearOptions.length - 1]);
        }
        setMonth(
            today.getMonth() >= 9
                ? (today.getMonth() + 1).toString()
                : "0" + (today.getMonth() + 1)
        );
    }, []);

    useEffect(() => {
        fetchFiles();
    }, [year, month, stage]);

    //when year/mon/stage is changed, immediately fetch files and store it in FileUrlMap
    const fetchFiles = () => {
        setFileUrlMap({});
        //#region get the dates in the month
        let dates = []; //valid dates for subfolders later on
        let today = new Date();
        let currMonth = today.getMonth() + 1; //adjust to be conventional ie june is 6
        if (currMonth < 10) {
            currMonth = "0" + currMonth;
        }
        console.log(
            `latest of selected month ${endOfMonth(new Date(year, month - 1, 1))}`
        );
        console.log(`today is ${today}`);
        let maxDt =
            endOfMonth(new Date(year, month - 1, 1)) > today && currMonth === month
                ? today
                : endOfMonth(new Date(year, month - 1, 1)); //selected month is the current month?
        for (let dt = 1; dt <= maxDt.getUTCDate(); dt++) {
            dates.push(dt < 10 ? "0" + dt : dt.toString());
        }
        console.log(`Date folder to search thru: ${JSON.stringify(dates)}`);
        // console.log()
        //#endregion
        let listPromise = dates.map((dt) =>
            Storage.list(`${year}/${month}/${dt}`, {
                bucket: `joyworld-data-lake-${stage}`,
                level: "public",
                cacheControl: "no-cache",
                customPrefix: {
                    public: "",
                },
            })
        );
        let _FileUrlMap = {};
        let filenames = [];
        let ayncOps = [];

        Promise.allSettled(listPromise)
            .then((listResults) => {
                //list all files according to y/m/d
                listResults.forEach((result, index) => {
                    console.log(result);
                    result.value.forEach((re) => {
                        filenames.push(re.key);
                        ayncOps.push(
                            Storage.get(re.key, {
                                customPrefix: {
                                    public: "",
                                },
                                level: "public",
                                bucket: `joyworld-data-lake-${stage}`,
                            })
                        );
                    });
                });
                console.log(
                    `${ayncOps.length} number of s3 files to download and combine`
                );
                Promise.allSettled(ayncOps)
                    .then((results) => {
                        results.forEach((result, index) => {
                            _FileUrlMap[filenames[index]] = result.value;
                        });
                        setFileUrlMap(_FileUrlMap);
                        setIsRetrieving(false);
                    })
                    .catch((err) => {
                        console.log(err);
                        setFileUrlMap({});
                        setIsRetrieving(false);
                    });
            })
            .catch((err) => {
                console.log(err);
                setFileUrlMap({});
                setIsRetrieving(false);
            });
        // Storage.list(`${year}/${month}`, {
        //     bucket: `joyworld-data-lake-${stage}`,
        //     level: "public",
        //     cacheControl: 'no-cache',
        //     customPrefix: {
        //         public: ""
        //     },
        // }) // for listing ALL files without prefix, pass '' instead
        //     .then(result => {
        //         let _FileUrlMap = {}
        //         console.log(result)
        //         let filenames = []
        //         let ayncOps = []
        //         result.forEach((re) => {
        //             filenames.push(re.key)
        //             ayncOps.push(Storage.get(re.key, {
        //                 customPrefix: {
        //                     public: ""
        //                 },
        //                 level: "public",
        //                 bucket: `joyworld-data-lake-${stage}`,
        //             }))

        //         })
        //         Promise.allSettled(ayncOps).then((results) => {
        //             results.forEach((result, index) => {
        //                 _FileUrlMap[filenames[index]] = result.value
        //             })
        //             setFileUrlMap(_FileUrlMap)
        //             setIsRetrieving(false)
        //         });
        //     })
        //     .catch(err => {
        //         console.log(err)
        //         setFileUrlMap({})
        //         setIsRetrieving(false)
        //     });
    };

    useEffect(() => {
        console.log(
            `FileUrlMap updated, ${Object.keys(FileUrlMap).length} entires`
        );

        let input = Object.values(FileUrlMap);
        let chunked = [];
        let size = MAX_DATA_BATCH;

        for (let i = 0; i < input.length; i += size) {
            chunked.push(input.slice(i, i + size));
        }
        console.log(`num of batches ${chunked.length}`)
        if (chunked.length > 0) {
            console.log(`len of first batch of file ${chunked[0].length}`)
            console.log(`len of last batch of file ${chunked[chunked.length - 1].length}`)
        }
        setBatchedFileUrl(chunked);
    }, [FileUrlMap]);
    useEffect(() => {
        console.log(batchedFileUrl.length)
    }, [batchedFileUrl])
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const downloadData = (batch, batchIdx) => {
        // console.log("batch" + JSON.stringify(batch))
        setIsDownloading(true);
        let fullString = "";
        let asyncOps = [];
        batch.forEach((url) => {
            asyncOps.push(
                fetch(url, { method: "GET" }).then((response) => {
                    return response.text();
                })
            );
        });

        Promise.allSettled(asyncOps)
            .then((results, index) => {
                results.forEach((result) => {
                    if (result.status === "fulfilled") {
                        fullString += result.value;
                    } else {
                        console.log(result);
                    }
                });
                // console.log(fullString)
                let entries = fullString.split("\n");
                let _jsonEntries = [];
                console.log(entries.length);
                entries.forEach((entry) => {
                    if (entry.trim().length > 0) {
                        try {
                            let obj = JSON.parse(entry.trim());
                            if ("reasopn" in obj) {
                                obj.reason = obj.reasopn;
                                delete obj.reasopn;
                            }
                            _jsonEntries.push(obj);
                        } catch (err) {
                            // console.log(err);
                            // console.log(entry);
                        }
                    }
                });
                setIsDownloading(false);
                csvDownload(
                    _jsonEntries,
                    `joyworld-data-${year}-${month}-${stage}-b${batchIdx}.csv`
                );
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const page = (
        <>
            <Backdrop open={IsRetrieving || IsDownloading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            <CommonHeader
                title={"Download Data"}
                subtitle="Download data collected from the game for Data Analytics and more. (GMT timezone)"
            />

            <Container maxWidth="lg" sx={{ p: isMobile ? 0 : 2 }}>
                <Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                        Select Time Period
                    </Typography>
                    <Grid
                        container
                        sx={{ my: 2 }}
                        justify="space-between"
                        alignItems="flex-end"
                        spacing={2}
                    >
                        {allStages.length > 1 && (
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={allStages}
                                    selectOnFocus
                                    clearOnBlur
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField {...params} label="Stage" />
                                    )}
                                    value={stage}
                                    onChange={(event, newValue) => {
                                        if (newValue == null) return;
                                        setStage(newValue);
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                options={allYearOptions}
                                selectOnFocus
                                clearOnBlur
                                fullWidth
                                renderInput={(params) => <TextField {...params} label="Year" />}
                                value={year}
                                onChange={(event, newValue) => {
                                    if (newValue == null) return;
                                    setYear(newValue);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete
                                options={allMonthOptions}
                                selectOnFocus
                                clearOnBlur
                                fullWidth
                                renderInput={(params) => (
                                    <TextField {...params} label="Month" />
                                )}
                                value={month}
                                onChange={(event, newValue) => {
                                    if (newValue == null) return;
                                    setMonth(newValue);
                                }}
                            />
                        </Grid>

                    </Grid>
                    {batchedFileUrl.map((batch, batchIdx) => (
                        <Grid container spacing={0} key={batchIdx}>
                            <Grid item xs={3} sx={{ display: "flex", alignItems: 'center' }}>
                                <Typography >
                                    File {batchIdx * MAX_DATA_BATCH + 1} - {Math.min((batchIdx + 1) * MAX_DATA_BATCH, Object.keys(FileUrlMap).length)}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    sx={{ mt: 1, boxShadow: "none" }}
                                    disabled={IsDownloading || Object.keys(FileUrlMap).length <= 0}
                                    onClick={(e) => { 
                                        console.log(batch)
                                        downloadData(batch, batchIdx) 
                                    }}
                                >
                                    {Object.keys(FileUrlMap).length <= 0 ? "No Data" : `Download`}
                                </Button>
                            </Grid>
                            <Grid item xs={'auto'} />
                        </Grid>
                    ))}
                </Box>

                {/* <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, boxShadow: "none" }}
                    disabled={IsDownloading || Object.keys(FileUrlMap).length <= 0}
                    onClick={downloadData}
                >
                    {Object.keys(FileUrlMap).length <= 0 ? "No Data" : `Download`}
                </Button> */}
            </Container>
        </>
    );
    return (
        <App2
            page={page}
            logout={logout}
            openStatus={openStatus}
            setStatus={setStatus}
        />
    );
}
