import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import Compress from "react-image-file-resizer";
import { Button, CardMedia, Container, Grid, TextField, RadioGroup, FormLabel, FormControlLabel, Radio, Checkbox, Backdrop, CircularProgress, useMediaQuery, Tabs, Tab, Typography, Switch, Autocomplete, InputLabel, Alert } from "@mui/material";
import { subDays } from "date-fns";

import { dataURLtoFile, deleteQuest, fetchGFAsset, isValidDate, MAX_QR_QUEST, printQRCode, updateQuest } from "./utils";
import Storage from '@aws-amplify/storage';
import App2 from "./App2";
import MyModal, { modalPreset } from "./MyModal";
import CommonHeader from "./CommonHeader"
import { Box, useTheme } from "@mui/system";
import { TabPanel } from "./PollEditView2";
import QuestCover from "./assets/polls_obj_default.png";

import MobileDatePicker from '@mui/lab/MobileDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TimePicker from '@mui/lab/TimePicker';
import { addHours } from "date-fns/esm";
import { QRCode } from "react-qrcode-logo";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TextWithTooltipIcon from "./TextWithTooltipIcon";
import RewardAttachment from "./RewardAttachment";


export default function QuestEditView({ logout, openStatus, setStatus }) {

    const history = useHistory();
    const [Image, setImage] = useState("")
    let { id } = useParams();
    const [EditedPicture, setEditedPicture] = useState(false) //used in edit mode only
    const [loading, setLoading] = useState(false)
    const [Quest, setQuest] = useState({
        header: "Quest",
        description: "",
        passcode: "",
        start_at: new Date(),
        end_at: addHours(new Date(), 1),
        start_view: subDays(new Date(), 1),
        end_view: addHours(new Date(), 1),
    })
    const [isSuccessCreation, setIsSuccessCreation] = useState(false)
    const [isSuccessEdition, setIsSuccessEdition] = useState(false)
    const [tab, setTab] = useState(0)
    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false)
    const [hasDeleted, setHasDeleted] = useState(false)
    const [rewards, setRewards] = useState([]);
    const [allRewardOptions, setAllRewardOptions] = useState([]);
    const [isMissingReward, setIsMissingReward] = useState(false)
    const [isErrorCreatingTooMany, setIsErrorCreatingTooMany] = useState(false)

    const [DateErrorMsg, setDateErrorMsg] = useState("")

    useEffect(() => {
        if (isValidDate(Quest.start_at) && isValidDate(Quest.end_at) && isValidDate(Quest.start_view) && isValidDate(Quest.end_view)) {
            setDateErrorMsg("")
        } else {
            setDateErrorMsg("Invalid Date(s)")
        }
    }, [Quest.end_at, Quest.end_view, Quest.start_at, Quest.start_view])


    const handleQuestChange = (e) => {
        setQuest((prev) => (
            (e.target.name === "price_gem" || e.target.name === "quantity") ?
                {
                    ...prev,
                    [e.target.name]: e.target.valueAsNumber
                }
                : {
                    ...prev,
                    [e.target.name]: e.target.value || e.target.checked || "",
                }
        ));
    };

    const WasStarted = () => {
        if (id) {
            return (new Date().getTime()) > Quest.start_at.getTime()
        }
        else {
            return false
        }
    }

    useEffect(() => { console.log(Quest) }, [Quest])

    useEffect(() => {
        setLoading(true)
        fetchGFAsset((_allRewardOptions) => {
            setAllRewardOptions(_allRewardOptions);
            console.log(_allRewardOptions);
            //if the Quest id is present, get the item from db
            if (id) {
                API.get("JoyworldQRApi", `/qr-quest/object/${id}`).then((quest) => {
                    if (quest.length <= 0) {
                        //No item was found
                        history.push("/");
                    } else {
                        setQuest({
                            ...quest,
                            start_at: new Date(quest.start_at * 1000),
                            end_at: new Date(quest.end_at * 1000),
                            start_view: new Date(quest.start_view * 1000),
                            end_view: new Date(quest.end_view * 1000),
                        }); //"decode" the dates
                        let _rewards = []
                        //unpack rewards
                        if (typeof (quest.rewards) != "undefined") {
                            quest.rewards.forEach(re => {
                                _rewards.push(
                                    {
                                        content: _allRewardOptions.find(rew => rew.m_Key === re.key),
                                        quantity: re.quantity
                                    }
                                )
                            });
                        }
                        setRewards(_rewards);
                    }
                    //fetch the s3 picture
                    if (quest.img_s3) {
                        Storage.get(`${quest.img_s3}`, { level: "public" }).then(signedUrl => {
                            console.log(signedUrl)
                            fetch(signedUrl).then(res => res.blob()).then(blob => {
                                var reader = new FileReader();
                                reader.onloadend = function () {
                                    // console.log('RESULT', reader.result)
                                    setImage(reader.result)
                                }
                                reader.readAsDataURL(blob);
                                // setImage(res.body)
                            }).catch(err => {
                                console.error(err)
                            })
                        }).catch(err => {
                            console.error(err)
                        })
                    }
                    setLoading(false)

                })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setLoading(false)
            }
        })
    }, [history, id]);


    useEffect(() => {

    }, [history, id])

    const EncodePic = (file) => {
        //resize
        Compress.imageFileResizer(
            file, // the file from input
            1024, // width
            1024, // height
            "PNG", // compress format WEBP, JPEG, PNG
            50, // quality
            0, // rotation
            (uri) => {
                console.log(uri.length);
                // You upload logic goes here
                setImage(uri)
            },
            "base64" // blob or base64 default base64
        )
        setEditedPicture(true)
    }

    const createQuest = (e) => {
        console.log("createQuest")
        const _id = uuidv4();
        const _postQuest = (signedUrl) => API.post("JoyworldQRApi", "/qr-quest", {
            body: {
                ...Quest,
                id: _id,
                createdOn: Math.floor(Date.now() / 1000),
                start_at: Math.floor(Quest.start_at.getTime() / 1000),
                end_at: Math.floor(Quest.end_at.getTime() / 1000),
                start_view: Math.floor(Quest.start_view.getTime() / 1000),
                end_view: Math.floor(Quest.end_view.getTime() / 1000),
                img_s3: signedUrl,
                rewards: rewards.map(rew => ({ key: rew.content.m_Key, quantity: rew.quantity }))
            }
        }).then(res => {
            setLoading(false)
            setIsSuccessCreation(true)
        }).catch(err => {
            console.error(err);
        });

        if (Image) {
            let file = dataURLtoFile(Image, _id + ".png") //filename will be the same as the quest id
            Storage.put(`qr/${file.name}`, file, {
                level: "public",
            }).then(res => {
                _postQuest(res.key)
            }).catch(err => {
                console.error(err)
            })
        } else {
            _postQuest("")
        }
    }

    const editQuest = (e) => {
        console.log("editQuest")
        let file
        try { file = dataURLtoFile(Image, Quest.id + ".png") } catch (err) { }//filename will be the same as the quest id
        if (EditedPicture) {
            Storage.put(`qr/${file.name}`, file, {
                level: "public",
            }).then(res => {
                console.log(res)
                _editQuest(res.key)
            }).catch(err => {
                console.error(err)
            })
        } else {
            _editQuest()
        }

    }
    const _editQuest = (imageKey) => {
        let body = {
            ...Quest,
            start_at: Math.floor(Quest.start_at / 1000),
            end_at: Math.floor(Quest.end_at / 1000),
            start_view: Math.floor(Quest.start_view / 1000),
            end_view: Math.floor(Quest.end_view / 1000),
            rewards: rewards.map(rew => ({ key: rew.content.m_Key, quantity: rew.quantity }))
        }
        if (imageKey) {
            body.img_s3 = `${imageKey}`
        }
        updateQuest(body, null, (res) => {
            setLoading(false)
            setIsSuccessEdition(true);
        })

    }
    const checkQuestLimit = (successCB, errorCB) => {
        API.get("JoyworldQRApi", "/qr-quest/id", {})
            .then((quests) => {
                let _ongoingUpcoming = quests.filter((qu) => qu["end_at"] * 1000 > new Date())
                if (_ongoingUpcoming.length >= MAX_QR_QUEST) {
                    errorCB()
                } else {
                    successCB()
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const page = (
        <>
            <Backdrop open={loading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            {
                (isSuccessCreation || isSuccessEdition)
                // (true)
                &&
                <MyModal title={isSuccessCreation ? modalPreset.questCreationSuccess.title : modalPreset.questEditionSuccess.title}
                    subtitle={isSuccessCreation ? modalPreset.questCreationSuccess.subtitle : modalPreset.questEditionSuccess.subtitle}
                    mainButtonText="Back to Quests"
                    subButtonText={isSuccessCreation ? "Make Another Quest" : null}
                    cb={() => {
                        history.push("/qr?filter=ongoing")
                    }}
                    subCb={isSuccessCreation ? () => {
                        window.location.reload(false)
                    } : null}
                />
            }
            {
                (isConfirmingDeletion)
                &&
                <MyModal title={modalPreset.deleteQuestConfirm.title}
                    subtitle={modalPreset.deleteQuestConfirm.subtitle}
                    mainButtonText="Yes, Delete"
                    cb={() => {
                        deleteQuest(Quest, null, () => {
                            setLoading(false)
                            setHasDeleted(true)
                        })
                    }}
                    subButtonText="Cancel"
                    subCb={() => { setIsConfirmingDeletion(false) }}
                />
            }
            {
                (isMissingReward)
                &&
                <MyModal title={modalPreset.CreatingQuestWithoutRewardError.title}
                    subtitle={modalPreset.CreatingQuestWithoutRewardError.subtitle}
                    mainButtonText="OK"
                    cb={() => {
                        setIsMissingReward(false);
                    }}
                />
            }
            {
                (hasDeleted)
                &&
                <MyModal title={modalPreset.questDeletionSuccess.title}
                    subtitle={modalPreset.questDeletionSuccess.subtitle}
                    mainButtonText="Back to Quests"
                    cb={() => {
                        history.push("/qr?filter=all")
                    }}
                />
            }

            <MyModal title={modalPreset.TooManyQuest.title}
                subtitle={modalPreset.TooManyQuest.subtitle}
                mainButtonText={"OK"}
                cb={() => {
                    setIsErrorCreatingTooMany(false)
                }}
                disabled={!isErrorCreatingTooMany}
            />

            <CommonHeader title={(!id) ? "Create a New Quest" : "Edit Quest"} subtitle="Create an in-game redeemable quest for players to enjoy the fruits of their labour!" />
            <Container maxWidth="lg" sx={{ p: isMobile ? 0 : 2 }}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        //check no reward error
                        if (rewards.length <= 0) {
                            setIsMissingReward(true);
                        } else {
                            setLoading(true);
                            if (id) {
                                editQuest();
                            } else {
                                checkQuestLimit(() => {
                                    createQuest();
                                },
                                () => {
                                    setIsErrorCreatingTooMany(true);
                                    setLoading(false)
                                })
                            }
                        }
                    }}
                >
                    <Grid container maxWidth="md" spacing={isMobile ? 0 : 3} >
                        <Grid item md={8}>
                            <Box sx={{
                                borderWidth: 1,
                                borderColor: 'divider',
                                borderStyle: "solid",
                                p: 2
                            }}>
                                <Box>
                                    <Tabs value={tab} onChange={(e, val) => { setTab(val) }} aria-label="tabs">
                                        <Tab label="Content" />
                                        <Tab label="Rewards" />
                                        <Tab label="QR Code" disabled={!id} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={tab} index={0}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container
                                                direction="column"
                                                spacing={1}
                                                sx={{ m: 0, height: "100%", justifyContent: "space-between", flexWrap: "noWrap" }}
                                                justifyContent="flex-start"
                                                alignItems="stretch"
                                            >
                                                <Grid item>
                                                    <TextField
                                                        name="header"
                                                        onChange={handleQuestChange}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                        id="header"
                                                        label="Quest Title"
                                                        value={Quest.header}
                                                        defaultValue={Quest.description}
                                                    />
                                                </Grid>
                                                <Grid item >
                                                    <TextField
                                                        name="description"
                                                        variant="outlined"
                                                        multiline
                                                        rows={4}
                                                        required
                                                        fullWidth
                                                        id="description"
                                                        onChange={handleQuestChange}
                                                        label="Description"
                                                        value={Quest.description}
                                                        defaultValue={Quest.description}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={6} spacing={1}>
                                            <Grid container
                                                direction="column"
                                                spacing={1}
                                                sx={{ m: 0, height: "100%", justifyContent: "space-between", flexWrap: "noWrap" }}
                                                justifyContent="flex-start"
                                                alignItems="stretch"
                                            >
                                                <Grid item>
                                                    {
                                                        <CardMedia sx={{
                                                            minHeight: "150px",
                                                            backgroundSize: "contain"
                                                        }} image={Image || QuestCover} />
                                                    }
                                                </Grid>
                                                <Grid item sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                                    <Button variant="outlined" color="secondary" component="label"
                                                    >
                                                        Upload Image
                                                        <input
                                                            type="file"
                                                            hidden
                                                            accept="image/*"
                                                            onChange={(e) => {
                                                                EncodePic(e.target.files[0]);
                                                            }}
                                                        />
                                                    </Button>
                                                </Grid>


                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <RewardAttachment rewards={rewards} setRewards={setRewards} title={"Rewards of Voting"} />
                                </TabPanel>
                                <TabPanel value={tab} index={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} >
                                            <Button
                                                fullWidth
                                                variant="outlined"
                                                color="info"
                                                onClick={() => { printQRCode(Quest.id) }}
                                            >
                                                Print QR Code
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sx={{
                                            "canvas": {
                                                width: "100% !important",
                                                height: "100% !important"
                                            }
                                        }}>
                                            <QRCode value={Quest.passcode}
                                                id={Quest.id}
                                                logoImage={`${process.env.PUBLIC_URL}/logo.png`}
                                                size={720} //controls the downloaded size
                                                // logoWidth={1280*.3}
                                                // logoHeight={1280*.3}
                                                // logoOpacity={0.5}
                                                // qrStyle="dots"
                                                height={400}
                                                width={400}
                                                ecLevel="H"
                                                fgColor={"#220808"}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                            </Box>
                        </Grid>
                        <Grid item md={4}>
                            <Grid container rowSpacing={1} sx={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "divider",
                                p: 2,
                                mt: 0
                            }}>
                                <Grid item xs={12}>
                                    <TextWithTooltipIcon text={"Quest Start Date"}
                                        tooltip={"Quest QR starts to be scannable by players. Cannot be edited once starts."}
                                        textStyle={{ fontWeight: "bold" }} />
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={12}>
                                        <MobileDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Choose Date (MM/DD/YYYY)"
                                            inputFormat="MM/dd/yyyy"
                                            value={Quest.start_at}
                                            onChange={(date) => {
                                                setQuest(
                                                    prev => ({ ...prev, start_at: date }))
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                                            disabled={WasStarted()}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Choose Time"
                                            value={Quest.start_at}
                                            onChange={(date) => {
                                                setQuest(prev => ({ ...prev, start_at: date }))
                                            }}
                                            disabled={WasStarted()}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }}  {...params} />}

                                        />
                                    </Grid>
                                </LocalizationProvider  >

                                <Grid item xs={12}>
                                    <TextWithTooltipIcon
                                        text={"Quest End Date"}
                                        tooltip={"Quest QR stops being scannable by players"}
                                        textStyle={{ fontWeight: "bold" }} />
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={12}>
                                        <MobileDatePicker
                                            margin="normal"
                                            label="Choose Date (MM/DD/YYYY)"
                                            inputFormat="MM/dd/yyyy"
                                            value={Quest.end_at}
                                            onChange={(date) => {
                                                setQuest(
                                                    prev => ({ ...prev, end_at: date }))
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <TimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Choose Time"
                                            value={Quest.end_at}
                                            onChange={(date) => {
                                                setQuest(prev => ({ ...prev, end_at: date }))
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change end time',
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }}  {...params} />}
                                        />

                                    </Grid>
                                </LocalizationProvider >

                                <Grid item xs={12}>
                                    <TextWithTooltipIcon
                                        text={"Viewable from"}
                                        tooltip={"Quest and its detail start to be viewable by players"}
                                        textStyle={{ fontWeight: "bold" }} />
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={12}>
                                        <MobileDatePicker
                                            margin="normal"
                                            id="date-picker-dialog"
                                            label="Choose Date (MM/DD/YYYY)"
                                            inputFormat="MM/dd/yyyy"
                                            value={Quest.start_view}
                                            onChange={(date) => {
                                                setQuest(
                                                    prev => ({ ...prev, start_view: date }))
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Choose Time"
                                            value={Quest.start_view}
                                            onChange={(date) => {
                                                setQuest(prev => ({ ...prev, start_view: date }))
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }}  {...params} />}
                                        />
                                    </Grid>
                                </LocalizationProvider  >


                                <Grid item xs={12}>
                                    <TextWithTooltipIcon
                                        text={"Viewable Until"}
                                        tooltip={"Quest and its detail stop being viewable by players"}
                                        textStyle={{ fontWeight: "bold" }} />
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={12}>
                                        <MobileDatePicker
                                            margin="normal"
                                            label="Choose Date (MM/DD/YYYY)"
                                            inputFormat="MM/dd/yyyy"
                                            value={Quest.end_view}
                                            onChange={(date) => {
                                                setQuest(
                                                    prev => ({ ...prev, end_view: date }))
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <TimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Choose Time"
                                            value={Quest.end_view}
                                            onChange={(date) => {
                                                setQuest(prev => ({ ...prev, end_view: date }))
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change end time',
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }}  {...params} />}
                                        />

                                    </Grid>
                                </LocalizationProvider >

                                {DateErrorMsg &&
                                    <Grid item xs={12}>
                                        <Alert severity="error" color="secondary">
                                            {DateErrorMsg.toString()}
                                        </Alert>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        disabled={loading || DateErrorMsg}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Confirm
                                    </Button>
                                </Grid>

                                {id &&
                                    <Grid item xs={12} >
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => setIsConfirmingDeletion(true)}
                                        >
                                            <DeleteOutlineIcon color="secondary" />
                                            Delete QR Quest
                                        </Button>
                                    </Grid>

                                }

                            </Grid>

                        </Grid>
                    </Grid>
                </form>
            </Container>

        </>
    )
    return <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />
}
