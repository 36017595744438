import API from '@aws-amplify/api';
import {
    Backdrop,
    CircularProgress,
    Grid,
    Tab,
    Typography,
    Tabs,
    useMediaQuery,
    Box,
    Button,
    ImageListItem,
    Tooltip,
} from '@mui/material'; import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import App2 from './App2';
import CommonHeader from './CommonHeader';
import { TabPanel } from './PollEditView2';
import { theme } from './style';
import defaultImage from "./assets/polls_obj_default.png";
import gem_img from "./assets/_ui_gem.png"
import { escapeRegExp, timeAgo, TOOLTIP_PRESETS, updateVoucher } from './utils';
import TextWithTooltipIcon from './TextWithTooltipIcon';
import { EditRounded } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import DataGridToolBar from './DataGridToolbar';
import Storage from '@aws-amplify/storage';




export default function VoucherDetailView({ logout, openStatus, setStatus }) {
    let { id } = useParams();
    const history = useHistory();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [IsLoading, setIsLoading] = useState(false);
    const [Voucher, setVoucher] = useState({
        start_at: -1,
        end_at: -1,
        expiryDate: -1,
        orders: [],
        type: 0
    });
    const [tab, setTab] = useState(0)
    const [pageSize, setPageSize] = useState(10);

    // table search stuff
    const [searchText, setSearchText] = React.useState('');
    const [rows, setRows] = useState([]); //viewable rows after search
    const [allRows, setAllRows] = useState([]); //all rows parsed by voucher.orders and voucher fields

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = allRows.filter((row) => {
            return Object.keys(row).some((field) => {
                return row[field] && searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };

    React.useEffect(() => {
        let _rows = []
        if (Voucher.type === 0)//flash
        {
            Voucher.orders.forEach((order) => {
                let _row = { ...order }
                _row.type = "Flash Screen"
                _row.status = (_row.isRedeemed ? "Redeemed" : (Voucher.expiryDate < new Date() / 1000 ? "Expired" : "Available"))
                _row.redeemedOn = new Date(_row.redeemedOn * 1000).toLocaleString()
                if (_row.status === "Redeemed" &&
                    _row.redeemedOn && _row.redeemedOn !== "Invalid Date") {
                    _row.status += ` (on ${_row.redeemedOn})`
                }
                _row.oid = _row.id
                _rows.push(_row)
            })
        } else { //manual
            Voucher.orders.forEach((order) => {
                let _row = { ...order }
                _row.type = "Manual Delivery"
                _row.status = (_row.deliveryProgress)
                _row.oid = _row.id
                _row.redeemedOn = new Date(_row.redeemedOn * 1000).toLocaleString()
                if (_row.status === "Delivered" && _row.redeemedOn && _row.redeemedOn !== "Invalid Date") {
                    _row.status += ` (on ${_row.redeemedOn})`
                }
                _rows.push(_row)
            })
        }
        setAllRows(_rows);
        setRows(_rows);
    }, [Voucher.orders, Voucher.expiryDate, Voucher.type]);
    // end of table search stuff

    // start of button group
    const options = ['Pending', 'Processing', 'In Transit', 'Delivered'];

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);



    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    // end of button group

    //table select stuff
    const [selectionModel, setSelectionModel] = useState([]);
    //end of table select stuff
    useEffect(() => {
        API.get("JoyworldVoucher", `/voucher/object/${id}`)
            .then((res) => {
                console.log(res)
                let vc = res;
                if (res.img_s3) {
                    Storage.get(`${res.img_s3}`, { level: "public" })
                        .then((signedUrl) => {
                            vc.img_url = signedUrl
                            setVoucher(vc);
                        })
                } else {
                    setVoucher(vc);
                }

            })
            .catch((err) => {
                console.error(err)
                // history.push("/");   
            });
    }, [history, id]);

    const columns = [
        {
            field: 'oid',
            headerName: 'Order ID',
            headerClassName: "myTableHeader",
            width: 290,
        },
        {
            field: 'createdOn',
            headerName: 'Date',
            width: 200,
            headerClassName: "myTableHeader",
            valueFormatter: (param) => (new Date(param.value * 1000).toLocaleString())
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 250,
            headerClassName: "myTableHeader",
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 150,
            headerClassName: "myTableHeader",
        },
        {
            field: 'buyer',
            headerName: 'Buyer ID',
            headerClassName: "myTableHeader",
            width: 290,
            renderCell: (param) =>
                <Tooltip title={param.value} >
                    <Typography sx={{}}>{param.value}</Typography>
                </Tooltip>
        },
        {
            field: 'buyerEmail',
            headerName: 'Buyer Email',
            headerClassName: "myTableHeader",
            width: 200,
        },
    ];


    const OrderTab = () => (

        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            rowsPerPageOptions={[10, 20, 50, 100]}
            checkboxSelection
            disableSelectionOnClick
            components={{ Toolbar: DataGridToolBar }}
            componentsProps={{
                toolbar: {
                    hideButtons: (Voucher.type === 0),
                    searchValue: searchText,
                    onSearchChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(''),
                    open: open,
                    setOpen: setOpen,
                    anchorRef: anchorRef,
                    selectedIndex: selectedIndex,
                    setSelectedIndex: setSelectedIndex,
                    handleClick: () => {
                        console.info(`You clicked ${options[selectedIndex]}`);
                    },
                    handleToggle: handleToggle,
                    options: options,
                    optionDisabled: (targetStatus) => {
                        if (targetStatus.toLowerCase() === 'delivered')
                            return false;
                        let num_pending = 0, num_processing = 0, num_intransit = 0, num_delivered = 0;
                        Voucher.orders.filter(originalOrder => (selectionModel.includes(originalOrder.id))).forEach(
                            (selectedOrder) => {
                                if (selectedOrder.deliveryProgress.toLowerCase() === "pending") num_pending++;
                                if (selectedOrder.deliveryProgress.toLowerCase() === "processing") num_processing++;
                                if (selectedOrder.deliveryProgress.toLowerCase() === "in transit") num_intransit++;
                                if (selectedOrder.deliveryProgress.toLowerCase() === "delivered") num_delivered++;
                            }
                        )
                        if (targetStatus.toLowerCase() === 'pending' && (num_delivered > 0))
                            return true;
                        if (targetStatus.toLowerCase() === 'processing' && (num_intransit > 0 || num_delivered > 0))
                            return true;
                        else if (targetStatus.toLowerCase() === 'in transit' && (num_delivered > 0))
                            return true;
                    },
                    buttonDisabled: () => {
                        return false
                        // let num_delivered = 0;
                        // Voucher.orders.filter(originalOrder => (selectionModel.includes(originalOrder.id))).forEach(
                        //     (selectedOrder) => {
                        //         console.log(selectedOrder);
                        //         if (selectedOrder.deliveryProgress.toLowerCase() === "delivered") num_delivered++;
                        //     }
                        // )
                        // if (num_delivered > 0)
                        //     return true;
                    },
                    handleClose: (event) => {
                        if (anchorRef.current && anchorRef.current.contains(event.target)) {
                            return;
                        }
                        setOpen(false);
                    },
                    handleMenuItemClick: (option) => {
                        setIsLoading(true)
                        let _orders = [...Voucher.orders]
                        selectionModel.forEach(selectedOrderId => {
                            _orders.forEach(order => {
                                if (order.id === selectedOrderId) {
                                    order.deliveryProgress = option;
                                } 
                            })
                        })
                        let newVoucher = { ...Voucher, orders: _orders }
                        updateVoucher(newVoucher, null, (res) => {
                            console.log(res);
                            setIsLoading(false)
                            window.location.reload(false)
                            // setVoucher(newVoucher)
                        })

                    },
                    selectedRows: selectionModel,
                    fileName: `joyworld-orders-${Voucher.header}-${Voucher.vid}`,

                },
            }}
            sx={{
                "& .MuiDataGrid-columnSeparator": {
                    color: "text.disabled"
                },
                "&, & .MuiTypography-root": {
                    fontSize: "0.85rem"
                },
                '& .myTableHeader, & .MuiDataGrid-cell': {
                    outline: "none !important",
                },
                '& .MuiDataGrid-row': {
                    borderWidth: "2px 0 0 0",
                    borderStyle: "solid",
                    borderColor: "text.disabled",
                    boxSizing: "border-box",
                },
                '& .MuiDataGrid-row:last-child': {
                    borderWidth: "2px 0 ",
                    borderStyle: "solid",
                    borderColor: "text.disabled",
                    boxSizing: "border-box",
                },
                '& .MuiDataGrid-row:first-of-type': {
                    borderWidth: "2px 0 0 0 ",
                    borderStyle: "solid",
                    borderColor: "text.disabled",
                    boxSizing: "border-box",
                },
                "& .MuiDataGrid-virtualScroller": {
                    height: null,
                    overflowY: "hidden",
                },
            }}
            onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                console.log(newSelectionModel)
            }}
            selectionModel={selectionModel}

        />

    )

    const page = (
        <>
            <Backdrop open={IsLoading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
                alignItems: "flex-start"
            }}>

                <CommonHeader title={"View Voucher"} subtitle={"View voucher details and manage orders."} />
                <Button color="secondary" variant="contained" href={`${id}/edit`} sx={{
                    boxShadow: "none", float: "right", mb: 1,
                }}>
                    <EditRounded />
                    Edit
                </Button>
            </Box>

            <Box >
                <Tabs value={tab} onChange={(e, val) => { setTab(val) }} aria-label="tabs">
                    <Tab label="Details" />
                    <Tab label="Orders" />
                </Tabs>

            </Box>
            <TabPanel value={tab} index={0}>
                <Grid container direction="row" rowSpacing={2} columnSpacing={isMobile ? 2 : 12} sx={{ pl: isMobile ? 0 : -12, width: "100%" }}>
                    {/* first column */}
                    <Grid item md={4} xs={12}  >
                        <Grid container direction="column" rowSpacing={2}>
                            <Grid item >
                                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                    Voucher Title*
                                </Typography>
                                <Typography sx={{ color: "text.disabled", ml: 2 }}>
                                    {Voucher.header}
                                </Typography>
                            </Grid>

                            <Grid item sx={{ minHeight: "12rem" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                    Voucher Description*
                                </Typography>
                                <Typography sx={{ color: "text.disabled", ml: 2, }}>
                                    {Voucher.description}
                                </Typography>
                            </Grid>
                            <Grid item sx={{ minHeight: "12rem" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                    Terms and Conditions
                                </Typography>
                                <Typography sx={{ color: "text.disabled", ml: 2, maxHeight: "10rem", overflow: "auto" }}>
                                    {Voucher.TnC}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* end of first column */}
                    {/* second column */}
                    <Grid item md={4} xs={12} >
                        <Grid container direction="column" rowSpacing={2}>
                            <Grid item xs={12} sx={{ minHeight: "150px" }}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                    Voucher Image
                                </Typography>
                                <Box sx={{ ml: 2 }}>
                                    <ImageListItem>
                                        <img src={Voucher.img_url || defaultImage} alt="voucher" style={{ width: "100%" }} />
                                    </ImageListItem>

                                </Box>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row">
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                            Quantity*
                                        </Typography>
                                        <Typography sx={{ color: "text.disabled", ml: 2 }}>
                                            {Voucher.quantity + (Voucher.orders ? Voucher.orders.length : 0)} ({Voucher.quantity} left)
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                            Quantity Left*
                                        </Typography>
                                        <Typography sx={{ color: "text.disabled", ml: 2 }}>
                                            {Voucher.quantity}
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                    Price*
                                </Typography>

                                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", ml: 2 }}>
                                    <img style={{ height: "1.2rem", }} src={gem_img} alt="gem" />
                                    <Typography sx={{ color: "text.disabled" }}>
                                        {Voucher.price_gem}
                                    </Typography>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>
                    {/* end of first column */}
                    {/* 3rd column */}
                    <Grid item md={4} xs={12} >
                        <Grid container direction="column" rowSpacing={2}>
                            <Grid item xs={12}>
                                <TextWithTooltipIcon
                                    text={"Start Date"}
                                    tooltip={TOOLTIP_PRESETS.voucher_start}
                                    textStyle={{ fontWeight: "bold", fontSize: "1.2rem" }}
                                />
                                <Typography sx={{ color: "text.disabled", ml: 2, }}>
                                    {new Date(Voucher.start_at * 1000).toLocaleString()}  ({timeAgo.format(Voucher.start_at * 1000)})
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextWithTooltipIcon
                                    text={"End Date"}
                                    tooltip={TOOLTIP_PRESETS.voucher_end}
                                    textStyle={{ fontWeight: "bold", fontSize: "1.2rem" }}
                                />
                                <Typography sx={{ color: "text.disabled", ml: 2, }}>
                                    {new Date(Voucher.end_at * 1000).toLocaleString()} ({timeAgo.format(Voucher.end_at * 1000)})
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextWithTooltipIcon
                                    text={"Expiration Date"}
                                    tooltip={TOOLTIP_PRESETS.voucher_expire}
                                    textStyle={{ fontWeight: "bold", fontSize: "1.2rem" }}
                                />
                                <Typography sx={{ color: "text.disabled", ml: 2, }}>
                                    {new Date(Voucher.expiryDate * 1000).toLocaleString()} ({timeAgo.format(Voucher.expiryDate * 1000)})
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                    Orders
                                </Typography>
                                <Typography sx={{ color: "text.disabled", ml: 2 }}>
                                    {Voucher.orders ? Voucher.orders.length : 0}
                                </Typography>

                            </Grid>

                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                                    Redemption Type
                                </Typography>
                                <Typography sx={{ color: "text.disabled", ml: 2 }}>
                                    {Voucher.type === 0 ? "Flash Screen" : "Manual Delivery"}
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>
                    {/* end of 3rd column */}
                    {/* bottom email row */}
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                            Admin Email
                        </Typography>
                        <Typography sx={{ color: "text.disabled", ml: 2 }}>
                            {Voucher.adminEmail}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                            Vendor Email 1*
                        </Typography>
                        <Typography sx={{ color: "text.disabled", ml: 2 }}>
                            {Voucher.vendorEmail1}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                            Vendor Email 2
                        </Typography>
                        <Typography sx={{ color: "text.disabled", ml: 2 }}>
                            {Voucher.vendorEmail2}
                        </Typography>
                    </Grid>

                    {/* end of bottom email row */}
                </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                {OrderTab()}
            </TabPanel>
        </>
    )
    return <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />

}
