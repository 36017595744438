import API from '@aws-amplify/api';
import { Backdrop, Button, Box, CircularProgress, Container, Grid, TextField, Typography, Autocomplete, FormControlLabel, Switch, InputLabel, CardMedia } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { IsProdEnv } from './utils';
import { useHistory } from "react-router";
import App2 from "./App2"
import CommonHeader from './CommonHeader';
import MyModal, { modalPreset } from './MyModal';
import RewardAttachment from './RewardAttachment';

//#region limit how many player id options can be displayed max
const OPTIONS_LIMIT = 5;
// const defaultFilterOptions = createFilterOptions();

const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT
})
//#endregion

export default function NewMail({ logout, openStatus, setStatus }) {
    const INIT_MAIL = {
        subject: "Mail From Admin",
        body: "Dear Player,\n\n\n\nRegards,\nAdmin",
        recipient: "", //list of string
    }
    const [Mail, SetMail] = useState(INIT_MAIL)
    const allDevStages = ["beta", "prebeta"]
    const [Stage, setStage] = useState(allDevStages[0])
    const [AllStagePlayers, SetAllStagePlayers] = useState([])
    const [recipients, setRecipients] = useState([])
    const [rewards, setRewards] = useState([]);
    const [isConfirming, setIsConfirming] = useState(false)
    const [sendToAllInStage, setSendToAllInStage] = useState(false)
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const handlePollChange = (e) => {
        SetMail((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    //onload
    useEffect(() => {
        if (IsProdEnv()) {
            setStage("prod")
        }
    }, [])

    useEffect(() => {
        console.log(AllStagePlayers)

    }, [AllStagePlayers])

    //update player list to the stage's when stage is updated
    useEffect(() => {
        setRecipients([])
        if (Stage.trim() !== "") {
            API.get("PollingApi2", "/all-players", {
                queryStringParameters: {  // OPTIONAL
                    stage: Stage,
                }
            }).then(res => {
                SetAllStagePlayers(res.map(item=>item.username))
            }).catch(err => console.log(err))
            //fetch all players in this stage
        }
    }, [Stage])


    const submitMail = () => {
        setLoading(true);
        let _recip
        if (sendToAllInStage) {
            _recip = AllStagePlayers
        } else {
            _recip = recipients
        }
        console.log(`sending to ${_recip.length} players`)
        if (_recip.length === 0) {
            setLoading(false);
            return
        }
        Mail.inventoryAttachment = []
        Mail.currencyAttachment = []
        rewards.forEach(re => {
            let entry = {
                inventoryKey: re.content.m_Key,
                quantity: re.quantity
            }
            console.log(re.type)
            if (re.content.type === "Resource" || re.content.type === "PRIDE") {
                entry.currencyKey = entry.inventoryKey
                entry.inventoryKey = null
                Mail.currencyAttachment.push(entry)
            } else {
                Mail.inventoryAttachment.push(entry)
            }
        })
        console.log(Mail)
        let ops = _recip.map(recipient => (
            API.post("PollingApi2", "/new-mail", {
                body: {
                    ...Mail,
                    recipient: recipient,
                    stage: Stage,
                }
            })
        ))
        Promise.allSettled(ops).then(resArr => {
            console.log(resArr)
            setLoading(false)
            //redirect to home page
            history.push("/")
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }
    const page = (
        <>
            <Backdrop open={loading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            {
                (isConfirming)
                // (true)
                &&
                <MyModal title={modalPreset.ConfirmSendMail(0).title}
                    subtitle={modalPreset.ConfirmSendMail(sendToAllInStage ? AllStagePlayers.length : recipients.length).subtitle}
                    mainButtonText="Yes, send it"
                    subButtonText="Cancel"
                    cb={submitMail}
                    subCb={() => setIsConfirming(false)}
                />
            }
            <CommonHeader title="Send a New Mail" subtitle="Send text, gems or resources to players here." />

            <Container maxWidth="md">
                <form
                    onSubmit={(e) => { e.preventDefault(); setIsConfirming(true) }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Typography sx={{ fontWeight: "bold" }}>
                                Mail Subject*
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField name="subject"
                                onChange={handlePollChange}
                                variant="outlined"
                                required
                                fullWidth
                                id="subject"
                                value={Mail.subject}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography sx={{ fontWeight: "bold" }}>
                                Mail Body*
                            </Typography>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField name="body"
                                variant="outlined"
                                multiline
                                rows={7}
                                required
                                fullWidth
                                id="body"
                                onChange={handlePollChange}
                                value={Mail.body}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography sx={{ fontWeight: "bold" }}>
                                Recipients*
                            </Typography>
                        </Grid>
                        {!IsProdEnv() &&
                            <Grid item xs={3}   >
                                <Autocomplete
                                    options={allDevStages}
                                    selectOnFocus
                                    clearOnBlur
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} label="Stage" />}
                                    value={Stage}
                                    onChange={(event, newValue) => {
                                        if (newValue == null)
                                            return
                                        setStage(newValue)
                                    }}
                                />
                            </Grid>}
                        <Grid item xs={IsProdEnv() ? 12 : 9}   >
                            {/* <Autocomplete
                                autoComplete={true}
                                options={AllStagePlayers}
                                selectOnFocus
                                clearOnBlur
                                fullWidth
                                renderInput={(params) =>
                                     <TextField {...params} label="Recipient" />
                                    }
                                value={Recipient}
                                filterOptions={filterOptions}
                                onChange={(event, newValue) => {
                                    if (newValue == null)
                                        return
                                    setRecipient(newValue)
                                }}
                            /> */}
                            {!sendToAllInStage && <Autocomplete
                                multiple
                                autoComplete={true}
                                clearOnBlur
                                options={AllStagePlayers}
                                filterOptions={filterOptions}
                                getOptionLabel={(option) => option}
                                value={recipients}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Player ID"
                                    />
                                )}
                                onChange={(event, newValue) => {
                                    setRecipients(newValue)
                                }}
                            />}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                sx={{
                                    ml: 0,
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                                control={
                                    <Switch
                                        name="send_to_all"
                                        checked={sendToAllInStage}
                                        color="secondary"
                                        onChange={(e) => {
                                            setSendToAllInStage(e.target.checked);
                                        }}
                                    />
                                }
                                label={
                                    <Typography sx={{
                                        fontSize: "0.75rem",
                                    }}>
                                        Send to all players
                                    </Typography>
                                }
                                labelPlacement="start"
                            />
                        </Grid>


                        <RewardAttachment setRewards={setRewards} rewards={rewards} title={"Attached Rewards"} />

                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={!sendToAllInStage && recipients.length <= 0}
                    >
                        Confirm
                    </Button>
                </form>
            </Container>

        </>

    )
    return <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />
}
