import {
    Grid,
    Typography
} from "@mui/material";
import React from 'react'

export default function CommonHeader({ title, subtitle }) {
    return (
        <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                {title}
            </Typography>
            <Typography sx={{ fontSize: "0.875rem" }}>
                {subtitle}
            </Typography>
        </Grid >
    )
}
