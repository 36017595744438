import {
  Autocomplete,
  Grid,
  TextField,
  InputLabel,
  CardMedia,
  Typography,
  Box,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { fetchGFAsset, getFilteredRewardOptions, importAll } from "./utils";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import AddBoxOutlined from "@mui/icons-material/AddBoxOutlined";
import TextWithTooltipIcon from "./TextWithTooltipIcon";
import { InfoOutlined } from "@mui/icons-material";
import prideExpTable from "./assets/pride_exp_table.png";

export default function RewardAttachment({ rewards, setRewards, title }) {
  const images = importAll(
    require.context("./assets/gf_image", false, /\.(png|jpe?g|svg)$/)
  );
  const [allRewardOptions, setAllRewardOptions] = useState([]);
  const _addReward = () => {
    setRewards((prevState) => [
      ...prevState,
      {
        content: getFilteredRewardOptions(allRewardOptions, rewards)[0],
        quantity: 1,
      },
    ]);
  };
  useEffect(() => {
    fetchGFAsset(setAllRewardOptions);
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid container alignItems="flex-end">
          <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
        </Grid>
        <Typography style={{ color: "grey", fontSize: "0.8rem" }}>
          Maximum of distributable gem, stamina, cosmetics and everything else
          are 300, 50, 1 and 5,000 respectively.
        </Typography>
        <Typography style={{ color: "grey", fontSize: "0.8rem" }}>
          The recommended amount of PRIDE experience from all 5 categories per
          poll is 10-30, and 80-300 per month. QR quests and mails are
          recommended to award up to 50 experience.
          <Tooltip
            title={
              <>
                <img src={prideExpTable} alt="prideExpTable" width="100%" />
              </>
            }
            placement="bottom"
            PopperProps={{
              sx: {
                " .MuiTooltip-tooltip": {
                  maxWidth: "unset",
                  width: isMobile ? "90%" : "500px",
                  margin: 0,
                },
              },
            }}
          >
            <InfoOutlined
              sx={{ color: "warning.main", mb: -0.5 }}
              fontSize={"small"}
            />
          </Tooltip>
        </Typography>
      </Grid>

      <Box sx={{ width: "100%", p: "1rem" }}>
        {rewards.map((reward, idx) => (
          <Grid
            container
            sx={{ mb: 2, alignItems: "stretch", justifyContent: "stretch" }}
            spacing={1}
            key={idx}
          >
            <Grid item sm={6} xs={12}>
              <Autocomplete
                options={getFilteredRewardOptions(allRewardOptions, rewards)}
                selectOnFocus
                clearOnBlur
                fullWidth
                isOptionEqualToValue={(op, va) => op.m_Key === va.m_Key}
                groupBy={(option) => option.type}
                getOptionLabel={(option) => option.m_DisplayName}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label="Reward" />
                )}
                value={reward.content}
                onChange={(event, newValue) => {
                  if (newValue == null) return;
                  setRewards((prevState) => {
                    let copy = [...prevState];
                    copy[idx].content = newValue;
                    copy[idx].quantity = 1;
                    console.log(newValue.type);
                    return copy;
                  });
                }}
              />
            </Grid>
            <Grid item sm={2} xs={3}>
              {
                reward.content &&
                  reward.content.m_Key &&
                  images[reward.content.m_Key] && (
                    <>
                      <InputLabel
                        sx={{
                          transform: "translate(14px, -6px) scale(0.75)",
                          position: "absolute",
                        }}
                      >
                        Preview
                      </InputLabel>

                      <CardMedia
                        image={images[reward.content.m_Key].default}
                        alt={reward.content.m_Key}
                        sx={{
                          height: "100%",
                          backgroundPosition: "50% 100%",
                          backgroundSize: "auto 90%",
                        }}
                      />
                    </>
                  )
                // <img src={images[reward.content.m_Key].default}
                //     alt={reward.content.m_Key}
                //     style={{ maxWidth: `75%`, objectFit:"contain" }} />
              }
            </Grid>
            <Grid item sm={3} xs={8}>
              <TextField
                label="Quantity"
                type="number"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={reward.quantity}
                variant="outlined"
                onChange={(e) => {
                  setRewards((prevState) => {
                    let copy = [...prevState];
                    let cap = 1; //clothes and hair
                    if (copy[idx].content.type === "Resource") {
                      cap = 5000; //WSI
                    }
                    if (copy[idx].content.m_Key === "gem") {
                      cap = 300; //gem
                    }
                    if (copy[idx].content.m_Key === "stamina") {
                      cap = 50; //stamina
                    }
                    if (copy[idx].content.m_Key.includes("exp_")) {
                      cap = Infinity; //PRIDE
                    }
                    copy[idx].quantity = Math.trunc(
                      Math.max(1, Math.min(Math.floor(e.target.value), cap))
                    );
                    return copy;
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IndeterminateCheckBoxOutlinedIcon
                sx={{ color: "secondary.main" }}
                onClick={() => {
                  setRewards((prevState) => {
                    let copy = [...prevState];
                    copy.splice(idx, 1);
                    return copy;
                  });
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Box>

      <Grid item xs={12} sx={{ display: "flex", mb: 2 }}>
        <AddBoxOutlined color="secondary" onClick={_addReward} />
        <Typography onClick={_addReward}>
          Add {rewards.length > 0 ? "another" : "a"} reward
        </Typography>
      </Grid>
    </>
  );
}
