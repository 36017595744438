import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';


import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router';
import config from './aws-exports';
import Amplify, { API } from "aws-amplify";


Amplify.configure(config);


const drawerWidth = 240

export default function App2({ logout, page, openStatus, setStatus, containerProps }) {
    const [mobileOpen, setMobileOpen] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
//   const history = useHistory();

//     useEffect(() => {

//         Auth.currentAuthenticatedUser()
//             .then((user) => {
//                 if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
//                     history.push("/change-pw")
//                 }

//             })
//             .catch((err) => {
//                 console.log(err);
//             });

//     })

    return (
        <Box sx={{ display: 'flex' }}>
            {!mobileOpen &&
                <Fab color="primary" aria-label="menu" onClick={handleDrawerToggle}
                    sx={{
                        left: 15,
                        bottom: 15,
                        position: "fixed",
                        display: { xs: 'block', sm: 'none' },
                        zIndex: 10000
                    }}
                >
                    <MenuIcon />
                </Fab>
            }
            {/* <CssBaseline /> */}
            {/* <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        Responsive drawer
                    </Typography>
                </Toolbar>
            </AppBar> */}
            <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} logout={logout} openStatus={openStatus} setStatus ={setStatus}  />
            <Container {...containerProps} sx={{ p: 2, background: `url(${process.env.PUBLIC_URL}/sparks.png) no-repeat right top` }}>
                {page}
            </Container>
        </Box>
    )
}
