import { Typography, Link } from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import API from "@aws-amplify/api";
import Storage from "@aws-amplify/storage";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { truncate } from "lodash";
import { Signer } from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import * as urlLib from "url";
import config from './aws-exports';

export const MAX_NORMAL_POLL = 5;
export const MAX_DISRUPTION_POLL = 3;
export const MAX_QR_QUEST = 5;
export const MAX_DATA_BATCH = 500;

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Lionfish Studios
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace(".png", "").replace("./", "")] = r(item);
  });
  return images;
};

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const get_fullword_substring = (str, idx) => {
  if (idx > str.length) {
    return str;
  } else {
    while (str[idx] !== " ") {
      idx--;
    }
    return `${str.substring(0, idx)}...`;
  }
};

/**
 * Given two dates (or one date and assume "now" for the second), convert this to
 * a human-readable string, like "2 months".
 *
 * I use this to put "3 months ago" strings into plugins. My use case has the date
 * coming in as a seconds-only UNIX epoch, so the params are expected at this.
 *
 * @param time1 integer Number of seconds since UNIX epoch
 * @param time2 integer Number of seconds since UNIX epoch
 * @return string
 */
export const getTimespan = (time1, time2) => {
  // Check/sanitise vars
  time1 = Math.max(0, parseInt(time1));

  if (typeof time2 == "undefined") {
    var now = new Date();
    time2 = Math.floor(now.getTime() / 1000);
  }
  var period = Math.abs(time1 - time2);

  var timespan = 1;
  var format = "seconds";
  if (period > 31556926) {
    // More than one year
    format = "years";
    timespan = Math.floor(period / 31556926);
  } else if (period > 2629744) {
    // More than one month
    format = "months";
    timespan = Math.floor(period / 2629744);
  } else if (period > 604800) {
    // More than one week
    format = "weeks";
    timespan = Math.floor(period / 604800);
  } else if (period > 86400) {
    // More than one day
    format = "days";
    timespan = Math.floor(period / 86400);
  } else if (period > 3600) {
    // More than one hour
    format = "hours";
    timespan = Math.floor(period / 3600);
  } else if (period > 60) {
    // More than one minute
    format = "minutes";
    timespan = Math.floor(period / 60);
  }

  // Remove the s
  if (timespan === 1) {
    format = format.slice(0, format.length - 1);
  }

  return timespan + " " + format;
};

export const getFilteredRewardOptions = (allRewardOptions, rewards) => {
  if (rewards.length === 0) {
    return allRewardOptions;
  }
  let _filteredRewardOptions = [];
  allRewardOptions.forEach((op) => {
    let matched = false;
    rewards.forEach((reward) => {
      if (reward.content.m_Key.trim() === op.m_Key.trim()) {
        matched = true;
      }
    });
    if (!matched) {
      _filteredRewardOptions.push(op);
    }
  });
  return _filteredRewardOptions;
};

export const IsProdEnv = () => {
  const url = window.location.href;
  return (
    (url.indexOf("jw-admin.lionfish-studios.com") > -1) ||
    (url.indexOf("amplifyapp.com") > -1 && url.indexOf("release") > -1) ||
    (config.aws_dynamodb_table_schemas[0].tableName.indexOf("-prod") > -1)
  );
};

export const printQRCode = (questId) => {
  console.log("printQRCode");
  const canvas = document.getElementById("react-qrcode-logo");
  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");

  //print func
  var win = window.open("about:blank", "_new");
  win.document.open();
  win.document.write(
    [
      "<html>",
      "   <head>",
      "   </head>",
      '   <body onload="window.print()" onafterprint="window.close()">',
      '       <img src="' + pngUrl + '"/>',
      "   </body>",
      "</html>",
    ].join("")
  );
  win.document.close();

  // let downloadLink = document.createElement("a");
  // downloadLink.href = pngUrl;
  // downloadLink.download = `QR_${questId}.png`;
  // document.body.appendChild(downloadLink);
  // downloadLink.click();
  // document.body.removeChild(downloadLink);
};

// export const DATE_STR_OPTION = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',  };

export const TOOLTIP_PRESETS = {
  voucher_start:
    "This is the time when the voucher start to be viewable and purchasable from Joyworld game.",
  voucher_end:
    "This is the time when the voucher stops being viewable or purchasable from Joyworld game. Already purchased vouchers will still be viewable by players.",
  voucher_expire:
    "This is the time when the purchased voucher stops being redeemable in Joyworld game.",
  required: "Please fill up all required fields",
};

export function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export const getVouchers = (setLoading, setAllVouchers) => {
  setLoading(true);
  API.get("JoyworldVoucher", "/voucher/id", {})
    .then((vouchers) => {
      // setVouchers(res);
      let asyncOps = [];
      vouchers.forEach((vc, vcIdx) => {
        if (vc.img_s3) {
          asyncOps.push(Storage.get(`${vc.img_s3}`, { level: "public" }));
        } else {
          asyncOps.push(Promise.reject(new Error("fail")));
        }
      });
      Promise.allSettled(asyncOps).then((results) => {
        results.forEach((result, index) => {
          if (result.status === "fulfilled") {
            vouchers[index].img_url = result.value;
          } else {
            vouchers[index].img_url = "";
          }
        });
        console.log(vouchers);
        setLoading(false);
        setAllVouchers(vouchers);
      });
    })
    .catch((err) => {
      setLoading(false);
      console.error(err);
    });
};

//used in mass voucher listing views
export const updateVoucher = (newVoucher, setAllVouchers, successCallback) => {
  delete newVoucher.order;
  console.log(newVoucher);

  let newVoucherToUpdate = { ...newVoucher };
  delete newVoucherToUpdate["img_url"]; //does nto throw error even if the attribute doesn't exist

  API.put("JoyworldVoucher", "/voucher", {
    body: {
      ...newVoucherToUpdate,
    },
  })
    .then((res) => {
      if (setAllVouchers) {
        setAllVouchers((prevState) => {
          const newState = [
            ...prevState.filter((vc) => vc.vid !== newVoucher.vid),
            newVoucher,
          ];
          console.log(newState);
          return newState;
        });
      }
      successCallback(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteVoucher = (vcToDelete, setAllVouchers, successCallback) => {
  API.del("JoyworldVoucher", `/voucher/object/${vcToDelete.vid}`)
    .then((res) => {
      setAllVouchers((prevState) => {
        const newState = [
          ...prevState.filter((vc) => vc.vid !== vcToDelete.vid),
        ];
        console.log(newState);
        return newState;
      });
      //#region  delete the s3 picture too
      if (vcToDelete.img_s3) {
        Storage.remove(`${vcToDelete.img_s3}`, { level: "public" })
          .then((res) => {
            console.log(res);
            if (successCallback) {
              successCallback();
            }
          })
          .catch((err) => {
            console.logError(err);
          });
      } else {
        if (successCallback) {
          successCallback();
        }
      }
      //#endregion
    })
    .catch((err) => {
      console.log(err);
    });
};

///timeago region
TimeAgo.addDefaultLocale(en);
export const timeAgo = new TimeAgo("en-US");
//end timeago region

export const ValidateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const updatePoll = (newPoll, setAllPolls, successCallback) => {
  console.log(newPoll);
  let newPollToUpdate = { ...newPoll };
  delete newPollToUpdate["img_url"]; //does nto throw error even if the attribute doesn't exist
  delete newPollToUpdate["base64Img"]; //does nto throw error even if the attribute doesn't exist
  delete newPollToUpdate["totalVotes"];
  API.put(
    "PollingApi2",
    newPoll.is_disruption ? "/disruption-polls" : "/polls",
    {
      body: {
        ...newPollToUpdate,
      },
    }
  )
    .then((res) => {
      if (setAllPolls) {
        setAllPolls((prevState) => {
          const newState = [
            ...prevState.filter((poll) => poll.id !== newPoll.id),
            newPoll,
          ];
          console.log(newState);
          return newState;
        });
      }
      if (successCallback) {
        successCallback();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateQuest = (newQuest, setAllObjs, successCallback) => {
  console.log(newQuest);
  let newQuestToUpdate = { ...newQuest };
  delete newQuestToUpdate["img_url"]; //does nto throw error even if the attribute doesn't exist

  API.put("JoyworldQRApi", "/qr-quest", {
    body: {
      ...newQuestToUpdate,
    },
  })
    .then((res) => {
      if (setAllObjs) {
        setAllObjs((prevState) => {
          const newState = [
            ...prevState.filter((qu) => qu.id !== newQuest.id),
            newQuest,
          ];
          console.log(newState);
          return newState;
        });
      }
      if (successCallback) {
        successCallback(res);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteQuest = (objToDelete, setAllObjs, successCallback) => {
  API.del("JoyworldQRApi", `/qr-quest/object/${objToDelete.id}`)
    .then((res) => {
      if (setAllObjs) {
        setAllObjs((prevState) => {
          const newState = [
            ...prevState.filter((obj) => obj.id !== objToDelete.id),
          ];
          console.log(newState);
          return newState;
        });
      }
      //#region  delete the s3 picture too
      if (objToDelete.img_s3) {
        Storage.remove(`${objToDelete.img_s3}`, { level: "public" })
          .then((res) => {
            console.log(res);
            if (successCallback) {
              successCallback();
            }
          })
          .catch((err) => {
            console.logError(err);
          });
      } else {
        if (successCallback) {
          successCallback();
        }
      }
      //#endregion
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deletePoll = (objToDelete, setAllObjs, successCallback) => {
  API.del(
    "PollingApi2",
    objToDelete.is_disruption
      ? `/disruption-polls/object/${objToDelete.id}`
      : `/polls/object/${objToDelete.id}`
  )
    .then((res) => {
      if (setAllObjs) {
        setAllObjs((prevState) => {
          const newState = [
            ...prevState.filter((obj) => obj.id !== objToDelete.id),
          ];
          console.log(newState);
          return newState;
        });
      }

      //#region  delete the s3 picture too
      if (objToDelete.img_s3) {
        Storage.remove(`${objToDelete.img_s3}`, { level: "public" })
          .then((res) => {
            console.log(res);
            if (successCallback) {
              successCallback();
            }
          })
          .catch((err) => {
            console.logError(err);
          });
      } else {
        if (successCallback) {
          successCallback();
        }
      }
      //#endregion
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const fetchGFAsset = (successCallback) => {
  fetch(`${process.env.PUBLIC_URL}/data/GameFoundationCatalog.asset`)
    .then((r) => r.text())
    .then((text) => {
      console.log(text);
      let lines = text.split("\n");
      let gfItems = [];
      lines.forEach((li) => {
        li = li.trim();
        if (li.startsWith("MonoBehaviour")) {
          gfItems.push({
            m_DisplayName: "",
            m_Key: "",
            m_Name: "",
          });
        } else if (li.startsWith("m_DisplayName")) {
          gfItems[gfItems.length - 1].m_DisplayName = li
            .split(":")
          [li.split(":").length - 1].trim();
        } else if (li.startsWith("m_Key")) {
          gfItems[gfItems.length - 1].m_Key = li
            .split(":")
          [li.split(":").length - 1].trim();
        } else if (li.startsWith("m_Name")) {
          //the last added item should be discarded
          gfItems[gfItems.length - 1].m_Name = li
            .split(":")
          [li.split(":").length - 1].trim();
        }
      });

      let _allResources = [];
      let _allClothes = [];
      let _allHair = [];
      let _allBp = [];
      let _allOthers = [];
      let _allPRIDE = [];
      gfItems.forEach((item) => {
        if (
          item.m_Name.startsWith("Item_") ||
          item.m_Name.startsWith("Currency_")
        ) {
          let type;
          if (item.m_Name.startsWith("Currency")) {
            if (item.m_Name.toLowerCase().includes("_exp_")) {
              type = "PRIDE";
              _allPRIDE.push({
                m_DisplayName: item.m_DisplayName,
                m_Key: item.m_Key,
                type: type,
              });
            } else if (item.m_Name.toLowerCase().includes("_level_")) {
              //skip
            } else {
              type = "Resource";
              _allResources.push({
                m_DisplayName: item.m_DisplayName,
                m_Key: item.m_Key,
                type: type,
              });
            }
          } else if (
            item.m_DisplayName.split("_")[1] === "head" &&
            item.m_DisplayName.includes("prem")
          ) {
            type = "Hair";
            _allHair.push({
              m_DisplayName: item.m_DisplayName,
              m_Key: item.m_Key,
              type: type,
            });
          } else if (
            item.m_DisplayName.split("_")[1] === "body" &&
            item.m_DisplayName.includes("prem")
          ) {
            type = "Clothes";
            _allClothes.push({
              m_DisplayName: item.m_DisplayName,
              m_Key: item.m_Key,
              type: type,
            });
          } else if (item.m_DisplayName.toLowerCase().includes("blueprint")) {
            type = "Blueprints";
            _allBp.push({
              m_DisplayName: item.m_DisplayName,
              m_Key: item.m_Key,
              type: type,
            });
          } else if (item.m_DisplayName.toLowerCase().includes("sticker")) {
            //ignore stickers
          } else {
            type = "Others";
            _allOthers.push({
              m_DisplayName: item.m_DisplayName,
              m_Key: item.m_Key,
              type: type,
            });
          }
        }
      });

      let _allRewardOptions = [
        ..._allResources,
        ..._allHair,
        ..._allClothes,
        ..._allBp,
        ..._allPRIDE,
        ..._allOthers,
      ];
      successCallback(_allRewardOptions);
    });
};
