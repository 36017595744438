import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router';
import API from '@aws-amplify/api';
import Storage from '@aws-amplify/storage';
import App2 from './App2';
import { Backdrop, Badge, Button, CardMedia, CircularProgress, Grid, ToggleButtonGroup, Typography } from '@mui/material';
import MyModal, { modalPreset } from './MyModal';
import CommonHeader from './CommonHeader';
import AddIcon from '@mui/icons-material/Add';
import PollCard from './PollCard';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import QrCodeRoundedIcon from '@mui/icons-material/QrCode';
import { QRCode } from 'react-qrcode-logo';
import { DeleteOutline, GridGoldenratioOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';
import fireIcon from './assets/admin_obj_fire.png';
import tickIcon from './assets/admin_obj_tick.png';
import upcomingIcon from './assets/admin_obj_upcoming.png';
import { deleteQuest, printQRCode, timeAgo, updateQuest } from './utils';
import EmptyList from './EmptyList';

export default function QRListView({ logout, openStatus, setStatus }) {

    const [allQuests, setAllQuests] = useState([])
    const [filter, setFilter] = useState("all")

    const [cardQuickActionQuest, setCardQuickActionQuest] = useState();
    const [isConfirmingStart, setIsConfirmingStart] = useState(false);
    const [isConfirmingEnd, setIsConfirmingEnd] = useState(false);
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showingQR, setShowingQR] = useState(false);
    const [uQuests, setUQuests] = useState([]); //Upcoming Quests
    const [oQuests, setOQuests] = useState([]); //active quests
    const [cQuests, setCQuests] = useState([]); //completed quests

    const history = useHistory()
    const location = useLocation();

    useEffect(() => {
        //if needs to redirect to signin page
        getQuests();
    }, []);

    useEffect(() => {
        //if needs to redirect to signin page
        let _filter = new URLSearchParams(location.search).get("filter")
        setFilter(_filter)
    }, [location]);


    useEffect(() => {
        console.log(filter)
        let _uVcs = allQuests.filter((quest) => quest["start_at"] * 1000 > new Date())
        let _aVcs = allQuests.filter((quest) => quest["start_at"] * 1000 < new Date() && new Date() < quest["end_at"] * 1000)
        let _fVcs = allQuests.filter((quest) => quest["end_at"] * 1000 < new Date())
        setUQuests(_uVcs)
        setOQuests(_aVcs)
        setCQuests(_fVcs)
        console.log(allQuests)
    }, [filter, allQuests])

    const getQuests = () => {
        setLoading(true)
        API.get("JoyworldQRApi", "/qr-quest/id", {})
            .then((quests) => {
                // setQuests(res);
                let asyncOps = []
                quests.forEach((vc, vcIdx) => {
                    if (vc.img_s3) {
                        asyncOps.push(
                            Storage.get(`${vc.img_s3}`, { level: "public" })
                        )
                    } else {
                        asyncOps.push(
                            Promise.reject(new Error('fail'))
                        )
                    }
                })
                Promise.allSettled(asyncOps).then(results => {
                    results.forEach((result, index) => {
                        console.log(result)
                        if (result.status === "fulfilled") {
                            quests[index].img_url = result.value
                        } else {
                            quests[index].img_url = ""
                        }
                    })
                    console.log(quests)
                    setLoading(false)
                    setAllQuests(quests)
                })
            })
            .catch((err) => {
                setLoading(false)
                console.error(err);
            });

    }



    const upcomingQRCard = (quest) =>
        <Grid item key={quest.id} md={3} sm={6} xs={12} >
            <PollCard
                title={quest.header}
                subtitle={`Starts ${timeAgo.format(quest.start_at * 1000)}
                Ends ${timeAgo.format(quest.end_at * 1000)}
                Viewable by players ${timeAgo.format(quest.start_view * 1000)}
                No longer viewable ${timeAgo.format(quest.end_view * 1000)}
                Created ${timeAgo.format(quest.createdOn * 1000)}`}
                imageStr={quest.img_url}
                href1={`/qr/${quest.id}/edit`}
                btnText1=""
                icon1={<CreateOutlinedIcon />}
                btnText2={"Start Now"}
                cb2={() => {
                    //start now
                    setCardQuickActionQuest(quest)
                    setIsConfirmingStart(true)
                }}
                icon3={<QrCodeRoundedIcon />}
                cb3={() => {
                    setShowingQR(true)
                    setCardQuickActionQuest(quest)
                }}
            />
        </Grid>


    const ongoingQRCard = (quest) =>
        <Grid item key={quest.id} md={3} sm={6} xs={12} >
            <PollCard
                title={quest.header}
                subtitle={`Starts ${timeAgo.format(quest.start_at * 1000)}
                Ends ${timeAgo.format(quest.end_at * 1000)}
                Viewable by players ${timeAgo.format(quest.start_view * 1000)}
                No longer viewable ${timeAgo.format(quest.end_view * 1000)}
                Created ${timeAgo.format(quest.createdOn * 1000)}`}
                imageStr={quest.img_url}
                href1={`/qr/${quest.id}/edit`}
                btnText1=""
                icon1={<CreateOutlinedIcon />}
                btnText2={"End Now"}
                cb2={() => {
                    //end now
                    setCardQuickActionQuest(quest)
                    setIsConfirmingEnd(true)
                }}
                icon3={<QrCodeRoundedIcon />}
                cb3={() => {
                    setShowingQR(true)
                    setCardQuickActionQuest(quest)
                }}
            />
        </Grid>



    const completedQRCard = (quest) =>
        <Grid item key={quest.id} md={3} sm={6} xs={12} >
            <PollCard
                title={quest.header}
                subtitle={`Starts ${timeAgo.format(quest.start_at * 1000)}
                Ends ${timeAgo.format(quest.end_at * 1000)}
                Viewable by players ${timeAgo.format(quest.start_view * 1000)}
                No longer viewable ${timeAgo.format(quest.end_view * 1000)}
                Created ${timeAgo.format(quest.createdOn * 1000)}`}
                imageStr={quest.img_url}
                href1={`/qr/${quest.id}/edit`}
                btnText1=""
                icon1={<CreateOutlinedIcon />}
                btnText2={" "}
                icon2={<DeleteOutline />}
                cb2={() => {
                    //delete
                    setCardQuickActionQuest(quest)
                    setIsConfirmingDelete(true)
                }}
                icon3={<QrCodeRoundedIcon />}
                cb3={() => {
                    setShowingQR(true)
                    setCardQuickActionQuest(quest)
                }}
            />
        </Grid>



    const gridViewAll = () => (
        <>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CardMedia image={fireIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                    <Typography sx={{ fontWeight: "bold" }} color="text.orange">
                        Ongoing QR Quests
                    </Typography>
                    <Badge badgeContent={oQuests.length + ""} sx={{
                        ml: 1,
                        "& .MuiBadge-badge": { color: "white", fontWeight: "bold", transform: "inherit", position: "inherit", bgcolor: "text.orange" }
                    }} />
                </Box>
                <Button variant="outlined" color="secondary"
                    onClick={() => {
                        history.push('/qr?filter=ongoing')
                        window.scrollTo(0, 0)
                    }}
                    sx={{ float: "right" }}
                > View All</Button>
            </Grid>
            {
                oQuests.slice(0, 4).map((qu) => ongoingQRCard(qu))
            }
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CardMedia image={upcomingIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                    <Typography sx={{ fontWeight: "bold" }} color="text.purple">
                        Upcoming QR Quests
                    </Typography>
                    <Badge badgeContent={uQuests.length + ""} sx={{
                        ml: 1,
                        "& .MuiBadge-badge": {
                            color: "white",
                            fontWeight: "bold",
                            transform: "inherit",
                            position: "inherit",
                            bgcolor: "text.purple"
                        }
                    }} />
                </Box>
                <Button variant="outlined" color="secondary"
                    onClick={() => {
                        history.push('/qr?filter=upcoming')
                        window.scrollTo(0, 0)
                    }}
                    sx={{ float: "right" }}
                > View All</Button>
            </Grid>
            {uQuests.slice(0, 4).map(quest => upcomingQRCard(quest))}
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CardMedia image={tickIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                    <Typography sx={{ fontWeight: "bold" }} color="text.green">
                        Completed QR Quests
                    </Typography>
                    <Badge badgeContent={cQuests.length + ""} sx={{
                        ml: 1,
                        "& .MuiBadge-badge": {
                            color: "white",
                            fontWeight: "bold",
                            transform: "inherit",
                            position: "inherit",
                            bgcolor: "text.green"
                        }
                    }} /></Box>
                <Button variant="outlined" color="secondary"
                    onClick={() => {
                        history.push('/qr?filter=completed')
                        window.scrollTo(0, 0)
                    }}
                    sx={{ float: "right" }}
                > View All</Button>
            </Grid>
            {cQuests.slice(0, 4).map(quest => upcomingQRCard(quest))}

        </>
    )

    const gridViewOngoing = () => {
        if (oQuests.length > 0) {
            return oQuests.map(quest => ongoingQRCard(quest))
        } else {
            return <EmptyList />
        }
    }

    const gridViewUpcoming = () => {
        if (uQuests.length > 0) {
            return uQuests.map(quest => upcomingQRCard(quest))
        } else {
            return <EmptyList />
        }
    }

    const gridViewCompleted = () => {
        if (cQuests.length > 0) {
            return cQuests.map(quest => completedQRCard(quest))
        } else {
            return <EmptyList />
        }
    }

    const page = () => <>
        <Backdrop open={loading} sx={{ zIndex: 9999 }}>
            <CircularProgress size={56} />
        </Backdrop>
        {
            isConfirmingStart
            // (true)
            &&
            <MyModal title={modalPreset.startQuestConfirm.title}
                subtitle={modalPreset.startQuestConfirm.subtitle}
                mainButtonText="Yes, Start the Quest"
                cb={() => {
                    updateQuest({
                        ...cardQuickActionQuest,
                        start_at: Math.floor(new Date() / 1000) - 1
                    }, setAllQuests, () => {
                        setCardQuickActionQuest(null)
                        setIsConfirmingStart(false)
                        history.push("/qr?filter=ongoing")
                    })
                }}
                subButtonText="Cancel"
                subCb={() => { setIsConfirmingEnd(false) }}
            />
        } {
            isConfirmingEnd
            // (true)
            &&
            <MyModal title={modalPreset.endQuestConfirm.title}
                subtitle={modalPreset.endQuestConfirm.subtitle}
                mainButtonText="Yes, End the Quest"
                cb={() => {
                    updateQuest({
                        ...cardQuickActionQuest,
                        end_at: Math.floor(new Date().getTime()) / 1000 - 1
                    }, setAllQuests, () => {
                        setCardQuickActionQuest(null)
                        setIsConfirmingEnd(false)
                        history.push("/qr?filter=completed")
                    })
                }}
                subButtonText="Cancel"
                subCb={() => { setIsConfirmingEnd(false) }}
            />
        }
        {
            isConfirmingDelete
            // (true)
            &&
            <MyModal title={modalPreset.deleteQuestConfirm.title}
                subtitle={modalPreset.deleteQuestConfirm.subtitle}
                mainButtonText="Yes, Delete the quest"
                cb={() => {
                    deleteQuest(cardQuickActionQuest,
                        setAllQuests,
                        () => {
                            setCardQuickActionQuest(null)
                            setIsConfirmingDelete(false)
                            history.push("/qr?filter=completed")
                        })
                }}
                subButtonText="Cancel"
                subCb={() => { setIsConfirmingDelete(false) }}
            />
        } {
            showingQR
            &&
            <MyModal title={cardQuickActionQuest.header}
                subtitle={cardQuickActionQuest.description}
                mainButtonText="Print"
                cb={() => {
                    setCardQuickActionQuest(null)
                    setShowingQR(false)
                    printQRCode(cardQuickActionQuest.id)
                }}
                subButtonText="Back"
                subCb={() => {
                    setShowingQR(false)
                    setCardQuickActionQuest(null)
                }}
                sx={{
                    "canvas": {
                        width: "100% !important",
                        height: "100% !important"
                    }
                }}
            >
                <QRCode value={cardQuickActionQuest.passcode}
                    id={cardQuickActionQuest.id}
                    logoImage={`${process.env.PUBLIC_URL}/logo.png`}
                    size={720} //controls the downloaded size
                    ecLevel="H"
                    fgColor={"#220808"}
                />
            </MyModal>
        }
        <Grid container spacing={2}>
            <Grid item xs={12} md={9} sx={{ mb: 2 }}>
                <CommonHeader title={`${filter.slice(0, 1).toUpperCase()}${filter.slice(1)} QR Quests`} subtitle="Create and manage status of QR Quests." />
            </Grid>

            <Grid item xs={12} md={3} sx={{ mb: 2, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                <Button color="secondary" variant="contained" href="qr/new" sx={{
                    boxShadow: "none", float: "right", mb: 1,
                }}>
                    <AddIcon />
                    Create Quest
                </Button>
            </Grid>
            {
                (filter === "all") &&
                gridViewAll()
            }
            {
                (filter === "ongoing") &&
                gridViewOngoing()
            }
            {
                filter === "upcoming" &&
                gridViewUpcoming()
            }
            {
                filter === "completed" &&
                gridViewCompleted()
            }
            {/* {allQuests.map(quest => (
                <Grid item key={quest.id} md={3} sm={6} xs={12} >
                    <PollCard
                        title={quest.header}
                        subtitle={`Starts ${timeAgo.format(quest.start_at * 1000)}\nEnds ${timeAgo.format(quest.end_at * 1000)}\nCreated ${timeAgo.format(quest.createdOn * 1000)}`}
                        imageStr={quest.img_url}
                        href1={`/qr/${quest.id}/edit`}
                        btnText1="Edit"
                        icon1={<CreateOutlinedIcon />}
                        btnText2={quest.start_at > new Date() / 1000 ? "Start Now" :
                            (quest.end_at > (new Date() / 1000)) ? "End Now" : null}
                        cb2={() => {
                            if (quest.start_at > new Date() / 1000) {
                                //start now
                                setCardQuickActionQuest(quest)
                                setIsConfirmingStart(true)
                            } else if (quest.end_at > (new Date() / 1000)) {
                                //end now
                                setCardQuickActionQuest(quest)
                                setIsConfirmingEnd(true)
                            }
                        }}
                    />
                </Grid>
            ))} */}

        </Grid>
    </>
    return <App2 page={page()} logout={logout} openStatus={openStatus} setStatus={setStatus} />

}
