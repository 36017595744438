import API from '@aws-amplify/api';
import { Button, CardMedia, Grid, Typography, Badge, ToggleButtonGroup, ToggleButton, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, Backdrop, CircularProgress, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getVouchers, updateVoucher, timeAgo, deleteVoucher } from './utils';
import voucherCover from "./assets/polls_obj_default.png";
import App2 from './App2';
import CommonHeader from './CommonHeader';
import AddIcon from '@mui/icons-material/Add';
import { useHistory, useLocation } from 'react-router';
import SearchIcon from '@mui/icons-material/Search';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import PollCard from './PollCard';
import { Box } from '@mui/system';
import fireIcon from './assets/admin_obj_fire.png';
import tickIcon from './assets/admin_obj_tick.png';
import upcomingIcon from './assets/admin_obj_upcoming.png';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import TableRowsIcon from '@mui/icons-material/TableRows';
import MyModal, { modalPreset } from './MyModal';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { EditRounded, SearchRounded } from '@mui/icons-material';
import EmptyList from './EmptyList';

export default function VoucherListView({ logout, openStatus, setStatus }) {

    const [allVouchers, setAllVouchers] = useState([])
    const [filter, setFilter] = useState("all")
    const [uVouchers, setUVouchers] = useState([]); //Upcoming Vouchers
    const [oVouchers, setOVouchers] = useState([]); //active vouchers
    const [cVouchers, setCVouchers] = useState([]); //completed vouchers
    const [cardQuickActionVoucher, setCardQuickActionVoucher] = useState();
    const [isConfirmingEnd, setIsConfirmingEnd] = useState(false);
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [loading, setLoading] = useState(false);


    const [useGridView, setUseGridView] = useState(true); //completed vouchers

    const history = useHistory()
    const location = useLocation();

    useEffect(() => {
        //if needs to redirect to signin page
        let _filter = new URLSearchParams(location.search).get("filter")
        setFilter(_filter)
    }, [location]);

    useEffect(() => {
        if ("allupcomingongoingcompleted".indexOf(filter) < 0 || filter.length === 0) {
            history.push("/")
        }
    }, [filter])


    useEffect(() => {
        console.log(filter)
        let _vcs = []
        let _uVcs = allVouchers.filter((voucher) => !voucher["start_at"] || !voucher["end_at"] || voucher["start_at"] * 1000 > new Date())
        let _aVcs = allVouchers.filter((voucher) => voucher["start_at"] * 1000 < new Date() && new Date() < voucher["end_at"] * 1000)
        let _fVcs = allVouchers.filter((voucher) => voucher["end_at"] * 1000 < new Date())
        setUVouchers(_uVcs)
        setOVouchers(_aVcs)
        setCVouchers(_fVcs)

        console.log(allVouchers)
    }, [filter, allVouchers])


    useEffect(() => {
        //if needs to redirect to signin page
        getVouchers(setLoading, setAllVouchers);
    }, []);
    const [isConfirmingPublishing, setIsConfirmingPublishing] = useState(false);
    const GetVoucherTable = (Vouchers) => (
        <Table>
            {VoucherTableHeader}
            <TableBody>
                {
                    Vouchers.map((vc) => (
                        <TableRow key={vc.vid}>
                            <TableCell align="left" sx={{ py: 0 }}> <img height="45" width="45" src={vc.img_url || voucherCover} alt="voucher" /></TableCell>
                            <TableCell align="left">{vc.header}</TableCell>
                            <TableCell align="left">{vc.price_gem}</TableCell>
                            <TableCell align="left">{vc.quantity + vc.orders.length}</TableCell>
                            <TableCell align="left">{vc.quantity}</TableCell>
                            <TableCell align="left">{new Date(vc.start_at * 1000).toLocaleString()}</TableCell>
                            <TableCell align="left">{new Date(vc.end_at * 1000).toLocaleString()}</TableCell>
                            <TableCell align="left">{vc.type === 0 ? "Flash Screen" : "Manual Delivery"}</TableCell>
                            <TableCell align="left">
                                <IconButton
                                    aria-label="view"
                                    size="small"
                                    onClick={() => { history.push(`voucher/${vc.vid}`) }}
                                ><SearchRounded /></IconButton><IconButton
                                    aria-label="edit"
                                    size="small"
                                    onClick={() => { }}
                                ><EditRounded /></IconButton></TableCell>
                        </TableRow>
                    ))
                }
            </TableBody>
        </Table>
    )

    const page = () => <>
        <Backdrop open={loading} sx={{ zIndex: 9999 }}>
            <CircularProgress size={56} />
        </Backdrop>
        {
            isConfirmingEnd
            // (true)
            &&
            <MyModal title={modalPreset.endVoucherConfirm.title}
                subtitle={modalPreset.endVoucherConfirm.subtitle}
                mainButtonText="Yes, End the Sale"
                cb={() => {
                    updateVoucher({
                        ...cardQuickActionVoucher,
                        end_at: Math.floor(new Date().getTime() / 1000) - 1
                    }, setAllVouchers, () => {
                        setCardQuickActionVoucher(null)
                        setIsConfirmingEnd(false)
                        history.push("/vouchers?filter=completed")
                    })
                }}
                subButtonText="Cancel"
                subCb={() => { setIsConfirmingEnd(false) }}
            />
        }
        {
            isConfirmingDelete
            // (true)
            &&
            <MyModal title={modalPreset.deleteVoucherConfirm.title}
                subtitle={modalPreset.deleteVoucherConfirm.subtitle}
                mainButtonText="Yes, Delete the voucher and its orders"
                cb={() => {
                    deleteVoucher(cardQuickActionVoucher, setAllVouchers, () => {
                        setCardQuickActionVoucher(null)
                        setIsConfirmingDelete(false)
                        history.push("/vouchers?filter=completed")
                    })

                }}
                subButtonText="Cancel"
                subCb={() => { setIsConfirmingDelete(false) }}
            />
        }
        {
            isConfirmingPublishing
            &&
            <MyModal title={modalPreset.startVoucherSaleConfirm.title}
                subtitle={modalPreset.startVoucherSaleConfirm.subtitle}
                mainButtonText="Yes, Publish the voucher"
                cb={() => {
                    updateVoucher({
                        ...cardQuickActionVoucher,
                        start_at: Math.floor(new Date().getTime() / 1000) - 1,
                    }, setAllVouchers, () => {
                        setCardQuickActionVoucher(null)
                        setIsConfirmingPublishing(false)
                        history.push("/vouchers?filter=ongoing")
                    })
                }}
                subButtonText="Cancel"
                subCb={() => { setIsConfirmingPublishing(false) }}
            />
        }
        <Grid container spacing={2}>
            <Grid item xs={12} md={9} sx={{ mb: 2 }}>
                <CommonHeader title={filter && filter.length > 0 ? `${filter[0].toUpperCase()}${filter.slice(1)} Vouchers` : "Vouchers"} subtitle="Create and manage status of redeemable reward vouchers." />
            </Grid>

            <Grid item xs={12} md={3} sx={{ mb: 2 }} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                <Button color="secondary" variant="contained" href="voucher/new" sx={{
                    boxShadow: "none", float: "right", mb: 1,
                }}>
                    <AddIcon />
                    Create Voucher
                </Button>

                <ToggleButtonGroup
                    color="primary"
                    value={useGridView}
                    exclusive
                    onChange={() => { setUseGridView(prev => !prev) }}
                >
                    <ToggleButton value={true}><ViewComfyIcon /></ToggleButton>
                    <ToggleButton value={false}><TableRowsIcon /></ToggleButton>
                </ToggleButtonGroup>
            </Grid>

            {useGridView ? gridView() : tableView()}

        </Grid>
    </>

    const renderOngoingCards = (vouchers, isPartial) => {
        if (vouchers.length > 0) {
            return vouchers.map(voucher => (
                <Grid item key={voucher.vid} md={3} sm={6} xs={12} >
                    <PollCard
                        title={voucher.header}
                        subtitle={`Created ${timeAgo.format(voucher.createdOn * 1000)}\nPublished ${timeAgo.format(voucher.start_at * 1000)}\nCloses ${timeAgo.format(voucher.end_at * 1000)}`}
                        imageStr={voucher.img_url}
                        href3={`/voucher/${voucher.vid}/edit`}
                        btnText3={" "}
                        icon3={<CreateOutlinedIcon />}

                        btnText2={"End Now"}
                        cb2={() => {
                            setCardQuickActionVoucher(voucher)
                            setIsConfirmingEnd(true)
                        }}

                        icon1={<SearchRounded />}
                        btnText1=" "
                        href1={`/voucher/${voucher.vid}`}
                    />
                </Grid>
            ))
        } else if (isPartial) {
            return <>  </>
        } else {
            return <EmptyList />
        }
    }


    const renderUpcomingCards = (vouchers, isPartial) => {
        if (vouchers.length > 0) {
            return vouchers.map(voucher => (
                <Grid item key={voucher.vid} md={3} sm={6} xs={12} >
                    <PollCard
                        title={voucher.header}
                        subtitle={`Publish ${timeAgo.format(voucher.start_at * 1000)}\nCreated ${timeAgo.format(voucher.createdOn * 1000)}`}
                        imageStr={voucher.img_url}
                        href1={`/voucher/${voucher.vid}/edit`}
                        btnText1=""
                        icon1={<CreateOutlinedIcon />}
                        btnText2={"Publish Now"}
                        cb2={() => {
                            setCardQuickActionVoucher(voucher)
                            setIsConfirmingPublishing(true)
                        }}
                    />
                </Grid>
            ))
        } else if (isPartial) {
            return <>  </>
        } else {
            return <EmptyList />
        }
    }

    const renderCompletedCards = (vouchers, isPartial) => {
        if (vouchers.length > 0) {
            return vouchers.map(voucher => (
                <Grid item key={voucher.vid} md={3} sm={6} xs={12} >
                    <PollCard title={voucher.header}
                        subtitle={`Created ${(voucher.createdOn && timeAgo.format(voucher.createdOn * 1000))}\nClosed ${timeAgo.format(voucher.end_at * 1000)}`}
                        imageStr={voucher.img_url}
                        href1={`/voucher/${voucher.vid}`}
                        btnText1=""
                        icon1={<SearchIcon />}
                        disabled2={false}
                        btnText2=" "
                        icon2={<DeleteOutlineIcon />}
                        cb2={() => {
                            setCardQuickActionVoucher(voucher)
                            setIsConfirmingDelete(true)
                        }}
                    />
                </Grid>
            ))
        } else if (isPartial) {
            return <>  </>
        } else {
            return <EmptyList />
        }
    }

    const gridViewAll = () => <>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia image={fireIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                <Typography sx={{ fontWeight: "bold" }} color="text.orange">
                    Ongoing Vouchers
                </Typography>
                <Badge badgeContent={oVouchers.length + ""} sx={{
                    ml: 1,
                    "& .MuiBadge-badge": { color: "white", fontWeight: "bold", transform: "inherit", position: "inherit", bgcolor: "text.orange" }
                }} />
            </Box>
            <Button variant="outlined" color="secondary"
                // href="/vouchers?filter=ongoing"
                onClick={() => {
                    history.push('/vouchers?filter=ongoing')
                    window.scrollTo(0, 0)
                }}
                sx={{ float: "right" }}
            > View All</Button>
        </Grid>
        {
            renderOngoingCards(oVouchers.slice(0, 4), true)
        }
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia image={upcomingIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                <Typography sx={{ fontWeight: "bold" }} color="text.purple">
                    Upcoming Vouchers
                </Typography>
                <Badge badgeContent={uVouchers.length + ""} sx={{
                    ml: 1,
                    "& .MuiBadge-badge": {
                        color: "white",
                        fontWeight: "bold",
                        transform: "inherit",
                        position: "inherit",
                        bgcolor: "text.purple"
                    }
                }} />
            </Box>
            <Button variant="outlined" color="secondary"
                // href="/vouchers?filter=upcoming"
                onClick={() => {
                    history.push('/vouchers?filter=upcoming')
                    window.scrollTo(0, 0)
                }}
                sx={{ float: "right" }}
            > View All</Button>
        </Grid>
        {
            renderUpcomingCards(uVouchers.slice(0, 4), true)
        }
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia image={tickIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                <Typography sx={{ fontWeight: "bold" }} color="text.green">
                    Completed Vouchers
                </Typography>
                <Badge badgeContent={cVouchers.length + ""} sx={{
                    ml: 1,
                    "& .MuiBadge-badge": {
                        color: "white",
                        fontWeight: "bold",
                        transform: "inherit",
                        position: "inherit",
                        bgcolor: "text.green"
                    }
                }} /></Box>
            <Button variant="outlined" color="secondary"
                // href="/vouchers?filter=ongoing"
                onClick={() => {
                    history.push('/vouchers?filter=completed')
                    window.scrollTo(0, 0)
                }}
                sx={{ float: "right" }}
            > View All</Button>
        </Grid>
        {
            renderCompletedCards(cVouchers.slice(0, 4), true)}

    </>

    const gridView = () => <>  {(filter === "all")
        ?
        gridViewAll()
        :
        (filter === "upcoming"
            ? renderUpcomingCards(uVouchers, false)
            : (filter === "ongoing"
                ? renderOngoingCards(oVouchers, false)
                : renderCompletedCards(cVouchers, false)
            )
        )
    }</>

    const VoucherTableHeader = <TableHead>
        <TableRow>
            <TableCell />
            <TableCell sx={{ fontWeight: "bold !important" }} align="left">Item</TableCell>
            <TableCell sx={{ fontWeight: "bold !important" }} align="left">Price</TableCell>
            <TableCell sx={{ fontWeight: "bold !important" }} align="left">Quantity</TableCell>
            <TableCell sx={{ fontWeight: "bold !important" }} align="left">Left</TableCell>
            <TableCell sx={{ fontWeight: "bold !important" }} align="left">Start Date</TableCell>
            <TableCell sx={{ fontWeight: "bold !important" }} align="left">End Date</TableCell>
            <TableCell sx={{ fontWeight: "bold !important" }} align="left">Type</TableCell>
            <TableCell />
        </TableRow>
    </TableHead>;
    const tableViewAll = () => <>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia image={fireIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                <Typography sx={{ fontWeight: "bold" }} color="text.orange">
                    Ongoing Vouchers
                </Typography>
                <Badge badgeContent={oVouchers.length + ""} sx={{
                    ml: 1,
                    "& .MuiBadge-badge": { color: "white", fontWeight: "bold", transform: "inherit", position: "inherit", bgcolor: "text.orange" }
                }} />
            </Box>
            <Button variant="outlined" color="secondary"
                // href="/vouchers?filter=ongoing"
                onClick={() => {
                    history.push('/vouchers?filter=ongoing')
                    window.scrollTo(0, 0)
                }}
                sx={{ float: "right" }}
            > View All</Button>

        </Grid>
        <TableContainer component={Paper}>
            {GetVoucherTable(oVouchers.slice(0, 2))}
        </TableContainer>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia image={upcomingIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                <Typography sx={{ fontWeight: "bold" }} color="text.purple">
                    Upcoming Vouchers
                </Typography>
                <Badge badgeContent={uVouchers.length + ""} sx={{
                    ml: 1,
                    "& .MuiBadge-badge": {
                        color: "white",
                        fontWeight: "bold",
                        transform: "inherit",
                        position: "inherit",
                        bgcolor: "text.purple"
                    }
                }} />
            </Box>
            <Button variant="outlined" color="secondary"
                // href="/vouchers?filter=upcoming"
                onClick={() => {
                    history.push('/vouchers?filter=upcoming')
                    window.scrollTo(0, 0)
                }}
                sx={{ float: "right" }}
            > View All</Button>
        </Grid>
        <TableContainer component={Paper}>
            {GetVoucherTable(uVouchers.slice(0, 2))}

        </TableContainer>

        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <CardMedia image={tickIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                <Typography sx={{ fontWeight: "bold" }} color="text.green">
                    Completed Vouchers
                </Typography>
                <Badge badgeContent={cVouchers.length + ""} sx={{
                    ml: 1,
                    "& .MuiBadge-badge": {
                        color: "white",
                        fontWeight: "bold",
                        transform: "inherit",
                        position: "inherit",
                        bgcolor: "text.green"
                    }
                }} /></Box>
            <Button variant="outlined" color="secondary"
                // href="/vouchers?filter=ongoing"
                onClick={() => {
                    history.push('/vouchers?filter=completed')
                    window.scrollTo(0, 0)
                }}
                sx={{ float: "right" }}
            > View All</Button>
        </Grid>
        <TableContainer component={Paper}>
            {GetVoucherTable(cVouchers.slice(0, 2))}
        </TableContainer>


    </>

    const tableView = () => <>  {(filter === "all") ?
        tableViewAll()
        :
        <TableContainer component={Paper}>
            {GetVoucherTable((filter === "upcoming" ? uVouchers : (filter === "ongoing" ? oVouchers : cVouchers)))}
        </TableContainer>
    }

    </>



    return <App2 page={page()} logout={logout} openStatus={openStatus} setStatus={setStatus} />
}
