import API from '@aws-amplify/api';
import { Typography, Backdrop, CircularProgress, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { escapeRegExp, getVouchers } from './utils';
import App2 from './App2';
import CommonHeader from './CommonHeader';
import { useHistory, useLocation } from 'react-router';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import defaultImage from "./assets/polls_obj_default.png";

import DataGridToolBar from './DataGridToolbar';
import MyModal, { modalPreset } from './MyModal';
import { SentimentNeutralSharp } from '@mui/icons-material';

export default function GrossOrderTable({ logout, openStatus, setStatus }) {
    const [allVouchers, setAllVouchers] = useState([])
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]); //viewable rows after search
    const [allRows, setAllRows] = useState([]); //all rows parsed by voucher.orders and voucher fields
    const history = useHistory()
    const location = useLocation();
    const [pageSize, setPageSize] = useState(20);
    const [isConfirmingAction, setIsConfirmingAction] = useState(false);

    useEffect(() => {
        getVouchers(setLoading, setAllVouchers);
    }, []);

    useEffect(() => {
        let _rows = []
        allVouchers.forEach(Voucher => {
            if (Voucher.type === 0)//flash
            {
                Voucher.orders.forEach((order) => {
                    let _row = { ...order }
                    _row.status = (_row.isRedeemed ? "Redeemed" : (Voucher.expiryDate < new Date() / 1000 ? "Expired" : "Available"))
                    _row.type = Voucher.type === 0 ? "Flash Screen" : "Manual Delivery"
                    _row.oid = _row.id
                    _row.voucherHeader = Voucher.header
                    _row.voucherId = Voucher.vid
                    _row.voucherPic = Voucher.img_url
                    _row.id += Voucher.vid
                    _row.redeemedOn = new Date(order.redeemedOn * 1000).toLocaleString()
                    _rows.push(_row)
                })
            } else { //manual
                Voucher.orders.forEach((order) => {
                    let _row = { ...order }
                    _row.status = (_row.deliveryProgress)
                    // _row.deliveryProgress = null;
                    _row.type = Voucher.type === 0 ? "Flash Screen" : "Manual Delivery"
                    _row.oid = _row.id
                    _row.voucherHeader = Voucher.header
                    _row.voucherId = Voucher.vid
                    _row.voucherPic = Voucher.img_url
                    _row.id += Voucher.vid
                    _row.redeemedOn = new Date(order.redeemedOn * 1000).toLocaleString()
                    _rows.push(_row)
                })
            }
        })
        setAllRows(_rows);
        setRows(_rows);
    }, [allVouchers])
    //table select stuff
    const [selectionModel, setSelectionModel] = useState([]);
    //end of table select stuff


    // table search stuff
    const [searchText, setSearchText] = React.useState('');

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = allRows.filter((row) => {
            return Object.keys(row).some((field) => {
                return row[field] && searchRegex.test(row[field].toString());
            });
        });
        setRows(filteredRows);
    };
    // end of table search stuff

    // start of button group
    const options = ['Pending', 'Processing', 'In Transit', 'Delivered'];

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [menuActionOption, setMenuActionOption] = useState("");

    const getEmailRecipientsString = () => {
        let emailCountMap = {}
        allVouchers.forEach(voucher => {
            if (voucher.type === 1) { //ignore flash type
                voucher.orders.forEach(order => {
                    selectionModel.forEach(ovid => {
                        if (ovid.indexOf(order.id) === 0) {
                            //match
                            //start editing entry
                            console.log(voucher)
                            if (voucher.vendorEmail1 in emailCountMap) {
                                emailCountMap[voucher.vendorEmail1]++
                            }
                            else {
                                emailCountMap[voucher.vendorEmail1] = 1
                            }
                            if (voucher.vendorEmail2 in emailCountMap) {
                                emailCountMap[voucher.vendorEmail2]++
                            }
                            else {
                                emailCountMap[voucher.vendorEmail2] = 1
                            }
                            //end editing entry
                        }
                    })
                })
            }
        })
        let str = ""
        Object.entries(emailCountMap).forEach((entry) => {
            str += ("\n" + entry[0] + " (" + entry[1] + (entry[1] <= 1 ? " email)" : " emails)"))
        })
        return str
    }

    // end of button group
    const page = (<>
        {
            isConfirmingAction
            &&
            <MyModal title={(menuActionOption === "Delivered") ? modalPreset.voucherOrderDeliverConfirm.title(selectionModel.length) :
                modalPreset.voucherOrderGenericConfirm(menuActionOption, selectionModel.length).title
            }
                subtitle={(menuActionOption === "Delivered")
                    ? `${modalPreset.voucherOrderDeliverConfirm.subtitle}${getEmailRecipientsString()}`
                    : "This Action is reversible."
                }
                mainButtonText="Yes"
                cb={() => {
                    setLoading(true)
                    let promiseArr = []
                    allVouchers.forEach((Voucher, idx) => { //loops over vouchers
                        let _orders = [...Voucher.orders]
                        let thisVoucherIsModified = false
                        for (var i = 0; i < selectionModel.length; i++) {
                            let selectedOrderId = selectionModel[i]
                            for (var j = 0; j < _orders.length; j++) {
                                let order = _orders[j]
                                console.log(order.id)
                                console.log(selectedOrderId)
                                if (selectedOrderId.indexOf(order.id) === 0) {
                                    order.deliveryProgress = menuActionOption;
                                    thisVoucherIsModified = true
                                }
                            }
                        }
                        if (thisVoucherIsModified) {
                            let newVoucher = { ...Voucher, orders: _orders }
                            promiseArr.push({
                                promise: API.put("JoyworldVoucher", "/voucher", {
                                    body: newVoucher,
                                }),
                                voucherIdx: idx
                            })
                        }
                    })
                    console.log(promiseArr)

                    Promise.allSettled(promiseArr.map(p => p.promise))
                        .then((results) => {
                            results.forEach(r => console.log(r))
                            window.location.reload(false)
                        })
                }}
                subButtonText="Cancel"
                subCb={() => { setIsConfirmingAction(false) }}
            />
        }
        <Backdrop open={loading} sx={{ zIndex: 9999 }}>
            <CircularProgress size={56} />
        </Backdrop>
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "space-between",
            alignItems: "flex-start"
        }}>

            <CommonHeader title={"Orders"} subtitle={"View and manage reward shop voucher orders."} />

        </Box>

        <DataGrid
            rows={rows}
            columns={[{
                field: 'voucherHeader',
                headerName: 'Item',
                minWidth: 35 * 16 / 2,
                flex: 3,
                headerClassName: "myTableHeader",
                renderCell: (param) => (<>
                    <img alt="voucher"
                        src={param.row.voucherImg || defaultImage}
                        style={{
                            height: "2rem",
                            objectFit: "contain",
                            marginRight: "5px"
                        }} />
                    <Tooltip title={param.value}>
                        <Box>{param.value}</Box>
                    </Tooltip>
                </>
                )
            },
            {
                field: 'voucherId',
                headerName: 'Voucher',
                minWidth: 90,
                flex: 1,
                headerClassName: "myTableHeader",
                renderCell: (param) => (<>
                    <a href={`/voucher/${param.value}`} >
                        v#{param.value.substring(0, 6)}
                    </a>
                </>),
            },
            {
                field: 'createdOn',
                headerName: 'Date',
                minWidth: 177,
                flex: 2,
                headerClassName: "myTableHeader",
                valueFormatter: (param) => (new Date(param.value * 1000).toLocaleString())
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 2,
                minWidth: 250,
                headerClassName: "myTableHeader",
                renderCell: (param) => {
                    let color = "";
                    if (param.value === "Pending") color = "secondary.main"
                    if (param.value === "Processing") color = "warning.main"
                    if (param.value === "In transit") color = "blue"
                    if (param.value === "Delivered") {
                        color = "text.green"

                    }
                    if ((param.value === "Delivered" || param.value === "Redeemed") &&
                        param.row.redeemedOn && param.row.redeemedOn !== "Invalid Date") {
                        param.value += ` (on ${param.row.redeemedOn})`
                    }
                    return (
                        <Typography sx={{
                            color: color
                        }}>{param.value}</Typography>
                    )
                }

            },
            {
                field: 'type',
                headerName: 'Type',
                minWidth: 120,
                flex: 1,
                headerClassName: "myTableHeader",
            },
            {
                field: 'buyer',
                headerName: 'Buyer ID',
                headerClassName: "myTableHeader",
                width: 290,
                // flex: 2,
                renderCell: (param) =>
                    <Tooltip title={param.value} >
                        <Typography sx={{}}>{param.value}</Typography>
                    </Tooltip>
            },
            {
                field: 'buyerEmail',
                headerName: 'Buyer Email',
                headerClassName: "myTableHeader",
                minWidth: 250,
                flex: 2,
                renderCell: (param) =>
                    <Tooltip title={param.value}>
                        <Typography sx={{}}>{param.value}</Typography>
                    </Tooltip>
            },
            {
                field: 'oid',
                headerName: 'Order ID',
                width: 290,
                headerClassName: "myTableHeader",
                renderCell: (param) =>
                    <Tooltip title={param.value}>
                        <Typography >{param.value}</Typography>
                    </Tooltip>
            }]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            autoHeight
            rowsPerPageOptions={[10, 20, 50, 100]}
            checkboxSelection
            disableSelectionOnClick
            components={{ Toolbar: DataGridToolBar }}
            componentsProps={{
                toolbar: {
                    hideButtons: false,
                    searchValue: searchText,
                    onSearchChange: (event) => requestSearch(event.target.value),
                    clearSearch: () => requestSearch(''),
                    open: open,
                    anchorRef: anchorRef,
                    selectedIndex: selectedIndex,
                    setSelectedIndex: setSelectedIndex,
                    handleClick: () => {
                        console.info(`You clicked ${options[selectedIndex]}`);
                    },
                    handleToggle: ((e) => {
                        e.preventDefault()
                        setOpen((prevOpen) => !prevOpen)
                    }),
                    options: options,
                    optionDisabled: (targetStatus) => {
                        if (targetStatus.toLowerCase() === 'delivered')
                            return false;
                        let num_pending = 0, num_processing = 0, num_intransit = 0, num_delivered = 0;
                        allRows.filter(originalOrder => (selectionModel.includes(originalOrder.id))).forEach(
                            (selectedOrder) => {
                                if (selectedOrder.deliveryProgress.toLowerCase() === "pending") num_pending++;
                                if (selectedOrder.deliveryProgress.toLowerCase() === "processing") num_processing++;
                                if (selectedOrder.deliveryProgress.toLowerCase() === "in transit") num_intransit++;
                                if (selectedOrder.deliveryProgress.toLowerCase() === "delivered") num_delivered++;
                            }
                        )
                        if (targetStatus.toLowerCase() === 'pending' && (num_delivered > 0))
                            return true;
                        if (targetStatus.toLowerCase() === 'processing' && (num_intransit > 0 || num_delivered > 0))
                            return true;
                        else if (targetStatus.toLowerCase() === 'in transit' && (num_delivered > 0))
                            return true;
                    },
                    buttonDisabled: (() => {
                        // true if any of selected rows are flash screen
                        let hasFlashScreenType = false
                        allRows.forEach(order => {
                            if (selectionModel.includes(order.id) && order.type === "Flash Screen") {
                                hasFlashScreenType = true
                            }
                        })
                        return hasFlashScreenType
                    }),
                    handleClose: (event) => {
                        if (anchorRef.current && anchorRef.current.contains(event.target)) {
                            return;
                        }
                        setOpen(false);
                    },
                    handleMenuItemClick: (option) => {
                        setIsConfirmingAction(true)
                        setMenuActionOption(option)
                    },
                    selectedRows: selectionModel,
                    fileName: `joyworld-orders-${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}-${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`,
                },
            }}
            sx={{
                maxWidth: "100%",
                pl: 1,
                pr: 2,
                "&, & .MuiTypography-root": {
                    fontSize: "0.85rem"
                },
                "& a": {
                    color: "secondary.main"
                },
                "& .MuiDataGrid-columnSeparator": {
                    color: "text.disabled"
                },
                '& .myTableHeader, & .MuiDataGrid-cell': {
                    outline: "none !important",
                },
                '& .MuiDataGrid-row': {
                    borderWidth: "2px 0 0 0",
                    borderStyle: "solid",
                    borderColor: "text.disabled",
                    boxSizing: "border-box",
                },
                '& .MuiDataGrid-row:last-child': {
                    borderWidth: "2px 0 ",
                    borderStyle: "solid",
                    borderColor: "text.disabled",
                    boxSizing: "border-box",
                },
                '& .MuiDataGrid-row:first-of-type': {
                    borderWidth: "2px 0 0 0 ",
                    borderStyle: "solid",
                    borderColor: "text.disabled",
                    boxSizing: "border-box",
                },
                "& .MuiDataGrid-virtualScroller": {
                    height: null,
                    overflowY: "hidden",
                },
            }}
            onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                console.log(newSelectionModel)
            }}
            selectionModel={selectionModel}

        />
    </>)
    return <App2 containerProps={{ maxWidth: false }} page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />
}
