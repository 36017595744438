import React, { useState, useEffect, useCallback } from 'react';
import MyNavBar from './MyNavBar';

import {
    Button,
    Typography,
    Container,
    TextField,
    Grid,
    CircularProgress,
    useMediaQuery,
    Backdrop,
    Autocomplete
} from '@mui/material';
import Storage from '@aws-amplify/storage';
import Amplify from 'aws-amplify';
import csvDownload from 'json-to-csv-export'
import App2 from './App2';
import CommonHeader from './CommonHeader';
import { Box, useTheme } from '@mui/system';
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { IsProdEnv } from './utils';
import { AllOutTwoTone } from '@mui/icons-material';
import axios from "axios";
import Auth from '@aws-amplify/auth';

export default function DownloadUserData({ logout, openStatus, setStatus }) {

    const allStages = IsProdEnv() ? ["prod"] : ["prebeta", "beta"]
    const [stage, setStage] = useState(allStages[0])
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)


    const fetchUserCognitoData = () => {
        setLoading(true)
        let url = ""
        if (stage === "beta") {
            url = "https://sq59t0jsd1.execute-api.ap-southeast-1.amazonaws.com/beta/user-map"
        } else if (stage === "prebeta") {
            url = "https://g6j3vu336i.execute-api.ap-southeast-1.amazonaws.com/prebeta/user-map"
        } else if (stage === "prod") {
            url = "https://otreecfly6.execute-api.ap-southeast-1.amazonaws.com/prod/user-map"
        } else {
            setLoading(false)
            return;
        }

        Auth.currentAuthenticatedUser().then(res => {
            let idToken = res.signInUserSession.idToken.jwtToken
            axios.get(url, {
                headers: {
                    "Authorization": idToken,
                }
            }).then(res => {
                console.log(res)
                var blob = new Blob([res.data], {
                    type: 'text/csv;charset=utf-8'
                });
                let filename = `joyworld-players-id-${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(blob, filename);
                    return;
                }
                var link = document.createElement('a');
                var url = URL.createObjectURL(blob);
                link.href = url;
                link.download = filename;
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoading(false)
            }
            ).catch(err => {
                console.log(err)
                setLoading(false)
            })

        })

    }

    const fetchUserDBData = () => {
        setLoading2(true)
        API.get("PollingApi2", "/all-players", {
            queryStringParameters: {  // OPTIONAL
                stage: stage,
            }
        }).then(res => {
            csvDownload(res, `joyworld-players-${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}.csv`)
            setLoading2(false)
        }).catch(err => {
            console.log(err)
            setLoading2(false)
        })
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // const downloadData = () => {
    //     setIsDownloading(true)
    //     csvDownload(filteredVotes, `joyworld-poll-${year}-${month}.csv`)
    //     setIsDownloading(false)
    // }

    const page = (
        <>
            <Backdrop open={loading || loading2} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            <CommonHeader title={"Download Player Information"} subtitle="Download username and email information for Data Analytics and more." />

            <Container maxWidth="lg" sx={{ p: isMobile ? 0 : 2 }}>
                {!IsProdEnv() &&
                    < Box >
                        <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                            Select Environment
                        </Typography>
                        <Grid container sx={{ my: 2 }} justify="space-between" alignItems="flex-end" spacing={2}>
                            <Grid item xs={12} md={IsProdEnv() ? 4 : 3}  >
                                <Autocomplete
                                    options={allStages}
                                    selectOnFocus
                                    clearOnBlur
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} label="Stage" />}
                                    value={stage}
                                    onChange={(event, newValue) => {
                                        if (newValue == null)
                                            return
                                        setStage(newValue)
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, boxShadow: "none" }}
                    disabled={loading}
                    onClick={fetchUserCognitoData}>

                    Download User Identities
                </Button>
                <br />
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, boxShadow: "none" }}
                    disabled={loading}
                    onClick={fetchUserDBData}>
                    Download User Records
                </Button>
            </Container>
        </>
    )
    return <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />
}
