import React from 'react'
import App2 from './App2'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import PollRoundedIcon from '@mui/icons-material/Poll';
import EmailRoundedIcon from '@mui/icons-material/Email';
import RedeemRoundedIcon from '@mui/icons-material/Redeem';
import NotificationsRoundedIcon from '@mui/icons-material/Notifications';
import { Button, Link, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router';
import { theme } from './style';
import { ArrowRight } from '@mui/icons-material';
import { IsProdEnv } from './utils';

const content = [
    {
        "title": "Polls",
        "body": "Create, delete, and view results of polls here.",
        "icon": <PollRoundedIcon sx={{ fontSize: "60px", color: theme => theme.palette.secondary.main }} />,
        "href": "/poll?filter=all",
    },
    {
        "title": "Mail",
        "body": "Send text, gems or resources to players here.",
        "icon": <EmailRoundedIcon sx={{ fontSize: "60px", color: theme => theme.palette.secondary.main }} />,
        "href": "/send-mail"
    },
    {
        "title": "Reward Shop",
        "body": "Create and manage status of redeemable reward vouchers.",
        "icon": <RedeemRoundedIcon sx={{ fontSize: "60px", color: theme => theme.palette.secondary.main }} />,
        "href": "/vouchers?filter=all",
    },
    {
        "title": "QR Quest",
        "body": "Create and manage status of QR quests.",
        "icon": <NotificationsRoundedIcon sx={{ fontSize: "60px", color: theme => theme.palette.secondary.main }} />,
        "href": "qr?filter=all",
    },
    {
        "title": "Push Notification",
        "body": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        "icon": <NotificationsRoundedIcon sx={{ fontSize: "60px", color: theme => theme.palette.secondary.main }} />,
        "href": "#",
        disabled: true,
    },
]

const quickbtns = [
    {
        text: "Create New Poll",
        href: "/poll/new",
        disabled: false,
    },
    {
        text: "Send Game Mail",
        href: "/send-mail",
        disabled: false,
    },
    {
        text: "Manage Reward Shop",
        href: "/vouchers?filter=all",
        disabled: false,
    },
    {
        text: "Push a Notification",
        href: "/push-notif",
        disabled: IsProdEnv(),
    },
    {
        text: "Download Current Month's Data",
        href: "/download-data",
        disabled: false,
    },
    {
        text: "View all ongoing polls",
        href: "/poll?filter=ongoing",
        disabled: false,
    },
]
export default function Landing({ logout, openStatus, setStatus }) {
    const history = useHistory()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const page = (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box  >
                        <CardMedia
                            sx={{
                                minHeight: "200px",
                                backgroundPosition: "right",
                                position: "relative",
                            }}
                            image={`${process.env.PUBLIC_URL}/admin_obj_banner.png`}
                        >
                            <Typography color="divider" sx={{
                                fontWeight: "bold",
                                fontSize: "2.2rem",
                                position: "absolute",
                                bottom: 24,
                                left: 32,
                            }}>
                                JoyWorld Admin Dashboard
                            </Typography>
                        </CardMedia>

                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} justifyItems="stretch">
                        {content.filter(item => !item.disabled).map(item => (
                            <Grid item md xs={12} key={item.title}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                        p: "15%",
                                        borderColor: "divider",
                                        borderWidth: "2px",
                                        borderStyle: "solid",
                                        boxderBox: "box-sizing",
                                        '&:hover': {
                                            backgroundColor: 'divider',
                                        },
                                        minHeight: (isMobile ? 150 : 250),
                                        // height: isMobile ? null : (content.filter(item => !item.disabled).length / 4),
                                        cursor: item.disabled ? "not-allowed" : "pointer"
                                    }}
                                    onClick={() => {
                                        if (item.disabled) { return }
                                        console.log(item.href)
                                        history.push(item.href)
                                    }}
                                >
                                    <Box >{item.icon}</Box>
                                    <Box>
                                        <Typography
                                            noWrap
                                            sx={{
                                                fontWeight: "bold",
                                                mt: 2,
                                                fontSize: "1.2rem"
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            paragraph
                                            sx={{
                                                fontSize: "0.875rem",
                                                color: theme => theme.palette.text.primary,
                                                textAlign: "center"
                                            }}
                                        >{item.body}
                                        </Typography>
                                    </Box>

                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                        Quick Action
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: isMobile ? "50vh" : "unset" }}>
                    {quickbtns.map(btn => (

                        <Button
                            variant="outlined"
                            color="secondary"
                            endIcon={<ArrowRight />}
                            onClick={() => { history.push(btn.href) }}
                            disabled={btn.disabled}
                            sx={{ mr: 2, mb: 2 }}
                            key={btn.text}
                        >
                            {btn.text}
                        </Button>
                    ))
                    }
                </Grid>
            </Grid>

        </>
    )
    return (
        <App2 logout={logout} page={page} openStatus={openStatus} setStatus={setStatus} />
    )
}
