import {
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';


export default function PollOption({
  option,
  setOptionArr,
  id,
  deleteOption,
}) {

  //const [name, setName] = useState("");
  //const [description, setDescription] = useState("");
  const handleOptionChange = (e) => {
    let partial_new = {};
    partial_new[e.target.name] = e.target.value;
    let opt = {};
    opt = { ...option, ...partial_new };
    setOptionArr((prevState) => {
      let copyPrev = [...prevState]
      copyPrev[opt.index] = opt
      return copyPrev
    });
  };
  return (
    <>
      {option.index > 1 && (
        <Grid item xs={1}>
          <IndeterminateCheckBoxOutlinedIcon
            sx={{ "color": "secondary.main" }}
            onClick={() => {
              deleteOption(id);
            }}
          />
        </Grid>
      )}
      <Grid item xs={(option.index <= 1) ? 12 : 11}>
        <TextField
          name="name"
          variant="outlined"
          required
          fullWidth
          id="name"
          label="Option"
          onChange={handleOptionChange}
          value={option.name}
        />
      </Grid>

      {/* <Grid item xs={12}>
        <TextField
          name="description"
          variant="outlined"
          multiline
          rows={2}
          fullWidth
          id="description"
          onChange={handleOptionChange}
          label="Option Subtext"
          value={option.description}
        />
      </Grid> */}
    </>
  );
}
