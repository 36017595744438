import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";
import Compress from "react-image-file-resizer";
import { Button, CardMedia, Container, Grid, TextField, RadioGroup, FormLabel, FormControlLabel, Radio, Checkbox, Backdrop, CircularProgress, useMediaQuery, Tabs, Tab, Typography, Switch, Alert } from "@mui/material";
import { addMonths, getTime } from "date-fns";

import DateFnsUtils from '@date-io/date-fns';
import { dataURLtoFile, TOOLTIP_PRESETS, ValidateEmail, updateVoucher, isValidDate } from "./utils";
import Storage from '@aws-amplify/storage';
import awsmobile from "./aws-exports"
import App2 from "./App2";
import MyModal, { modalPreset } from "./MyModal";
import CommonHeader from "./CommonHeader"
import { Box, useTheme } from "@mui/system";
import { TabPanel } from "./PollEditView2";
import VoucherCover from "./assets/polls_obj_default.png";

import MobileDatePicker from '@mui/lab/MobileDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TimePicker from '@mui/lab/TimePicker';
import TextWithTooltipIcon from "./TextWithTooltipIcon";

export default function VoucherEditView({ logout, openStatus, setStatus }) {

    const history = useHistory();
    const [Image, setImage] = useState("")
    let { id } = useParams();
    const [EditedPicture, setEditedPicture] = useState(false) //used in edit mode only
    const [loading, setLoading] = useState(false)
    const [Voucher, setVoucher] = useState({
        header: "Voucher $",
        description: "",
        price_gem: 1,
        type: 0, //0 for flash screen, 1 for manual delivery (qty 1). need to parse to N from S 
        quantity: 1,
        expiryDate: addMonths(new Date(), 3),
        vendorEmail1: "",
        vendorEmail2: "",
        adminEmail: "jwsupport@lionfish-studios.com",
        orders: [],
        start_at: new Date(),
        end_at: addMonths(new Date(), 1),
        TnC: ""
    })
    const [toPublish, setToPublish] = useState(!id);
    const [isSuccessCreation, setIsSuccessCreation] = useState(false)
    const [isSuccessEdition, setIsSuccessEdition] = useState(false)
    const [tab, setTab] = useState(0)
    const [err, setErr] = useState("");
    const [DateErrorMsg, setDateErrorMsg] = useState("")

    useEffect(() => {
        if (isValidDate(Voucher.start_at) && isValidDate(Voucher.end_at) && isValidDate(Voucher.expiryDate)) {
            setDateErrorMsg("")
        } else {
            setDateErrorMsg("Invalid Date(s)")
        }
    }, [Voucher.end_at, Voucher.expiryDate, Voucher.start_at])

    useEffect(() => {
        if (toPublish) {
            setVoucher(prev => ({
                ...prev,
                start_at: new Date()
            }))
        }
    }, [toPublish]);

    const handleVoucherChange = (e) => {
        setVoucher((prev) => (
            (e.target.name === "price_gem" || e.target.name === "quantity") ?
                {
                    ...prev,
                    [e.target.name]: e.target.valueAsNumber
                }
                : {
                    ...prev,
                    [e.target.name]: e.target.value || e.target.checked || "",
                }
        ));
    };

    useEffect(() => {

        if (Voucher.header.length > 35) {
            setVoucher((prev) => ({
                ...prev,
                header: prev.header.substring(0, 35)
            }))
        }
    }, [Voucher])
    const WasStarted = () => {
        if (id) {
            return (new Date().getTime() > Voucher.start_at.getTime())
        }
        else {
            return false
        }
    }

    // useEffect(() => { console.log(Voucher) }, [Voucher]?)
    useEffect(() => {
        //if the Voucher id is present, get the item from db
        if (id) {
            API.get("JoyworldVoucher", `/voucher/object/${id}`)
                .then((vc) => {
                    if (vc.length <= 0) {
                        //No item was found
                        history.push("/");
                    } else {
                        setVoucher({
                            ...vc,
                            expiryDate: new Date(vc.expiryDate * 1000),
                            start_at: new Date(vc.start_at * 1000),
                            end_at: new Date(vc.end_at * 1000),
                        }); //"decode" the dates
                        setToPublish(false)
                    }
                    //fetch the s3 picture
                    if (vc.img_s3) {
                        Storage.get(`${vc.img_s3}`, { level: "public" })
                            .then(signedUrl => {
                                console.log(signedUrl)
                                fetch(signedUrl).then(res => res.blob()).then(blob => {
                                    var reader = new FileReader();
                                    reader.onloadend = function () {
                                        // console.log('RESULT', reader.result)
                                        setImage(reader.result)
                                    }
                                    reader.readAsDataURL(blob);
                                    // setImage(res.body)
                                }).catch(err => {
                                    console.error(err)
                                })
                            }).catch(err => {
                                console.error(err)
                            })
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [history, id])
    const EncodePic = (file) => {
        //resize
        Compress.imageFileResizer(
            file, // the file from input
            1024, // width
            1024, // height
            "PNG", // compress format WEBP, JPEG, PNG
            50, // quality
            0, // rotation
            (uri) => {
                console.log(uri.length);
                // You upload logic goes here
                setImage(uri)
            },
            "base64" // blob or base64 default base64
        )
        setEditedPicture(true)
    };
    const createVoucher = (e) => {
        console.log("createVoucher")
        if (Voucher.header === ""
            || Voucher.adminEmail === ""
            || Voucher.vendorEmail1 === ""
            || Voucher.TnC === "") {
            setErr(TOOLTIP_PRESETS.required)
            return;
        }
        if (!ValidateEmail(Voucher.adminEmail)
            || !ValidateEmail(Voucher.vendorEmail1)
            || (Voucher.vendorEmail2.length > 0 && !ValidateEmail(Voucher.vendorEmail2))) {
            setErr("Wrong email format")
            return;
        }
        if (Voucher.quantity < 0
            || Voucher.price_gem < 0
        ) {
            setErr(`"Quantity" and "Price in Gem" cannot be negative`)
            return;
        }
        setLoading(true);
        setErr("")
        const vId = uuidv4();
        const _postVoucher = (signedUrl) => API.post("JoyworldVoucher", "/voucher", {
            body: {
                ...Voucher,
                vid: vId,
                createdOn: Math.floor(Date.now() / 1000),
                start_at: Math.floor(Voucher.start_at.getTime() / 1000),
                end_at: Math.floor(Voucher.end_at.getTime() / 1000),
                expiryDate: Math.floor(Voucher.expiryDate.getTime() / 1000),
                img_s3: `${signedUrl}`,
                type: parseInt(Voucher.type)
            }
        }).then(res => {
            // console.log(res);
            // history.push("/vouchers");
            setLoading(false)
            setIsSuccessCreation(true)
        }).catch(err => {
            console.error(err);
        });

        if (Image) {
            let file = dataURLtoFile(Image, vId + ".png") //filename will be the same as the voucher id
            Storage.put(file.name, file, {
                level: "public",
            }).then(res => {
                _postVoucher(res.key)
            }).catch(err => {
                console.error(err)
            })
        } else {
            _postVoucher("")
        }
    }
    const editVoucher = (e) => {
        console.log("editVoucher")
        setLoading(true);
        let file
        try { file = dataURLtoFile(Image, Voucher.vid + ".png") } catch (err) { }//filename will be the same as the voucher id
        if (EditedPicture) {
            Storage.put(file.name, file, {
                level: "public",
            }).then(res => {
                console.log(res)
                _editVoucher(res.key)
            }).catch(err => {
                console.error(err)
            })
        } else {
            _editVoucher()
        }

    }
    const _editVoucher = (imageKey) => {
        let body = {
            ...Voucher,
            start_at: Math.floor(Voucher.start_at.getTime() / 1000),
            end_at: Math.floor(Voucher.end_at.getTime() / 1000),
            expiryDate: Math.floor(Voucher.expiryDate.getTime() / 1000),
            type: typeof (Voucher.type) == "number" ? Voucher.type : parseInt(Voucher.type)
        }
        if (imageKey) {
            body.img_s3 = `${imageKey}`
        }

        updateVoucher(body, null, (res) => {
            console.log(res);
            // history.push("/vouchers")
            // history.push("/");
            setLoading(false)
            setIsSuccessEdition(true);
        })
    }
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const page = (
        <>
            <Backdrop open={loading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            {
                (isSuccessCreation || isSuccessEdition)
                // (true)
                &&
                <MyModal title={isSuccessCreation ? modalPreset.voucherCreationSuccess.title : modalPreset.voucherEditionSuccess.title}
                    subtitle={isSuccessCreation ? modalPreset.voucherCreationSuccess.subtitle : modalPreset.voucherEditionSuccess.subtitle}
                    mainButtonText="Back to Vouchers"
                    subButtonText={isSuccessCreation ? "Make Another Voucher" : null}
                    cb={() => {
                        if (toPublish) {
                            history.push("/vouchers?filter=ongoing")
                        } else {
                            history.push("/vouchers?filter=upcoming")
                        }
                    }}
                    subCb={isSuccessCreation ? () => {
                        window.location.reload(false)
                    } : null}
                />
            }
            <CommonHeader title={(!id) ? "Create a New Voucher" : "Edit Voucher"} subtitle="Create an in-game redeemable voucher for players to enjoy the fruits of their labour!" />
            <Container maxWidth="lg" sx={{ p: isMobile ? 1 : 2 }}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        if (id) {
                            editVoucher();
                        } else {
                            createVoucher();
                        }
                    }}
                >
                    <Grid container maxWidth="md" spacing={3}>
                        <Grid item md={8}>

                            {/* <Box>
                                    <Tabs value={tab} onChange={(e, val) => { setTab(val) }} aria-label="tabs">
                                        <Tab label="Content" />
                                        <Tab label="Logistics" />
                                    </Tabs>
                                </Box> */}
                            {/* <TabPanel value={tab} index={0}> */}
                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <Grid container
                                        direction="row"
                                        justifyContent="center"
                                        spacing={2}
                                        alignItems="flex-end"
                                    >

                                        <Grid item xs={12} sm={6} sx={{ height: "100%" }}>
                                            <Grid container
                                                direction="column"
                                                spacing={1}
                                                justifyContent="flex-start"
                                            >
                                                <Grid item sx={{ pl: "0!important" }}>
                                                    <TextField
                                                        name="header"
                                                        onChange={handleVoucherChange}
                                                        variant="outlined"
                                                        fullWidth
                                                        id="header"
                                                        label="Voucher Title*"
                                                        value={Voucher.header}
                                                        disabled={WasStarted()}
                                                    />
                                                </Grid>
                                                <Grid item sx={{ pl: "0!important" }}>
                                                    <TextField
                                                        name="description"
                                                        variant="outlined"
                                                        multiline
                                                        rows={4}
                                                        fullWidth
                                                        id="description"
                                                        onChange={handleVoucherChange}
                                                        label="Description*"
                                                        value={Voucher.description}
                                                    />
                                                </Grid>
                                                <Grid item sx={{ pl: "0!important" }}>
                                                    <TextField
                                                        name="TnC"
                                                        variant="outlined"
                                                        multiline
                                                        rows={5}
                                                        fullWidth
                                                        id="TnC"
                                                        onChange={handleVoucherChange}
                                                        label="Terms and Conditions*"
                                                        value={Voucher.TnC}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={6} sx={{ height: "100%" }}
                                        >
                                            <Grid container
                                                direction="column"
                                                spacing={1}
                                                justifyContent="flex-start"
                                            >
                                                <Grid item>
                                                    {
                                                        <CardMedia sx={{
                                                            minHeight: "150px",
                                                            backgroundSize: "contain"
                                                        }} image={Image || VoucherCover} />
                                                    }
                                                </Grid>
                                                <Grid item sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                                    <Button variant="outlined" color="secondary" component="label"
                                                        disabled={WasStarted()}
                                                    >
                                                        Upload Image
                                                        <input
                                                            type="file"
                                                            hidden
                                                            accept="image/*"
                                                            onChange={(e) => {
                                                                EncodePic(e.target.files[0]);
                                                            }}
                                                        />
                                                    </Button>
                                                </Grid>
                                                <Grid item sm={3} xs={6}>
                                                    <TextField
                                                        name="quantity"
                                                        label="Quantity*"
                                                        type="number"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={Voucher.quantity}
                                                        onChange={handleVoucherChange}
                                                        disabled={WasStarted()}
                                                    />
                                                </Grid>
                                                <Grid item sm={3} xs={6}>
                                                    <TextField
                                                        label="Price in Gem*"
                                                        type="number"
                                                        name="price_gem"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={Voucher.price_gem}
                                                        onChange={handleVoucherChange}
                                                        disabled={WasStarted()}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel component="legend" disabled={WasStarted()}>Redemption Mode</FormLabel>
                                    <RadioGroup
                                        aria-label="mode"
                                        value={Voucher.type}
                                        name="type"
                                        onChange={handleVoucherChange}
                                    >
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    value={0} control={
                                                        <Radio
                                                            color="primary"
                                                        />}
                                                    label="Flash Screen"
                                                    disabled={WasStarted()} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel
                                                    value={1} control={
                                                        <Radio
                                                            color="primary"
                                                        />}
                                                    label="Manual Delivery"
                                                    disabled={WasStarted()}
                                                />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextWithTooltipIcon text={"Admin Email*"}
                                        tooltip={"for email notification to DBS when a voucher has been purchased or redeemed"}
                                        textStyle={{ fontWeight: "bold" }} />
                                    <TextField
                                        name="adminEmail"
                                        onChange={handleVoucherChange}
                                        variant="outlined"
                                        fullWidth
                                        id="adminEmail"
                                        value={Voucher.adminEmail}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextWithTooltipIcon text={"Vendor Email 1*"}
                                        tooltip={"for email notification to voucher vendor when a voucher has been purchased, redeemed, or delivered"}
                                        textStyle={{ fontWeight: "bold" }} />
                                    <TextField
                                        name="vendorEmail1"
                                        onChange={handleVoucherChange}
                                        variant="outlined"
                                        fullWidth
                                        id="vendorEmail1"
                                        value={Voucher.vendorEmail1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextWithTooltipIcon text={"Vendor Email 2"}
                                        tooltip={"for email notification to voucher vendor when a voucher has been purchased, redeemed, or delivered"}
                                        textStyle={{ fontWeight: "bold" }} />

                                    <TextField
                                        name="vendorEmail2"
                                        onChange={handleVoucherChange}
                                        variant="outlined"
                                        fullWidth
                                        id="vendorEmail2"
                                        value={Voucher.vendorEmail2}
                                    />
                                </Grid>

                            </Grid>
                            {/* </TabPanel> */}
                            {/* <TabPanel value={tab} index={1}> */}
                            {/* <Grid container xs={12} sx={{ mb: 2 }} spacing={2}> */}
                            {/* </Grid> */}
                            {/* </TabPanel> */}
                        </Grid>
                        <Grid item md={4}>
                            <Grid container rowSpacing={1} sx={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "divider",
                                p: 2,
                                mt: 0
                            }}>
                                <Grid item xs={12}>
                                    <TextWithTooltipIcon
                                        text={"Expiry Date"}
                                        tooltip={TOOLTIP_PRESETS.voucher_expire}
                                        textStyle={{ fontWeight: "bold" }}
                                    />
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={12}>
                                        <MobileDatePicker
                                            margin="normal"
                                            label="Choose Date (MM/DD/YYYY)"
                                            inputFormat="MM/dd/yyyy"
                                            value={Voucher.expiryDate}
                                            onChange={(date) => {
                                                setVoucher(
                                                    prev => ({ ...prev, expiryDate: date }))
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                                            disabled={WasStarted()}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Choose Time"
                                            value={Voucher.expiryDate}
                                            onChange={(date) => {
                                                setVoucher(prev => ({ ...prev, expiryDate: date }))
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change end time',
                                            }}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }}  {...params} />}
                                            disabled={WasStarted()}
                                        />

                                    </Grid>
                                </LocalizationProvider >
                                <Grid item xs={12}>
                                    <TextWithTooltipIcon
                                        text={"End Date"}
                                        tooltip={TOOLTIP_PRESETS.voucher_end}
                                        textStyle={{ fontWeight: "bold" }}
                                    />
                                </Grid>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={12}>
                                        <MobileDatePicker
                                            margin="normal"
                                            label="Choose Date (MM/DD/YYYY)"
                                            inputFormat="MM/dd/yyyy"
                                            value={Voucher.end_at}
                                            onChange={(date) => {
                                                setVoucher(
                                                    prev => ({ ...prev, end_at: date }))
                                            }}
                                            disabled={WasStarted()}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>

                                        <TimePicker
                                            margin="normal"
                                            id="time-picker"
                                            label="Choose Time"
                                            value={Voucher.end_at}
                                            onChange={(date) => {
                                                setVoucher(prev => ({ ...prev, end_at: date }))
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change end time',
                                            }}
                                            disabled={WasStarted()}
                                            renderInput={(params) => <TextField sx={{ width: "100%" }}  {...params} />}
                                        />

                                    </Grid>
                                </LocalizationProvider >


                                <Grid item xs={12}>
                                    <FormControlLabel
                                        sx={{
                                            ml: 0,
                                            width:"100%",
                                            display:"flex",
                                            justifyContent:"space-between"
                                        }}
                                        control={
                                            <Switch
                                                name="is_published"
                                                checked={toPublish}
                                                color="success"
                                                onChange={(e) => {
                                                    setToPublish(e.target.checked);
                                                }}
                                                disabled={WasStarted()}
                                            />
                                        }
                                        label={
                                             <TextWithTooltipIcon text={"publish immediately"}
                                             tooltip={"Once published, only Description, Terms and Conditions and contact emails will be editable."}
                                             textStyle={{  fontSize: "0.75rem"}} />
                                        }
                                        labelPlacement="start"
                                    />
                                </Grid>
                                {
                                    (!toPublish) &&
                                    <>
                                        <Grid item xs={12}>
                                            <TextWithTooltipIcon
                                                text={"Start Date"}
                                                tooltip={TOOLTIP_PRESETS.voucher_start}
                                                textStyle={{ fontWeight: "bold" }}
                                            />

                                        </Grid>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Grid item xs={12}>

                                                <MobileDatePicker
                                                    margin="normal"
                                                    id="date-picker-dialog"
                                                    label="Choose Date (MM/DD/YYYY)"
                                                    inputFormat="MM/dd/yyyy"
                                                    value={Voucher.start_at}
                                                    onChange={(date) => {
                                                        setVoucher(
                                                            prev => ({ ...prev, start_at: date }))
                                                    }}
                                                    disabled={WasStarted()}
                                                    renderInput={(params) => <TextField sx={{ width: "100%" }} {...params} />}

                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TimePicker
                                                    margin="normal"
                                                    id="time-picker"
                                                    label="Choose Time"
                                                    value={Voucher.start_at}
                                                    onChange={(date) => {
                                                        setVoucher(prev => ({ ...prev, start_at: date }))
                                                    }}
                                                    disabled={WasStarted()}
                                                    renderInput={(params) => <TextField sx={{ width: "100%" }}  {...params} />}

                                                />
                                            </Grid>
                                        </LocalizationProvider  >
                                    </>
                                }
                                {DateErrorMsg &&
                                    <Grid item xs={12}>
                                        <Alert severity="error" color="secondary">
                                            {DateErrorMsg.toString()}
                                        </Alert>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        disabled={loading || DateErrorMsg.length > 0}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Confirm
                                    </Button>
                                    <Alert severity="error" color="secondary" sx={{ mt: 2, opacity: err ? "100%" : "0" }}>
                                        {err}
                                    </Alert>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Grid>
                </form>
            </Container>

        </>
    )
    return <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />
}
