import { Modal, Box, Button, Typography } from '@mui/material'
import React from 'react'
import { MAX_DISRUPTION_POLL, MAX_NORMAL_POLL, MAX_QR_QUEST } from './utils'

export const modalPreset = {
    endPollConfirm: {
        title: "Are you sure you want to end this poll now?",
        subtitle: "Ending the poll prematurely is non-reversible and may put certain players at a disadvantage."
    },
    endVoucherConfirm: {
        title: "Are you sure you want to end this voucher now?",
        subtitle: "Ending the voucher prematurely is non-reversible and may put certain players at a disadvantage."
    },
    endQuestConfirm: {
        title: "Are you sure you want to end this QR Quest?",
        subtitle: "Ending the quest prematurely may put certain players at a disadvantage. You can reverse this."
    },
    publishPollConfirm: {
        title: "Are you sure you want to publish this poll now?",
        subtitle: "You will not be able to make any edits after this."
    },
    startQuestConfirm: {
        title: "Are you sure you want to start this QR Quest?",
        subtitle: "You will not be able to edit its start time after this."
    },
    startVoucherSaleConfirm: {
        title: "Are you sure you want to start the sale of this Voucher?",
        subtitle: "You will not be able to edit its start time after this."
    },
    deletePollConfirm: {
        title: "Are you sure you want to delete this poll?",
        subtitle: "This action is irreversible and your poll irretrievable forever."
    },
    deleteVoucherConfirm: {
        title: "Are you sure you want to delete this voucher?",
        subtitle: "This action is irreversible and already purchased vouchers irretrievable forever. Players will no long be able to use their purchased vouchers."
    },
    deleteQuestConfirm: {
        title: "Are you sure you want to delete this QR quest?",
        subtitle: "This action is irreversible. Players will no long be able to scan the QR code."
    },
    pollCreationSuccess: {
        title: "Poll successfully created!",
        subtitle: "You may view its details from the Polls Overview page now."
    },
    voucherCreationSuccess: {
        title: "Voucher successfully created!",
        subtitle: "You may view its details from the Voucher Overview page now."
    },
    questCreationSuccess: {
        title: "Quest successfully created!",
        subtitle: "You may view its details from the Quest Overview page now."
    },
    pollEditionSuccess: {
        title: "Changes saved!",
        subtitle: "You may view the updated poll from the Polls Overview page now."
    },
    voucherEditionSuccess: {
        title: "Changes saved!",
        subtitle: "You may view the updated voucher from the Voucher Overview page now."
    },
    voucherOrderDeliverConfirm: {
        title: (len) => `Are you sure you want to mark ${len} ${len > 1 ? "orders" : "order"} to be DELIVERED?`,
        subtitle: "This action is irreversible. We will notify the following vendors via email:"
    },
    voucherOrderGenericConfirm: (option, len) => ({
        title: `Are you sure you want to mark ${len} ${len > 1 ? "orders" : "order"} to be ${option.toUpperCase()}?`,
        subtitle: ""
    }),
    questEditionSuccess: {
        title: "Changes saved!",
        subtitle: "You may view the updated quest from the Quest Overview page now."
    },
    pollDeletionSuccess: {
        title: "Poll deleted successfully!",
        subtitle: "This poll is successfully deleted from the database."
    },
    questDeletionSuccess: {
        title: "QR Quest deleted successfully!",
        subtitle: "This QR Quest is successfully deleted from the database."
    },
    CreatingPollWithoutRewardWarning: {
        title: "Warning: Creating without Rewards",
        subtitle: "You are creating a poll without rewards. It's encouraged to add some rewards to incentivise players!"
    },
    CreatingQuestWithoutRewardError: {
        title: "Error: Creating without Rewards",
        subtitle: "You need to attach some rewards to the new QR Quest!"
    },
    TooManyPoll: (is_disruption) => ({
        title: `Error: You have reached the limit of ${is_disruption ? `${MAX_DISRUPTION_POLL} disruption` : `${MAX_NORMAL_POLL}`} polls.`,
        subtitle: `You need to end some ${is_disruption ? "disruption " : ""} polls before you can create a new one!`
    }),
    ConfirmSendMail: (num_recipients) => ({
        title: "Are you sure you want to send this mail?",
        subtitle: `${num_recipients} players will receive this mail. This action is irreversible.`
    }),
    ConfirmPushNotification: {
        title: "Are you sure you want to push this notification to all players?",
        subtitle: "Only users who has logged in at least once will receive this. Players who have the application running active in the foreground may not receive this."
    },
    TooManyQuest: {
        title: `Error: You have reached the limit of ${MAX_QR_QUEST} QR Quests.`,
        subtitle: `You need to end some QR Quests before you can create a new one!`
    },
}
export default function MyModal({ children, title, subtitle, mainButtonText, subButtonText, cb, subCb, disabled, sx }) {
    return (
        (!disabled) &&
        <Modal open={true} sx={{
            position: 'fixed',
            Zindex: 10000,
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Box sx={{
                width: 400,
                bgcolor: 'white',
                borderRadius: 2,
                boxShadow: "5px 5px 10px 0px rgb(0 0 0 / 20%), -5px 5px 10px 0px rgb(0 0 0 / 20%)",
                p: 4,
                "&:focus-visible": {
                    outline: "none"
                },
                ...sx
            }}>
                <Typography variant="h5" sx={{
                    fontWeight: "bold",
                    textAlign: "center"
                }}>{title}</Typography>
                {subtitle &&
                    <Typography variant="body1" color="text.disabled" sx={{
                        textAlign: "center",
                        fontSize: "0.75rem",
                        my: 3

                    }}
                        style={{ whiteSpace: 'pre-line' }} //to recognize newline
                    >
                        {subtitle}
                    </Typography>}
                {children}

                {
                    <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                        {subButtonText &&
                            <Button variant="outlined" color="secondary" sx={{
                                boxShadow: "none",
                                textTransform: "none"
                            }} onClick={subCb} >
                                {subButtonText}
                            </Button>
                        }
                        <Button variant="contained" color="secondary" sx={{
                            boxShadow: "none",
                            textTransform: "none"
                        }} onClick={cb} >
                            {mainButtonText}
                        </Button>
                    </Box>
                }
            </Box>
        </Modal >
    )
}
