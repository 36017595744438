import React, { useState, useEffect, useCallback } from 'react'
import MyNavBar from './MyNavBar'

import {
    Button,
    Typography,
    Container,
    TextField,
    Grid,
    CircularProgress,
    useMediaQuery,
    Backdrop,
    Autocomplete
} from '@mui/material';
import Storage from '@aws-amplify/storage';
import Amplify from 'aws-amplify';
import csvDownload from 'json-to-csv-export'
import App2 from './App2';
import CommonHeader from './CommonHeader';
import { Box, useTheme } from '@mui/system';
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { IsProdEnv } from './utils';
import { AllOutTwoTone } from '@mui/icons-material';


export default function DownloadDataPoll({ logout, openStatus, setStatus }) {

    const allStages = IsProdEnv() ? ["prod"] : ["prebeta", "beta"]
    const [year, setYear] = useState("")
    const [month, setMonth] = useState("")
    const [stage, setStage] = useState(allStages[0])
    const [allYearOptions, setAllYearOptions] = useState([])
    const [allMonthOptions, setAllMonthOptions] = useState([])
    const [IsDownloading, setIsDownloading] = useState(false)
    const [filteredVotes, setFilteredVotes] = useState([]) //keys: poll_text, poll_id, option_id, option_text, voter
    const [AllVotes, setAllVotes] = useState([]) //only fetch once. every vote is here
    const [IsRetrieving, setIsRetrieving] = useState(false)
    const allTypes = [
        "Both",
        "Normal Polls",
        "Disruption Polls",
    ]
    const [type, setType] = useState(allTypes[0]);
    // let allYearOptions = []
    // let allMonthOptions = []

    const initTime = () => {
        let _allYearOptions = []
        let _allMonthOptions = []
        let today = new Date();
        for (let i = new Date(2021, 1, 1).getFullYear(); i <= today.getFullYear(); i++) {
            _allYearOptions.push("" + i);
        }
        for (let i = 1; i <= 12; i++) {
            if (i < 10) {
                _allMonthOptions.push("0" + i)
            } else {
                _allMonthOptions.push("" + i)
            }
        }
        console.log(_allYearOptions)
        console.log(_allMonthOptions)
        setAllYearOptions(_allYearOptions);
        setAllMonthOptions(_allMonthOptions);
        if (_allYearOptions.length > 0) {
            setYear(_allYearOptions[_allYearOptions.length - 1]);
        }
        setMonth((today.getMonth() >= 9) ? (today.getMonth() + 1).toString() : "0" + (today.getMonth() + 1));
    }

    const fetchAllVotesAllTypeAllTime = () => {
        console.log("fetchAllVotesAllTypeAllTime")
        setIsRetrieving(true)
        let asyncOps = [
            API.get("PollingApi2", "/polls/id", {}),
            API.get("PollingApi2", "/disruption-polls/id", {})
        ]
        Promise.allSettled(asyncOps)
            .then((results) => {
                let votes = [];
                results.forEach(result => {
                    result.value.forEach(poll => { //get total vote count
                        //iterate the polls's responses based on time
                        if (poll.options) {
                            Object.entries(poll.options).forEach(([oid, option]) => {
                                if (option.votes) {
                                    option.votes.forEach(vote => {
                                        votes.push({
                                            poll_text: poll.name,
                                            poll_description: poll.description,
                                            poll_id: poll.id,
                                            option_id: oid,
                                            option_text: option.name,
                                            voter: vote.username,
                                            department: vote.department || "",
                                            office_location: vote.officeLocation || "",
                                            event_timestamp: vote.created_at,
                                            event_name: "vote_poll",
                                            event_id: uuidv4(),
                                            disruption: (poll.is_disruption === true)
                                        })
                                    })
                                }
                            });
                        }
                    })
                })
                console.log(`fetched ${votes.length} votes in total`)
                setAllVotes(votes)
                console.log(votes)
                initTime()
                setIsRetrieving(false)
            })
            .catch((err) => {
                console.log(err);
                setAllVotes([])
                setIsRetrieving(false)
            });
    }

    useEffect(() => { fetchAllVotesAllTypeAllTime() }, [])

    const FilterVotesOnTypeTime = useCallback(() => {
        if (month.length === 0 || year.length === 0 || type.length === 0) return;
        let _month = parseInt(month)
        let _filteredVotes = []
        if (type === allTypes[0]) { //both
            _filteredVotes = AllVotes.filter(vote => (
                vote.event_timestamp > (Math.floor(new Date(year, _month - 1).getTime() / 1000))
                && vote.event_timestamp < (Math.floor(new Date(year, _month).getTime() / 1000))
            ))
        } else if (type === allTypes[1]) { //normal
            _filteredVotes = AllVotes.filter(vote => (
                vote.event_timestamp > (Math.floor(new Date(year, _month - 1).getTime() / 1000))
                && vote.event_timestamp < (Math.floor(new Date(year, _month).getTime() / 1000))
                && !vote.disruption
            ))
        } else if (type === allTypes[2]) { //disruption
            _filteredVotes = AllVotes.filter(vote => (
                vote.event_timestamp > (Math.floor(new Date(year, _month - 1).getTime() / 1000))
                && vote.event_timestamp < (Math.floor(new Date(year, _month).getTime() / 1000))
                && vote.disruption
            ))
        }
        console.log(`FilterVotesOnTypeTime ${month}${type} ${AllVotes.length} ${year}. after filer No. votes = ${_filteredVotes.length}`)
        setFilteredVotes(_filteredVotes)
    }, [month, type, AllVotes, year])

    useEffect(() => {
        FilterVotesOnTypeTime();
    }, [FilterVotesOnTypeTime])

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const downloadData = () => {
        setIsDownloading(true)
        csvDownload(filteredVotes, `joyworld-poll-${year}-${month}.csv`)
        setIsDownloading(false)
    }
    const page = (
        <>
            <Backdrop open={IsRetrieving || IsDownloading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            <CommonHeader title={"Download Poll Responses"} subtitle="Download poll responses collected from the game for Data Analytics and more." />

            <Container maxWidth="lg" sx={{ p: isMobile ? 0 : 2 }}>
                <Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                        Select Time Period
                    </Typography>
                    <Grid container sx={{ my: 2 }} justify="space-between" alignItems="flex-end" spacing={2}>
                        {!IsProdEnv()
                            &&
                            <Grid item xs={12} md={IsProdEnv() ? 4 : 3}  >
                                <Autocomplete
                                    options={allStages}
                                    selectOnFocus
                                    clearOnBlur
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} label="Stage" />}
                                    value={stage}
                                    onChange={(event, newValue) => {
                                        if (newValue == null)
                                            return
                                        setStage(newValue)
                                    }}
                                />
                            </Grid>}
                        <Grid item xs={12} md={IsProdEnv() ? 4 : 3}  >
                            <Autocomplete
                                options={allTypes}
                                selectOnFocus
                                clearOnBlur
                                fullWidth
                                renderInput={(params) => <TextField {...params} label="Type" />}
                                value={type}
                                onChange={(event, newValue) => {
                                    if (newValue == null)
                                        return
                                    setType(newValue)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={IsProdEnv() ? 4 : 3}  >
                            <Autocomplete
                                options={allYearOptions}
                                selectOnFocus
                                clearOnBlur
                                fullWidth
                                renderInput={(params) => <TextField {...params} label="Year" />}
                                value={year}
                                onChange={(event, newValue) => {
                                    if (newValue == null)
                                        return
                                    setYear(newValue)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={IsProdEnv() ? 4 : 3}  >
                            <Autocomplete
                                options={allMonthOptions}
                                selectOnFocus
                                clearOnBlur
                                fullWidth
                                renderInput={(params) => <TextField {...params} label="Month" />}
                                value={month}
                                onChange={(event, newValue) => {
                                    if (newValue == null)
                                        return
                                    setMonth(newValue)
                                }}
                            />
                        </Grid>

                    </Grid>
                </Box>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 1, boxShadow: "none" }}
                    disabled={IsDownloading || filteredVotes.length <= 0}
                    onClick={downloadData}>
                    {filteredVotes.length <= 0 ? "No Data" : `Download`}
                </Button>
            </Container>
        </>
    )
    return <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />
}
