import React, { useState, useEffect } from 'react'

import HomeRoundedIcon from '@mui/icons-material/Home';
import PollRoundedIcon from '@mui/icons-material/Poll';
import EmailRoundedIcon from '@mui/icons-material/Email';
import RedeemRoundedIcon from '@mui/icons-material/Redeem';
import QrCodeRoundedIcon from '@mui/icons-material/QrCode';
import NotificationsRoundedIcon from '@mui/icons-material/Notifications';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownload';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import RunCircleRoundedIcon from '@mui/icons-material/RunCircleRounded';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import RuleIcon from '@mui/icons-material/Rule';

import fireIcon from './assets/admin_obj_fire.png';
import upcomingIcon from './assets/admin_obj_upcoming.png';
import tickIcon from './assets/admin_obj_tick.png';



import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';

import { useHistory, useLocation } from "react-router-dom";
import { RuleRounded } from '@mui/icons-material';
import { IsProdEnv } from './utils';

const drawerWidth = 255

export default function Sidebar({ mobileOpen, handleDrawerToggle, logout, openStatus, setStatus }) {

    const [drawerContent, setdrawerContent] = useState([])
    const history = useHistory()
    const drawer = (
        <div style={{ //to hide the scroll bar 
            width: `${drawerWidth}px`,
            height: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingRight: "17px", /* Increase/decrease this value for cross-browser compatibility */
            boxSizing: "content-box", /* So the width will be 100% + 17px */
        }}>
            <Toolbar sx={{ pl: "16px !important" }}>
                <Avatar variant="rounded" src={`${process.env.PUBLIC_URL}/60x60.png`} sx={{ mr: 2 }} />
                <Typography sx={{
                    color: 'primary.contrastText',
                    fontWeight: "bold !important",
                }}>
                    JoyWorld Admin
                </Typography>
            </Toolbar>
            {/* <Divider /> */}
            <List sx={{
                color: 'primary.contrastText',
                fontSize: "0.8rem"
            }}>
                {
                    drawerContent.map((item, itemIdx) => (<React.Fragment key={item.title}>
                        <ListItem button key={item.title}
                            sx={{
                                p: "8px 16px !important",
                            }}
                            disabled={!item.href}
                            onClick={() => {
                                if (item.href) {
                                    history.push(item.href)
                                }
                            }}
                        >
                            <ListItemIcon sx={{ color: "primary.contrastText", minWidth: 0, mr: "1.5rem" }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title}
                                sx={{
                                    "& .MuiListItemText-primary": {
                                        fontWeight: ((window.location.pathname + window.location.search === item.href) ? "bold" : "normal")
                                    }
                                }}
                            />
                            {
                                item.children && item.open &&
                                <ExpandLessRounded sx={{
                                    color: theme => theme.palette.primary.contrastText
                                }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (item.children) {
                                            setdrawerContent(prev => {
                                                let copy = [...prev]
                                                copy[itemIdx].open = !prev[itemIdx].open
                                                setStatus(prevS => ({
                                                    ...prevS,
                                                    [copy[itemIdx].title]: false
                                                }))
                                                return copy
                                            })
                                        }
                                    }} />
                            }
                            {
                                item.children && !item.open &&
                                <ExpandMoreRounded sx={{
                                    color: theme => theme.palette.primary.contrastText
                                }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (item.children) {
                                            setdrawerContent(prev => {
                                                let copy = [...prev]
                                                copy[itemIdx].open = !prev[itemIdx].open
                                                setStatus(prevS => ({
                                                    ...prevS,
                                                    [copy[itemIdx].title]: true
                                                }))
                                                return copy
                                            })
                                        }
                                    }} />
                            }
                        </ListItem>
                        <Collapse in={item.open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{ pl: 2 }}>
                                {item.children &&
                                    item.children.map(childEntry => (
                                        <ListItem
                                            disabled={!childEntry.href}
                                            button
                                            key={childEntry.title}
                                            // href={item.href}
                                            onClick={() => {
                                                if (childEntry.href) {
                                                    history.push(childEntry.href)
                                                }
                                            }}
                                            sx={{
                                                p: "8px 16px !important",
                                                "& .MuiListItemText-primary": {
                                                    fontWeight: ((window.location.pathname + window.location.search === childEntry.href) ? "bold" : "normal")
                                                }
                                            }}
                                        >
                                            {
                                                (childEntry.title === "Orders") ?
                                                    <ListItemIcon sx={{ color: "primary.contrastText", minWidth: 0, mr: "1.5rem" }}>
                                                        {childEntry.icon}
                                                    </ListItemIcon>
                                                    :
                                                    <img src={childEntry.icon} alt="icon" style={{ minWidth: 0, marginRight: "1.5rem", width: "1.5rem" }} />
                                            }
                                            <ListItemText primary={childEntry.title} />
                                        </ListItem>

                                    ))
                                }
                            </List>
                        </Collapse>
                    </React.Fragment>
                    ))
                }
                <ListItem button
                    sx={{
                        p: "8px 16px !important",
                    }}
                    onClick={logout}
                >
                    <ListItemIcon sx={{ color: theme => theme.palette.primary.contrastText }}>
                        <ExitToAppRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Log Out" sx={{
                        color: theme => theme.palette.primary.contrastText,
                    }} />
                </ListItem>
            </List>
        </div>
    );
    const location = useLocation()
    useEffect(() => {
        const url_lower = location.pathname.toLowerCase();
        let _openStatus = {}
        if (url_lower.includes("disruption_poll")) {
            _openStatus["Disruption Polls"] = true
        } else if (url_lower.includes("poll")) {
            _openStatus["Polls"] = true
        } else if (url_lower.includes("voucher")) {
            _openStatus["Reward Shop"] = true
        } else if (url_lower.includes("qr")) {
            _openStatus["QR Quests"] = true
        }
        console.log(_openStatus)
        setStatus(_openStatus)
    }, [location, setStatus])
    useEffect(() => {
        setdrawerContent([
            {
                "title": "Home",
                "icon": <HomeRoundedIcon />,
                "href": "/"
            },
            {
                "title": "Polls",
                "icon": <PollRoundedIcon />,
                "open": (typeof (openStatus["Polls"]) == "undefined" ? false : openStatus["Polls"]),
                "children": [
                    {
                        "title": "Ongoing",
                        //"icon": <RunCircleRoundedIcon />,
                        "icon": fireIcon,
                        "href": "/poll?filter=ongoing"
                    },
                    {
                        "title": "Upcoming",
                        //"icon": <HourglassTopRoundedIcon/>,
                        "icon": upcomingIcon,
                        "href": "/poll?filter=upcoming",
                    },
                    {
                        "title": "Completed",
                        //"icon": <HourglassBottomRoundedIcon/>,
                        "icon": tickIcon,
                        "href": "/poll?filter=completed",
                    },
                ],
                "href": "/poll?filter=all"
            },
            {
                "title": "Disruption Polls",
                "icon": <CampaignRoundedIcon />,
                "open": (typeof (openStatus["Disruption Polls"]) == "undefined" ? false : openStatus["Disruption Polls"]),
                "children": [
                    {
                        "title": "Ongoing",
                        //"icon": <RunCircleRoundedIcon />,
                        "icon": fireIcon,
                        "href": "/disruption_poll?filter=ongoing"
                    },
                    {
                        "title": "Upcoming",
                        //"icon": <HourglassTopRoundedIcon/>,
                        "icon": upcomingIcon,
                        "href": "/disruption_poll?filter=upcoming"
                    },
                    {
                        "title": "Completed",
                        //"icon": <HourglassBottomRoundedIcon/>,
                        "icon": tickIcon,
                        "href": "/disruption_poll?filter=completed"
                    },
                ],
                "href": "/disruption_poll?filter=all"
            },
            {
                "title": "Mails",
                "icon": <EmailRoundedIcon />,
                "href": "/send-mail",
            },
            {
                "title": "Reward Shop",
                "icon": <RedeemRoundedIcon />,
                "href": "/vouchers?filter=all",
                "open": (typeof (openStatus["Reward Shop"]) == "undefined" ? false : openStatus["Reward Shop"]),
                "children": [
                    {
                        "title": "Ongoing",
                        //"icon": <RunCircleRoundedIcon />,
                        "icon": fireIcon,
                        "href": "/vouchers?filter=ongoing"
                    },
                    {
                        "title": "Upcoming",
                        //"icon": <HourglassTopRoundedIcon/>,
                        "icon": upcomingIcon,
                        "href": "/vouchers?filter=upcoming"
                    },
                    {
                        "title": "Completed",
                        //"icon": <HourglassBottomRoundedIcon/>,
                        "icon": tickIcon,
                        "href": "/vouchers?filter=completed"
                    },
                    {
                        "title": "Orders",
                        "icon": <RuleRounded />,
                        "href": "/orders"
                    },
                ],
            },
            {
                "title": "QR Quests",
                "icon": <QrCodeRoundedIcon />,
                "href": "/qr?filter=all",
                "open": (typeof (openStatus["QR Quests"]) == "undefined" ? false : openStatus["QR Quests"]),
                "children": [
                    {
                        "title": "Ongoing",
                        //"icon": <RunCircleRoundedIcon />,
                        "icon": fireIcon,
                        "href": "/qr?filter=ongoing"
                    },
                    {
                        "title": "Upcoming",
                        //"icon": <HourglassTopRoundedIcon/>,
                        "icon": upcomingIcon,
                        "href": "/qr?filter=upcoming"
                    },
                    {
                        "title": "Completed",
                        //"icon": <HourglassBottomRoundedIcon/>,
                        "icon": tickIcon,
                        "href": "/qr?filter=completed"
                    },
                ],
            },
            {
                "title": "Push Notifications",
                "icon": <NotificationsRoundedIcon />,
                "href": IsProdEnv() ? "" : "/push-notif",
            },
            {
                "title": "Download Data",
                "icon": <CloudDownloadRoundedIcon />,
                "href": "/download-data"
            },
            {
                "title": "Download Poll Data",
                "icon": <CloudDownloadRoundedIcon />,
                "href": "/download-data-poll"
            },
            {
                "title": "Download User List",
                "icon": <CloudDownloadRoundedIcon />,
                "href": "/download-user"
            },

        ])
    }, [openStatus])
    return (
        <Box
            component="nav"
            sx={{
                width: { sm: drawerWidth },
                flexShrink: { sm: 0 },
                height: "100%",

            }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        bgcolor: (theme) => theme.palette.primary.main,
                        overflow: "hidden !important"
                    },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        bgcolor: (theme) => theme.palette.primary.main,
                        overflow: "hidden !important"
                    },
                    width: "100%",
                    height: "100%",

                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>

    )
}
