import { InfoOutlined } from '@mui/icons-material';
import { Typography, Box, Tooltip, } from '@mui/material';
import React from 'react';
import { TOOLTIP_PRESETS } from './utils';

export default function TextWithTooltipIcon({ text, tooltip, textStyle }) {
    return <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={textStyle}>
            {text}
        </Typography>
        <Tooltip title={tooltip} placement="bottom">
            <InfoOutlined sx={{ color: "warning.main" }} />
        </Tooltip>
    </Box>
}

