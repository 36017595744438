import React, { useState, useEffect, useCallback } from 'react'
import { useLocation, useHistory } from "react-router-dom";
import App2 from "./App2"
import { Grid, Typography, CardMedia, Button, Box, Backdrop, CircularProgress, Badge } from "@mui/material"
import { API } from "aws-amplify";
import pollCover from "./assets/polls_obj_default.png";

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import CommonHeader from './CommonHeader';
import PollCard from './PollCard';
import fireIcon from './assets/admin_obj_fire.png';
import tickIcon from './assets/admin_obj_tick.png';
import upcomingIcon from './assets/admin_obj_upcoming.png';
import MyModal, { modalPreset } from './MyModal';
import { timeAgo, updatePoll } from './utils';
import EmptyList from './EmptyList';
import Storage from '@aws-amplify/storage';

export default function PollListView({ logout, openStatus, setStatus, disruption = false }) {
    const history = useHistory()
    const [filter, setFilter] = useState("all")
    const [upcomingPolls, setUpcomingPolls] = useState([]); //upcoming polls
    const [ongoingPolls, setOngoingPolls] = useState([]); //active polls
    const [completedPolls, setCompletedPolls] = useState([]); //completed polls
    const [allUnfilteredPolls, setAllUnfilteredPolls] = useState([]) //both disruption && normal
    const [allPolls, setAllPolls] = useState([]); //filtered with field is_disruption
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    useEffect(() => {
        if ("allupcomingongoingcompleted".indexOf(filter) < 0 || filter.length === 0) {
            history.push("/")
        }
    }, [filter, history])

    const getPolls = useCallback(() => {
        setLoading(true)
        API.get("PollingApi2", disruption? "/disruption-polls/id": "/polls/id", {})
            .then((polls) => {
                let asyncOps = []
                polls.forEach((poll, vcIdx) => {
                    if (poll.img_s3) {
                        asyncOps.push(
                            Storage.get(`${poll.img_s3}`, { level: "public" })
                        )
                    } else {
                        asyncOps.push(
                            Promise.reject(new Error('fail'))
                        )
                    }
                })
                Promise.allSettled(asyncOps).then(results => {
                    results.forEach((result, index) => {
                        console.log(result)
                        if (result.status === "fulfilled") {
                            polls[index].img_url = result.value
                        } else {
                            polls[index].img_url = ""
                        }
                    })
                    setLoading(false)
                    setAllUnfilteredPolls(polls)
                })
            })
            .catch((err) => {
                console.log(err);
                setLoading(false)
            });
    }, [disruption])

    const filterDisruption = useCallback(() => {
        let _filtered_polls = []
        allUnfilteredPolls.forEach(poll => { //get total vote count
            let _voteCount = 0;
            if (poll.options) {
                Object.values(poll.options).forEach((option) => {
                    if (option.votes) {
                        _voteCount += option.votes.length;
                    }
                });
                poll.totalVotes = _voteCount
            } else {
                poll.totalVotes = 0
            }
            if (typeof (poll.is_disruption) === "undefined") {
                poll.is_disruption = false
            }

            if (poll.is_disruption === disruption) {
                _filtered_polls.push(poll)
            }
        })
        setAllPolls(_filtered_polls)
        setLoading(false)
    }, [disruption, allUnfilteredPolls])

    useEffect(() => { filterDisruption() }, [filterDisruption])


    useEffect(() => {
        //if needs to redirect to signin page
        let _filter = new URLSearchParams(location.search).get("filter")
        setFilter(_filter)
    }, [location]);

    useEffect(() => {
        getPolls()
    }, [getPolls])

    useEffect(() => {
        let _upcomingPolls = allPolls.filter((poll) => !poll["start_at"] || !poll["end_at"] || poll["start_at"] * 1000 > new Date())
        let _ongoingPolls = allPolls.filter((poll) => poll["start_at"] * 1000 < new Date() && new Date() < poll["end_at"] * 1000)
        let _completedPolls = allPolls.filter((poll) => poll["end_at"] * 1000 < new Date())
        setUpcomingPolls(_upcomingPolls)
        setOngoingPolls(_ongoingPolls)
        setCompletedPolls(_completedPolls)

    }, [filter, allPolls])

    const [isConfirmingEndPoll, setIsConfirmingEndPoll] = useState(false)
    const [isConfirmingPublishPoll, setIsConfirmingPublishPoll] = useState(false)
    const [cardQuickActionPoll, setCardQuickActionPoll] = useState()
    const page = (
        <>
            <Backdrop open={loading} sx={{ zIndex: 9999 }}>
                <CircularProgress size={56} />
            </Backdrop>
            {
                isConfirmingEndPoll
                // (true)
                &&
                <MyModal title={modalPreset.endPollConfirm.title}
                    subtitle={modalPreset.endPollConfirm.subtitle}
                    mainButtonText="Yes, End This Poll"
                    cb={() => {
                        updatePoll({
                            ...cardQuickActionPoll,
                            end_at: Math.floor(new Date().getTime() / 1000) - 1
                        }, setAllPolls, () => {
                            setCardQuickActionPoll(null)
                            setIsConfirmingEndPoll(false)
                            history.push(`/${disruption ? "disruption_poll" : "poll"}?filter=completed`)
                        })
                    }}
                    subButtonText="Cancel"
                    subCb={() => { setIsConfirmingEndPoll(false) }}
                />
            }
            {
                isConfirmingPublishPoll
                // (true)
                &&
                <MyModal title={modalPreset.publishPollConfirm.title}
                    subtitle={modalPreset.publishPollConfirm.subtitle}
                    mainButtonText="Yes, Publish This Poll"
                    cb={() => {
                        updatePoll({
                            ...cardQuickActionPoll,
                            start_at: Math.floor(new Date().getTime() / 1000) - 1
                        }, setAllPolls, () => {
                            setCardQuickActionPoll(null)
                            setIsConfirmingPublishPoll(false)
                            history.push(`/${disruption ? "disruption_poll" : "poll"}?filter=ongoing`)
                        })
                    }}
                    subButtonText="Cancel"
                    subCb={() => { setIsConfirmingPublishPoll(false) }}
                />
            }
            <Grid container >
                <Grid item xs={12} md={9} sx={{ mb: 2 }}>
                    <CommonHeader title={filter && filter.length > 0 ? `${filter[0].toUpperCase()}${filter.slice(1)} ${disruption ? "Disruption " : ""}Polls` : "Polls"}
                        subtitle={`Create, delete and view results of ${disruption ? "disruption " : ""}polls here.`} />
                    {/* {filter && filter.length > 0 && <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                        {`${filter[0].toUpperCase()}${filter.slice(1)}`} Polls
                    </Typography>}
                    <Typography sx={{ fontSize: "0.875rem" }}>
                        Create, delete and view results of polls here.
                    </Typography> */}
                </Grid>
                <Grid item xs={12} md={3} sx={{ mb: 2 }}>
                    <Button color="secondary" variant="contained" sx={{
                        boxShadow: "none",
                        float: "right"
                    }}
                        href={disruption ? "/disruption_poll/new" : "/poll/new"}>
                        <AddIcon />
                        Create New
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>

                {filter !== "all" ? <>
                    {(filter === "upcoming" ? upcomingPolls : (filter === "ongoing" ? ongoingPolls : completedPolls)).length <= 0 &&
                        <EmptyList />
                    }

                    {(filter === "upcoming" ? upcomingPolls : (filter === "ongoing" ? ongoingPolls : completedPolls)).map((poll) => (
                        <Grid item key={poll.id} md={3} sm={6} xs={12} >
                            <PollCard title={poll.name}
                                subtitle={`${poll.created_at ? "Created " + timeAgo.format(poll.created_at * 1000) : "Unknown Creation Time"}
                                        ${poll.start_at ? "Published " + timeAgo.format(poll.start_at * 1000) : "Unknown Publish Time"}
                                        ${poll.end_at ? "Ends " + timeAgo.format(poll.end_at * 1000) : "Unknown End Time"}`}
                                imageStr={poll.img_url}
                                href1={(filter === "upcoming") ? `/${disruption ? "disruption_poll" : "poll"}/${poll.id}/edit` : `/${disruption ? "disruption_poll" : "poll"}/${poll.id}`}
                                btnText1={(filter === "upcoming") ? "" : ""}
                                icon1={(filter === "upcoming") ? <CreateOutlinedIcon /> : <SearchIcon />}
                                btnText2={(filter === "upcoming") ? "Publish Now" : ((filter === "ongoing") ? "End Now" : null)}
                                cb2={(filter === "upcoming")
                                    ? () => {
                                        setCardQuickActionPoll(poll)
                                        setIsConfirmingPublishPoll(true)
                                    }
                                    : ((filter === "ongoing")
                                        ? () => {
                                            setCardQuickActionPoll(poll)
                                            setIsConfirmingEndPoll(true)
                                        }
                                        : null
                                    )
                                }
                            />
                        </Grid>
                    ))}
                </> : (
                    <>
                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <CardMedia image={fireIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                                <Typography sx={{ fontWeight: "bold" }} color="text.orange">
                                    Ongoing Polls
                                </Typography>
                                <Badge badgeContent={ongoingPolls.length + ""} sx={{
                                    ml: 1,
                                    "& .MuiBadge-badge": {
                                        color: "white",
                                        fontWeight: "bold",
                                        transform: "inherit",
                                        position: "inherit",
                                        bgcolor: "text.orange"
                                    }
                                }} />
                            </Box>
                            <Button variant="outlined" color="secondary"
                                onClick={() => {
                                    history.push(disruption ? `/disruption_poll?filter=ongoing` : '/poll?filter=ongoing')
                                    window.scrollTo(0, 0)
                                }}
                                sx={{ float: "right" }}
                            > View All</Button>

                        </Grid>
                        {
                            ongoingPolls.slice(0, 4).map(poll => (
                                <Grid item key={poll.id} md={3} sm={6} xs={12} >
                                    <PollCard title={poll.name}
                                        subtitle={`${poll.created_at ? "Created " + timeAgo.format(poll.created_at * 1000) : "Unknown Creation Time"}
                                        ${poll.start_at ? "Published " + timeAgo.format(poll.start_at * 1000) : "Unknown Publish Time"}
                                        ${poll.end_at ? "Ends " + timeAgo.format(poll.end_at * 1000) : "Unknown End Time"}`}
                                        imageStr={poll.img_url}
                                        href1={disruption ? `/disruption_poll/${poll.id}/` : `/poll/${poll.id}/`}
                                        btnText1={""}
                                        icon1={<SearchIcon />}
                                        btnText2={"End Now"}
                                        cb2={() => {
                                            setCardQuickActionPoll(poll)
                                            setIsConfirmingEndPoll(true)
                                        }}
                                    />
                                </Grid>
                            ))
                        }


                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <CardMedia image={upcomingIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                                <Typography sx={{ fontWeight: "bold" }} color="text.purple">
                                    Upcoming Polls
                                </Typography>
                                <Badge badgeContent={upcomingPolls.length + ""} sx={{
                                    ml: 1,
                                    "& .MuiBadge-badge": {
                                        color: "white",
                                        fontWeight: "bold",
                                        transform: "inherit",
                                        position: "inherit",
                                        bgcolor: "text.purple"
                                    }
                                }} />
                            </Box>
                            <Button variant="outlined" color="secondary"
                                onClick={() => {
                                    history.push(disruption ? '/disruption_poll?filter=upcoming' : '/poll?filter=upcoming')
                                    window.scrollTo(0, 0)
                                }}
                                sx={{ float: "right" }}
                            > View All</Button>
                        </Grid>
                        {
                            upcomingPolls.slice(0, 4).map(poll => (
                                <Grid item key={poll.id} md={3} sm={6} xs={12} >
                                    <PollCard title={poll.name}
                                        subtitle={`${poll.created_at ? "Created " + timeAgo.format(poll.created_at * 1000) : "Unknown Creation Time"}
                                        ${poll.start_at ? "Publishes " + timeAgo.format(poll.start_at * 1000) : "Unknown Publish Time"}
                                        ${poll.end_at ? "Ends " + timeAgo.format(poll.end_at * 1000) : "Unknown End Time"}`}
                                        imageStr={poll.img_url}
                                        href1={disruption ? `/disruption_poll/${poll.id}/edit` : `/poll/${poll.id}/edit`}
                                        btnText1=""
                                        icon1={<CreateOutlinedIcon />}
                                        btnText2={"Publish Now"}
                                        cb2={() => {
                                            setCardQuickActionPoll(poll)
                                            setIsConfirmingPublishPoll(true)
                                        }}
                                    />
                                </Grid>
                            ))
                        }




                        <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <CardMedia image={tickIcon} sx={{ height: 30, width: 30, mr: 1 }} />
                                <Typography sx={{ fontWeight: "bold" }} color="text.green">
                                    Completed Polls
                                </Typography>
                                <Badge badgeContent={completedPolls.length + ""} sx={{
                                    ml: 1,
                                    "& .MuiBadge-badge": {
                                        color: "white",
                                        fontWeight: "bold",
                                        transform: "inherit",
                                        position: "inherit",
                                        bgcolor: "text.green"
                                    }
                                }} /></Box>
                            <Button variant="outlined" color="secondary"
                                // href="/poll?filter=ongoing"
                                onClick={() => {
                                    history.push(disruption ? '/disruption_poll?filter=completed' : '/poll?filter=completed')
                                    window.scrollTo(0, 0)
                                }}
                                sx={{ float: "right" }}
                            > View All</Button>
                        </Grid>
                        {
                            completedPolls.slice(0, 4).map(poll => (
                                <Grid item key={poll.id} md={3} sm={6} xs={12} >
                                    <PollCard title={poll.name}
                                        subtitle={`${poll.created_at ? "Created " + timeAgo.format(poll.created_at * 1000) : "Unknown Creation Time"}
                                        ${poll.start_at ? "Published " + timeAgo.format(poll.start_at * 1000) : "Unknown Publish Time"}
                                        ${poll.end_at ? "Ended " + timeAgo.format(poll.end_at * 1000) : "Unknown End Time"}`}
                                        imageStr={poll.img_url}
                                        href1={disruption ? `/disruption_poll/${poll.id}` : `/poll/${poll.id}`}
                                        btnText1=""
                                        icon1={<SearchIcon />}
                                    />
                                </Grid>
                            ))
                        }


                    </>

                )
                }
            </Grid>
        </>
    )


    return <App2 page={page} logout={logout} openStatus={openStatus} setStatus={setStatus} />
}
