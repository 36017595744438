import React, { useState } from "react";
import { Alert, Avatar, Button, TextField, Typography, Container, Backdrop, Card, CircularProgress, useMediaQuery } from "@mui/material";

import MyNavBar from "./MyNavBar";
import { Copyright } from "./utils";
import Auth from "@aws-amplify/auth";

import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { theme } from "./style";
import { useHistory } from 'react-router';


const useStyles = ((theme) => ({
  paper: {
    padding: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  main: {
    backgroundColor: theme.palette.background.main,
    minHeight: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function SignIn({ setCurrentUser }) {
  const [err, setErr] = useState("");
  const [bdOpen, setBdOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [user, setUser] = useState();
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [isForcedChangePw, setIsForcedChangepw] = useState(false);

  const doSignIn = (username, password) => {
    setBdOpen(true);
    Auth.configure({
      authenticationFlowType: 'USER_SRP_AUTH',
    })
    Auth.signIn(username, password)
      .then((res) => {
        console.log(res);
        setUser(res);
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setBdOpen(false)
          setIsForcedChangepw(true)
        } else {
          setCurrentUser(res);
        }
      })
      .catch((err) => {
        setErr(err.message);
        setBdOpen(false);
      });
  };
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  //   const [rememberMe, setRememberMe] = useState(false);
  return (
    <>
      <Container maxWidth="xs" sx={{
        backgroundColor: "background",
        minHeight: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: isMobile ? "flex-start" : "space-evenly",
        p: isMobile ? 0 : "auto"
      }}>
        <Card sx={{
          my: isMobile ? 0 : 5,
          padding: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderTop: "4px solid #ff3333"
        }} raised>
          <Avatar sx={{
            margin: 1,
            backgroundColor: "secondary",
          }}>
            <LockRoundedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {isForcedChangePw ?
            <form
              sx={{
                width: "100%", // Fix IE 11 issue.
                my: 2
              }}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                if (newPassword !== newPassword2) {
                  setErr("Please enter the same password.")
                } else {
                  console.log(newPassword)
                  Auth.completeNewPassword(
                    user,
                    newPassword
                  ).then(user => {
                    doSignIn(user.username, newPassword)
                  }).catch(e => {
                    console.log(e);
                  });
                }
              }}
            >
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                label="New Password"
                type="password"
                id="newPassword"
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                value={newPassword2}
                onChange={(e) => {
                  setNewPassword2(e.target.value);
                }}
                label="Confirm New Password"
                type="password"
                id="newPassword2"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                sx={{
                  my: 2
                }}
              >
                {"Confirm Password & Sign In"}
              </Button>
            </form> : <form
              sx={{
                width: "100%", // Fix IE 11 issue.
                my: 2
              }}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                doSignIn(username, password);
              }}
            >
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                autoComplete="email"
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                sx={{
                  my: 2
                }}
              >
                Sign In
              </Button>
            </form>}
          <Copyright />
        </Card>
        <Alert severity="error" style={{ opacity: err ? "100%" : "0" }}>
          {err}
        </Alert>
      </Container>
      <Backdrop sx={{
        color: "#fff",
        zIndex: 9999
      }} open={bdOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
