
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#220808',
    },
    secondary: {
      main: '#ff3333',
    },
    divider: '#ececec',
    warning: {
      main: '#ffaa22',
    },
    success: {
      main: '#22be82',
    },
    text: {
      primary: '#2e2e2e',
      orange: "#ed5824",
      purple: "#8f0f66",
      green: "#22be82",
      disabled: 'rgba(46,46,46,0.5)',
    },
    error: {
      main: '#220808',
    },
    info: {
      main: '#22be82',
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif !important',
    fontColor: "yellow",
  }
});